import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${
          typeof params?.[key] === "object"
            ? params?.[key]["value"]
            : params?.[key]
        }`;
      } else {
        return "";
      }
    };

    const getQuery = (paramsData) => {
      return getParams(paramsData) !== "" ? `&${getParams(paramsData)}` : "";
    };

    try {
      let url =
        method !== "post"
          ? `${endpoint}?${getParams("sort_by")}&sort_order=${
              params?.sort_order || "desc"
            }&length=${params?.length || "10"}&${getParams(
              "search"
            )}${getParams("branch_id")}${getParams("currency_id")}&page=${
              params?.page || 1
            }`
          : `${endpoint}`;

      const response = await api[method](url, body);

      if (response?.data?.data?.rows !== undefined) {
        return { data: response?.data?.data };
      } else {
        return { data: response?.data };
      }
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const currencyRtk = createApi({
  reducerPath: "currencyRtk",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["currency"],
  endpoints: (builder) => ({
    getAdminUsersListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/branch-currency-rate/filters`,
      }),
      providesTags: ["currency"],
    }),

    updateAdminUsersListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["currency"],
    }),

    updateAdminUserDataByID: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/update`,
      }),

      invalidatesTags: ["AdminUsers"],
    }),
  }),
});

export const {
  useGetAdminUsersListDataQuery,
  useUpdateAdminUsersListDataMutation,
  useUpdateAdminUserDataByIDMutation,
} = currencyRtk;

import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {

      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const getQuery = (paramsData) => {
      return getParams(paramsData) !== "" ? `&${getParams(paramsData)}` : "";
    };

    try {
      let url =
        method !== "post"
          ? `${endpoint}?${getParams("sort_by")}&sort_order=${
              params?.sort_order || "desc"
            }&length=${params?.length || "10"}&${getParams("search")}&page=${
              params?.page || 1
            }${getQuery("franchise_id")}${getQuery("is_confirmed")}${getQuery(
              "start_date"
            )}${getQuery("end_date")}`
          : `${endpoint}`;

      const response = await api[method](url, body);

      if (response?.data?.data?.rows !== undefined) {
        return { data: response?.data?.data };
      } else {
        return { data: response?.data };
      }
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const cancelRequest = createApi({
  reducerPath: "cancelRequestApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["cancelRequest"],
  endpoints: (builder) => ({
    getCancelRequestListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/purchase/cancel-requests`,
      }),
      providesTags: ["cancelRequest"],
    }),

    //bug

    // getPurchasesListDataById: builder.query({
    //   query: ({
    //     customerID,
    //     page_size,
    //     page,
    //     sort_by,
    //     sort_order,
    //     start,
    //     end,
    //     search,
    //   }) => ({
    //     method: "get",
    //     params: {
    //       customerID,
    //       page_size: page_size,
    //       page: page,
    //       sort_by: sort_by,
    //       sort_order: sort_order,
    //       start: start,
    //       end: end,
    //       search: search,
    //     },
    //     endpoint: `/v1/purchase/customer/${customerID}`,
    //   }),
    //   providesTags: ["Purchases"],
    // }),

    updateCancelRequestListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["cancelRequest"],
    }),
    // updatePurchasesDataByID: builder.mutation({
    //   query: (body) => ({
    //     method: "post",
    //     body,
    //     endpoint: `/admin/staffs/update`,
    //   }),

    //   invalidatesTags: ["cancelRequests"],
    // }),
    // STOP HERE
  }),
});

export const {
  useGetCancelRequestListDataQuery,
  useUpdateCancelRequestListDataMutation,
} = cancelRequest;

import Dropdown from "react-bootstrap/Dropdown";

import Assets from "../../../../assets/Assets";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";

const HeaderActions = () => {
  const {
    handleLogout,
    formattedDate,
    formattedTime,
    // handleSettings,
    // handleViewProfile,
    // handleCloseProfileView,
    // isProfileViewVisible,
    loggedStaffData,
    // setProfileViewVisible,
  } = useHeaderActions();
  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root} `}>
      <div className="pro-d-flex pro-items-center pro-gap-2 pro-me-5 pro-fw-medium pro-text-uppercase pro-no-point">
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </div>

      <Dropdown className={`pro-d-flex pro-items-center `}>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar  pro-flex-shrink-0">
            <img
              src={
                loggedStaffData?.data?.staff_image
                  ? loggedStaffData?.data?.staff_image
                  : Assets.ACCOUNT_CIRCLE
              }
              alt=""
            />
          </div>
          {/* <span class="material-symbols-outlined x4">account_balance</span> */}
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.dropdown}`}>
          <Dropdown.Item className={` pro-no-point`}>
            <div className={`${Style.user_wrapper}`}>
              <div className={`${Style.image_wrapper} pro-justify-center`}>
                <img
                  src={
                    loggedStaffData?.data?.staff_image
                      ? loggedStaffData?.data?.staff_image
                      : Assets.ACCOUNT_CIRCLE
                  }
                  alt=""
                />
                {/* <span className={`material-symbols-outlined ${Style.user_img}`}>
                  account_balance
                </span> */}
              </div>
              <div className={Style.content_wrapper}>
                <h3 className={Style.staff_name}>
                  {loggedStaffData?.data?.name}
                </h3>
                {/* <p className={Style.staff_email}>
                  {loggedStaffData?.data?.branch}
                </p> */}
                <p className={Style.staff_email}>
                  {loggedStaffData?.data?.email}
                </p>
                {/* <p className={Style.staff_number}>
                  <a href={`tel:${loggedStaffData?.data?.staff_mobile}`}>
                    {loggedStaffData?.data?.staff_mobile}
                  </a>
                </p> */}
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className={`${Style.logout} pro-btn pro-btn-outline pro-items-center`}
          >
            <span class="material-symbols-outlined x3">logout</span>
            <span>Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderActions;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetCustomersListDataQuery,
  useUpdateCustomersListDataMutation,
} from "../../../store/queries/customers";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  updateConfig,
} from "../../../store/slices/customers/customersSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  editItemFromTableByID,
  resetEditAction,
} from "../../../store/slices/customers/registerCustomersSlice";
import { toast } from "react-toastify";

const useCustomersList = () => {
  const dispatch = useDispatch();

  const { editCustomer } = useSelector((state) => state.registerCustomers);

  const customers = useSelector((state) => state.customers);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const [isFilterShow, setIsFilterShow] = useState(false);

  const [activeTab, setActiveTab] = useState("");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  let tabs = [
    { label: "Basic", completed: 100 },
    { label: "Address", completed: 100 },
    { label: "Visa", completed: 100 },
    { label: "Identity", completed: 100 },
  ];

  useEffect(() => {
    if (customers.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }

    if (showform === false) {
      dispatch(resetEditAction());
    }

    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateCustomersListDataMutation();

  const {
    data: customerList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetCustomersListDataQuery({
    length: customers.currentPageSize,
    page: customers.currentPage,
    sort_by: customers.sortBy,
    sort_order: customers.sortOrder,
    search: customers.search,
    franchise_id: customers.franchiseId, // Add the desired franchise ID
    visa_type_id: customers.visaTypeId, // Add the desired visa type ID
    country_id: customers.countryId, // Add the desired country ID
    state_id: customers.stateId, // Add the desired state ID
    nationality_id: customers.nationalityId, // Add the desired nationality ID
    is_active: customers.isActive, // Add the desired active status (e.g., true or false)
    start_date: customers.startDate, // Add the desired start date
    start_date: customers.startDate ? customers.startDate + " 00:00:00" : "",
    end_date: customers.endDate ? customers.endDate + " 00:00:00" : "",
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
  };

  /*********************************
   * PERMISSION CHECKING FUNCTIONS *
   *********************************/

  

  const hasCreatePermission = useMemo(() => {
    let permission = customerList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [customerList]);

  const hasDeletePermission = useMemo(() => {
    let permission = customerList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [customerList]);

  const hasEditPermission = useMemo(() => {
    let permission = customerList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [customerList]);

  const hasViewPermission = useMemo(() => {
    let permission = customerList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? false;
  }, [customerList]);

  /*********************************
   * PERMISSION CHECKING FUNCTIONS ENDS *
   *********************************/

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable(selectedItemID[0]))
        // .unwrap()
        .then((res) => {
          if (res?.payload?.success === false) {
            toast.error(res?.payload?.message);
            setShowDeleteConfirm(false);
          } else {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            toast.success(res?.payload?.data?.message);
          }
        });
    }
  };
  const handleSort = (label) => {
    if (customers.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = customers.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID(ids[0]))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  // chat gpt change below name to new name

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      full_name: (feild, data) => (
        <p className="pro-mb-0">
          {hasViewPermission ? (
            <Link to={`purchase/${data["id"]}`} className="link-primary">
              {data[feild]}
            </Link>
          ) : (
            data[feild]
          )}
        </p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  
  return {
    customerList,
    customers,
    currentPage: customers.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    hasDeletePermission,
    tabs,
    handleTabClick,
    activeTab,
    isFilterShow,
    setIsFilterShow,
    editCustomer,
  };
};

export default useCustomersList;

import React from "react";
import Style from "./changePassword.module.scss";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const ChangePassowrdFormFields = ({
  formik,
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              New Password *
            </label>
            <input
              type="password"
              className={`pro-input lg ${
                formik.errors.new_password &&
                formik.touched.new_password &&
                " error"
              }`}
              id="new_password"
              name="new_password"
              {...formik.getFieldProps("new_password")}
              autoComplete="new-password"
            />
            {formik.touched.new_password &&
              formik.errors.new_password && (
                <span className="error-text">
                  {formik.errors.new_password}
                </span>
              )}
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Confirm Password *
              </label>
              <input
                type="password"
                className={`pro-input lg ${
                  formik.errors.confirm_password && formik.touched.confirm_password && " error"
                }`}
                id="confirm_password"
                name="confirm_password"
                {...formik.getFieldProps("confirm_password")}
                autoComplete="confirm-password"
              />
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <span className="error-text">{formik.errors.confirm_password}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassowrdFormFields;

import React from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import usePermissions from "./usePermissions";

const Permissions = () => {
  const { drawerMenu } = usePermissions();
  return (
    <CommonLayout drawerMenu={drawerMenu} >
      <Outlet />
    </CommonLayout>
  );
};

export default Permissions;

import React from "react";
import Style from "./tableStructure.module.scss";
import { Table } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import { getRevFormatedDate } from "../../../utils/functions/table";

const TableStructurePurchaseRegisterReport = ({
  data,
  printRef,
  state,
  title,
  fields,
  rlm3ReportList,
  getRow,
  isFetching,

  handleEditAction,
  handleDelete,
}) => {
  const dateStringStart = getRevFormatedDate(state?.startDate);
  const dateStringEnd = getRevFormatedDate(state?.endDate);

  let dateStart = undefined;
  let dateEnd = undefined;

  if (dateStringStart) {
    const datePartsStart = dateStringStart?.split(" ");
    const formattedDateStart = datePartsStart?.[0];
    dateStart = formattedDateStart
    ? formattedDateStart.split("-").reverse().join("-")
    : null;
  }

  if (dateStringEnd) {
    const datePartsEnd = dateStringEnd?.split(" ");
    const formattedDateEnd = datePartsEnd?.[0];
    dateEnd = formattedDateEnd
    ? formattedDateEnd.split("-").reverse().join("-")
    : null;
  }

  return (
    <div className={`${Style.table_root} pro-mt-4`} style={{ display: 'none' }}>
      <table ref={printRef}>
        <tbody>
          <tr>
            <td
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <table
                align="center"
                border="0"
                cellspacing="0"
                role="presentation"
                cellpadding="0"
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  borderBottom: "5px solid #003a63",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "76px",
                        height: "30px",
                        paddingBottom: "12px",
                      }}
                    >
                      <a
                        href
                        target="_blank"
                        style={{ width: "76px", height: "30px" }}
                      >
                        <img
                          src={Assets.HEADERLOGO}
                          alt="luluforex-logo"
                          width={76}
                          height={30}
                          style={{
                            display: "inline",
                            textAlign: "center",
                            width: "76px",
                            height: "30px",
                          }}
                        />
                      </a>
                    </td>
                    <td
                      style={{
                        fontSize: "17px",
                        fontWeight: 700,
                        letterSpacing: "0.34px",
                        textAlign: "right",
                        paddingBottom: "12px",
                        color: "#003A63",
                      }}
                    >
                      {title}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          
         

          <tr>
            <td
              style={{
                paddingBottom: "18px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <Table
                // ref={printRef}
                multiSelect={false}
                data={rlm3ReportList?.rows || []}
                fields={rlm3ReportList?.field}
                showCheckBox={false}
                getRow={getRow}
                loading={isFetching}
                perpage={state.currentPageSize}
                assignable={false}
                handleEdit={handleEditAction}
                handleDelete={handleDelete}
                deletable={true}
                extraClasssName={Style.table_wrap}
              />
            </td>
          </tr>


        </tbody>
      </table>
    </div>
  );
};

export default TableStructurePurchaseRegisterReport;

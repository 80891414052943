import React, { useState } from "react";
import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import useMultiTabForm from "./useMultiTabForm";

function Filter({ setShowform }) {
  const {
    activeTab,
    handleTabClick,
    formik,
    renderTabContent,
    handleResetExtraFilters,
  } = useMultiTabForm(setShowform);

  return (
    <div>
      <form action={"javascript:void(0)"}>{renderTabContent()}</form>
    </div>
  );
}

export default Filter;

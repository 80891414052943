import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  search: "",
  franchiseId: "", // New variable for franchise ID
  startDate: "", // New variable for start date
  endDate: "", // New variable for end date
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  showNotesCk: false,
  selectedItemData: {},
};

export const addItemToTable = createAsyncThunk(
  "customers/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "surrender/deleteItemFromTable",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/surrender/delete/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "surrender/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/surrender/show/${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const balanceCheck = createAsyncThunk(
  "surrender/balanceCheck",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/surrender/balance-check", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createSurrender = createAsyncThunk(
  "surrender/createSurrender",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/surrender/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateItemFromTableByID = createAsyncThunk(
  "customers/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRemark = createAsyncThunk(
  "v1/surrender/cancel-request/",
  async ({id, remark}, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`v1/surrender/cancel-request/${id}`, remark);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const surrenderSlice = createSlice({
  name: "surrender",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },

    setStoreFormValues: (state, action) => {
      state.franchiseId = action.payload.franchise;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
     
    },
    resetExtraFilters: (state, action) => {
      state.franchiseId = "";
      state.startDate = "";
      state.endDate = "";
     
    },
  },
  extraReducers: {
    [balanceCheck.pending]: (state, action) => {
      // Handle the pending action here
    },
    [balanceCheck.fulfilled]: (state, action) => {
      // Update state with the calculated amount
      state.calculatedAmount = action.payload.data.data;
      state.isCDFrequired = true;
    },
    [balanceCheck.rejected]: (state, action) => {
      // Update state with the error message
      state.error = action.payload;
    },
    [addRemark.pending]: (state, action) => {
      state.remarkLoading = true;
    
    },
    [addRemark.fulfilled]: (state, action) => {
      // state.selectedItemsDetails = action.payload.data;
      state.remarkLoading = false;
    
    },
    [addRemark.rejected]: (state, action) => {
      state.remarkLoading = false;
    },
  },
});

export const {
  updateConfig,
  setEditTestimonail,
  resetExtraFilters,
  setStoreFormValues,
} = surrenderSlice.actions;

export default surrenderSlice.reducer;

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { addRemark } from "../../../../store/slices/Purchases/purchasesSlice";
import { updateConfig } from "../../../../store/slices/customers/customerDetailsPageSlice";

const useAddRemark = ({ refetch }) => {
  const dispatch = useDispatch();

  const {remarkLoading}  = useSelector((state) => state.purchasesList);

  const { selectedItemData, showNotesCk } =
    useSelector((state) => state.customerDetailsPage);

  let YupValidationObject = {
    remark: Yup.string().required("*Required"),
  };

  let createRemarkVal = Yup.object(YupValidationObject);

  const formik = useFormik({
    initialValues: {
        remark: "",
    },
    validationSchema: createRemarkVal,
    enableReinitialize: true,

    onSubmit: async (values, { resetForm }) => {
        dispatch(addRemark({ id: selectedItemData[0], remark: values })).then((response) => {
          if (response?.payload?.data?.status_code === 200) {
            resetForm();
            refetch();
            handleCloseModal?.();
            dispatch(
              updateConfig((state) => {
                state.showNotesCk = false;
                state.remarkLoading = false;
              })
            );

            toast.success(response?.payload?.data?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.data?.message);
            dispatch(
              updateConfig((state) => {
                state.showNotesCk = false;
                state.remarkLoading = false;
              })
            );
          } else {
            dispatch(
              updateConfig((state) => {
                state.showNotesCk = false;
                state.remarkLoading = false;
              })
            );
            toast.error(response?.payload?.message);
          }
        });
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showNotesCk = false;
      })
    );
  };
  const getFieldError = (fieldName) => {
    if (formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  return {
    formik,
    getFieldError,
    handleCloseModal,
    remarkLoading
  };
};

export default useAddRemark;

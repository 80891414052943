import { Button } from "@wac-ui-dashboard/wac_component_library";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getAllIDTypes } from "../../../../store/slices/customers/registerCustomersSlice";
import { useDispatch } from "react-redux";



const IdentityForm = ({
  formik,
  handleRemove,
  handleAdd,
  setShowform,
  isLoading,
  setActiveTab,
  tabs,
  checkError,
}) => {
  const dispatch = useDispatch();
 
  const [IDtypesOptions, setIDtypes] = useState([]);

  useEffect(() => {
    dispatch(getAllIDTypes())
      .unwrap()
      .then((result) => {
        setIDtypes(
          result.data.data?.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {" "}
      <div>
        {/* Render form fields for the "Identity" tab */}
        {formik.values.group.map((item, index) => (
          <div key={index} className="col pro-mb-5 pro-pb-3">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                ID Type {index === 0 ? "*" :""}
              </label>
              <Select
                name="idType"
                options={IDtypesOptions} // replace with your nationality options
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    `group.[${index}].idType`,
                    selectedOption
                  );
                }}
                className={`pro-input lg ${
                  formik?.errors?.group?.[index]?.idType &&
                  formik?.touched?.group?.[index]?.idType &&
                  "error"
                }`}
                classNamePrefix={`pro-input`}
                onBlur={formik.handleBlur}
                value={formik?.values?.group?.[index]?.idType}
              />
              {formik.touched?.group?.[index]?.idType &&
                formik.errors?.group?.[index]?.idType && (
                  <div className="error-text">
                    {formik.errors?.group?.[index]?.idType}
                  </div>
                )}
            </div>

            <div className="input-wrap pro-mb-4">
              <label
                htmlFor={`idNumber-${index}`}
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                ID Number {index === 0 ? "*" :""}
              </label>
              <input
                id={`idNumber-${index}`}
                name={`group.[${index}].idNumber`}
                value={formik.values?.group?.[index].idNumber}
                onChange={formik.handleChange}
                placeholder="ID Number"
                className={`pro-input lg ${
                  formik.errors?.group &&
                  formik.touched?.group &&
                  formik.errors?.group?.[index]?.idNumber &&
                  "error"
                }`}
              />
              {formik.touched?.group?.[index]?.idNumber &&
                formik.errors?.group?.[index]?.idNumber && (
                  <div className="error-text">
                    {formik.errors?.group?.[index]?.idNumber}
                  </div>
                )}
            </div>

            <div className="input-wrap pro-mb-4">
              <label
                htmlFor={`idIssueDate-${index}`}
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Issue Date {index === 0 ? "*" :""}
              </label>
              <input
                type="date"
                id={`idIssueDate-${index}`}
                name={`group.[${index}].idIssueDate`}
                value={formik.values.group?.[index].idIssueDate}
                onChange={formik.handleChange}
                placeholder="Issue Date"
                className={`pro-input lg  ${
                  formik.errors.group &&
                  formik.touched.group &&
                  formik.errors.group?.[index]?.idIssueDate &&
                  "error"
                }`}
                max={new Date().toISOString().split("T")[0]} // Set max date to today
              />
              {formik.touched.group?.[index]?.idIssueDate &&
                formik.errors.group?.[index]?.idIssueDate && (
                  <div className="error-text">
                    {formik.errors.group?.[index]?.idIssueDate}
                  </div>
                )}
            </div>

            <div className="input-wrap">
              <label
                htmlFor={`idExpiryDate-${index}`}
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Expiry Date {index === 0 ? "*" :""}
              </label>
              <input
                type="date"
                id={`idExpiryDate-${index}`}
                name={`group.[${index}].idExpiryDate`}
                value={formik.values.group?.[index].idExpiryDate}
                onChange={formik.handleChange}
                placeholder="Expiry Date"
                className={`pro-input lg ${
                  formik.errors.group &&
                  formik.touched.group &&
                  formik.errors.group?.[index]?.idExpiryDate &&
                  "error"
                }`}
                min={formik.values.group?.[index].idIssueDate}
              />
              {formik.touched.group?.[index]?.idExpiryDate &&
                formik.errors.group?.[index]?.idExpiryDate && (
                  <div className="error-text">
                    {formik.errors.group?.[index]?.idExpiryDate}
                  </div>
                )}
            </div>
            {index !== 0 && (
              <button
                type="button"
                onClick={() => handleRemove(index)}
                className="pro-btn pro-btn-link pro-mt-2 pro-px-0 pro-ms-auto"
              >
                <span className="material-symbols-outlined"> delete </span>
                <span>Remove</span>
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={() => handleAdd()}
          className="pro-mt-4 pro-btn pro-btn-outline"
        >
          <span>Add New</span>
          <span className="material-symbols-outlined"> add </span>
        </button>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
         type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${isLoading ? "loading" : ""}`}
          type="submit"
          disabled={isLoading}
          onClick={checkError}
        >
            {isLoading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Submit
        </Button>
      </div>
    </>
  );
};

export default IdentityForm;

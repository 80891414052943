import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import {
  getCurrency,
  getAllBranches,
} from "../../../../store/slices/Currency/addNewCurrencySlice";



const BasicForm = ({ formik, setShowform, handleResetFilter }) => {
  const dispatch = useDispatch();

  const [userCurrencyOptions, setCurrencyOptions] = useState([]);

  const [userBranchesOptions, setBranchesOptions] = useState([]);

  useEffect(() => {
    dispatch(getCurrency())
      .unwrap()
      .then((result) => {
        setCurrencyOptions(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllBranches())
      .unwrap()
      .then((result) => {
        setBranchesOptions(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Currency
          </label>
          <Select
            name="currency"
            options={userCurrencyOptions} // replace with your currency options
            onChange={(selectedOption) => {
              formik.setFieldValue("currency", selectedOption);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.currency}
            className={`pro-input lg ${
              formik.errors.currency && formik.touched.currency && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.currency && formik.errors.currency && (
            <div className="error">{formik.errors.currency}</div>
          )}
        </div>
      </div>

      {localStorage.getItem("user_role") == 1 && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="country"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch
            </label>
            <Select
              name="branch"
              options={userBranchesOptions} // replace with your branch options
              onChange={(selectedOption) => {
                formik.setFieldValue("branch", selectedOption);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.branch}
              className={`pro-input lg ${
                formik.errors.branch && formik.touched.branch && "error"
              }`}
              classNamePrefix={`pro-input`}
            />
            {formik.touched.branch && formik.errors.branch && (
              <div className="error">{formik.errors.branch}</div>
            )}
          </div>
        </div>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => {
            setShowform(false);
            handleResetFilter();
          }}
        >
          Reset
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={false}
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetSurrendersListDataQuery,
  useUpdateSurrendersListDataMutation,
} from "../../../store/queries/surrenders";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
} from "../../../store/slices/surrender/surrenderSlice";
import { Link, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import styles from "./surrender.module.scss"

const useSurrenderList = () => {
  const dispatch = useDispatch();
  let user_type = localStorage.getItem("user_role");
  const surrender = useSelector((state) => state.surrender);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [is_Cancelled, setIsCancelled] = useState(false)

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];
  const navigate = useNavigate();
  const [updateSurrendersFields] = useUpdateSurrendersListDataMutation();

  const {
    data: surrendersList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetSurrendersListDataQuery({
    length: surrender.currentPageSize,
    page: surrender.currentPage,
    sort_by: surrender.sortBy,
    sort_order: surrender.sortOrder,
    start_date: surrender.startDate ? surrender.startDate + " 00:00:00" : "",
    end_date: surrender.endDate ? surrender.endDate + " 00:00:00" : "",
    search: surrender.search,
    franchise_id: surrender.franchiseId.value,
  });
  useEffect(() => {
    refetch();
  }, []);

  const hasCreatePermission = useMemo(() => {
    let permission = surrendersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [surrendersList]);

  const hasDeletePermission = useMemo(() => {
    let permission = surrendersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [surrendersList]);

  const hasEditPermission = useMemo(() => {
    let permission = surrendersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [surrendersList]);

  const hasViewPermission = useMemo(() => {
    let permission = surrendersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? false;
  }, [surrendersList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable(selectedItemID[0])).then((res) => {
        
        if (res?.payload?.data?.success === false) {
          toast.error(res?.payload?.data?.message);
          setShowDeleteConfirm(false);
        } else {
          refetch();
          setSelectedItemID("");
          setShowDeleteConfirm(false);
          toast.success(res?.payload?.data?.message);
        }
      });
    }
  };

  const handleEditAction = (ids) => {
    navigate(`/surrender/edit-surrender/${ids[0]}`);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleAddsurrender = () => {
    navigate(`/surrender/add-surrender`);
  };
  const handleSort = (label) => {
    if (surrender.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = surrender.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      surrender_no: (feild, data) =>
        hasViewPermission ? (
          <p className="pro-mb-0 ">
            {data.status === "Confirmed" ? (
            <Link to={`detail-surrender/${data["id"]}`} className="link-primary">
            {data[feild]}
          </Link>
          ) : data.status === "Cancelled" ? (
            <Link to={`detail-surrender/${data["id"]}`} className="pro-link-danger">
              {data[feild]}
            </Link>
          ) : data.status === "Not Confirmed" ? (
            <Link to={`detail-surrender/${data["id"]}`} className="pro-link-warning">
              {data[feild]}
            </Link>
          ) : (
            <Link to={`detail-surrender/${data["id"]}`}>
              {data[feild]}
            </Link>
          )}
            
          </p>
        ) : (
          <p className="pro-mb-0">{data[feild]}</p>
        ),

        
        status: (feild, data) => (
          <p className="pro-mb-0">
            {data.status === "Confirmed" ? (
              <span className={`${styles.conform_pill} ${styles.green}`}>
                {data[feild]}
              </span>
            ) : data.status === "Cancellation Requested" ? (
              <span className={`${styles.conform_pill} ${styles.blue}`}>
                {data[feild]}
              </span>
            ) : data.status === "Not Confirmed" ? (
              <span className={`${styles.conform_pill} ${styles.orange}`}>
                {data[feild]}
              </span>
            ) : (
              <span className={`${styles.conform_pill} ${styles.red}`}>
                {data[feild]}
              </span>
            )}
          </p>
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const checkIsActive = (id) => {
    if (Array.isArray(id)) {
      let isSurrenderExist = surrendersList?.rows.data.filter(
        (row) => row.id === id[0]
      );
      if (
        isSurrenderExist.length > 0 &&
        isSurrenderExist[0]["is_confirmed"] == "Cancelled") {
        setIsCancelled(true);
        return;
      }
      else {
        setIsCancelled(false);
      }
    }
  }

  const handleAddNote = (data) => {
    if (Array.isArray(data)) {
      let isSurrenderExist = surrendersList?.rows.data.filter(
        (row) => row.id === data[0]
      );
      if (
        isSurrenderExist.length > 0 &&
        isSurrenderExist[0]["is_confirmed"] == "Cancelled") {
        toast.error("Can't Request Cancellation for Cancelled surrender!");
        return;
      }
      else {
        dispatch(
          updateConfig((state) => {
            state.showNotesCk = true;
            state.selectedItemData = data;
          })
        );
      }
    }
  };

  const handleCloseNotesCk = (close = true) => {
    dispatch(
      updateConfig((state) => {
        state.showNotesCk = false;
      })
    );
  };

  return {
    surrendersList,
    surrender,
    currentPage: surrender.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleAddsurrender,
    // handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    setIsFilterShow,
    isFilterShow,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateSurrendersFields,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    is_Cancelled,
    user_type,
    checkIsActive,
    handleAddNote,
    handleCloseNotesCk
  };
};

export default useSurrenderList;

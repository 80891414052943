import {
  Button,
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";

import AddFormContainer from "./AddForm";
import { useSelector } from "react-redux";
import useLeadsList from "./useLeadsList";
import Filter from "./Filter";
import Style from './customers.module.scss';

const LeadsList = () => {
  const {
    LeadsList,
    leads,
    lead,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    isModelShow,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    setIsFilterShow,
    isFilterShow,
    showEditModal,
    handleDelete,
    message,
    showform,
    setShowform,
    setIsModelShow,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    filter,
    setFilter,
  } = useLeadsList();

  

  return (
    <>
      <HeadingGroup
        title={"Leads"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ?? false ? "Add new" : ""}
        handleClick={() => {
          setShowform(true);
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col-10">
            <SearchFilters
              data={LeadsList?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
            />
          </div>
          <div className="col-auto pro-ms-auto">
            <Button
              onClick={() => {
                setIsFilterShow(true);
              }}
              className="pro-btn pro-btn-primary pro-px-5 lg"
            >
              Filter
            </Button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={LeadsList?.rows?.data || []}
          uniqueID={"id"}
          fields={LeadsList?.field}
          showCheckBox={hasEditPermission}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={leads.currentPageSize}
          assignable={false}
          
          handleEdit={handleEditAction}
          handleDelete={handleDelete}
          deletable={hasDeletePermission??false}
        />

        {LeadsList?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={LeadsList?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered={true}
          show={isModelShow}
          handleClose={() => {
            setIsModelShow(false);
          }}
          title={"Message"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          propStyle={{root : Style.modal_root}}
        >
          <div className="pro-p-4 pro-pe-2">
            <div className={`${Style.modal_sticky_body} pro-pe-2`}>
              <p className="pro-mb-0 pro-fw-bold">{message}</p>
            </div>
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={LeadsList?.field}
              moduleId={LeadsList?.table_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
        <OffCanvasLayout
          centered={false}
          show={showform}
          handleClose={() => {
            setShowform(false);
          }}
          backdrop="static"
          title={`${
            Object.keys(leads.singleLeadInfo).length > 0
              ? "Update Lead"
              : "Add a new Lead"
          }`}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddFormContainer
            setShowform={setShowform}
            refetch={refetch}
            lead={lead}
          />
        </OffCanvasLayout>
        <OffCanvasLayout
          centered={true}
          show={isFilterShow}
          handleClose={() => {
            setIsFilterShow(false);
          }}
          backdrop="static"
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setIsFilterShow} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default LeadsList;

import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ChangePassowrdFormFields from "../FormFields";
import useChangePassword from "./useChangePassword";

const ChangePasswordFormFieldsContainer = ({
  setChangePassword,
  refetch,
  changePasswordUserID,
  setChangePasswordUserID,
}) => {
  const { formik, loading } = useChangePassword({
    setChangePassword,
    refetch,
    changePasswordUserID,
    setChangePasswordUserID,
  });

  return (
    <div className={`pro-p-4`}>
      <ChangePassowrdFormFields
        formik={formik}
        setChangePassword={setChangePassword}
      />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setChangePassword(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {loading ? "Please wait..." : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordFormFieldsContainer;

import React from 'react';
import PropTypes from 'prop-types';
import Style from './stippedTable.module.scss';

const StippedTable = ({ data, extraClassName }) => {
  return (
    <div className={`${extraClassName ? extraClassName : ''} ${Style.root}`}>
      <table className={`${Style.root_table} pro-w-100`}>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? Style.even_row : Style.odd_row}>
              <td className={Style.td_left}>
                <p className='pro-mb-0 pro-font-sm pro-fw-medium'>{item.label}</p>
              </td>
              <td className={Style.td_right}>
                <p className='pro-mb-0  pro-fw-medium'>{item.value}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

StippedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  extraClassName: PropTypes.string,
};

export default StippedTable;

import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  getAllCountries,
  getAllStates,
} from "../../../../store/slices/customers/registerCustomersSlice";
import { useEffect } from "react";



const AddressForm = ({ formik, setActiveTab, tabs, setShowform }) => {
  const dispatch = useDispatch();

  const [statesOptions, setStates] = useState([]);
  const [countriesOptions, setCountries] = useState([]);

  useEffect(() => {
    dispatch(getAllStates())
      .unwrap()
      .then((result) => {
        setStates(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getAllCountries())
      .unwrap()
      .then((result) => {
        setCountries(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Address" tab */}
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Country *
          </label>
          <Select
            name="country"
            options={countriesOptions} // replace with your country options
            onChange={(selectedOption) =>
              formik.setFieldValue("country", selectedOption)
            }
            onBlur={formik.handleBlur}
            value={formik.values.country}
            className={`pro-input lg ${
              formik.errors.country && formik.touched.country && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.country && formik.errors.country && (
            <div className="error-text">{formik.errors.country}</div>
          )}
        </div>
      </div>
      { formik?.values?.country?.value === 92  && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="state"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              State *
            </label>

            <Select
              name="state"
              options={statesOptions} // replace with your country options
              onChange={(selectedOption) =>
                formik.setFieldValue("state", selectedOption)
              }
              onBlur={formik.handleBlur}
              value={formik.values.state}
              className={`pro-input lg ${
                formik.errors.state && formik.touched.state && "error"
              }`}
            />
            {formik.touched.state && formik.errors.state && (
              <div className="error-text">{formik.errors.state}</div>
            )}
          </div>
        </div>
      )}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="city" className="pro-font-sm pro-mb-1 pro-fw-medium">
            City *
          </label>
          <input
            type="text"
            name="city"
            id="city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            className={`pro-input lg ${
              formik.errors.city && formik.touched.city && "error"
            }`}
          />
          {formik.touched.city && formik.errors.city && (
            <div className="error-text">{formik.errors.city}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Address *
          </label>
          <textarea
            rows={5}
            className={`pro-input lg ${
              formik.errors.address && formik.touched.address && " error"
            }`}
            id="address"
            name="address"
            {...formik.getFieldProps("address")}
          ></textarea>
          {formik.touched.address && formik.errors.address && (
            <span className="error-text">{formik.errors.address}</span>
          )}
        </div>
      </div>
      {formik?.values?.country?.value === 92 &&
        formik?.values?.visaType?.label === "Non Resident" && (
          <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Permanent Address *
              </label>
              <textarea
                rows={5}
                className={`pro-input lg ${
                  formik.errors.permanentAddress &&
                  formik.touched.permanentAddress &&
                  " error"
                }`}
                id="permanentAddress"
                name="permanentAddress"
                {...formik.getFieldProps("permanentAddress")}
              ></textarea>
              {formik.touched.permanentAddress && formik.errors.permanentAddress && (
            <span className="error-text">{formik.errors.permanentAddress}</span>
          )}
            </div>
          </div>
        )}

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button  type="button" className={"pro-btn-link lg pro-px-3"} onClick={() => setShowform(false)}>
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={
          //   formik?.errors?.country != undefined ||
          //   formik?.errors?.state != undefined ||
          //   formik?.errors?.city != undefined ||
          //   formik?.errors?.address != undefined ||
          //   formik?.errors?.permanentAddress != undefined
          // }
          onClick={() => setActiveTab(tabs[2])}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AddressForm;

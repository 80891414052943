import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";

import OrderColumn from "../../Global/OrderColumn";

import useRLM1 from "./useRLM1";
import Style from "./customers.module.scss";
import TableStructure from "../TableStructure";
import EmptyData from "../../Global/EmptyData";
import Select from "react-select";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import { useState } from "react";

const RLM1 = () => {
  const {
    rlm1ReportList,
    rlm1Report,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDateRangeChange,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    date,
    branchesOptions,
    handleActionSetFranchiseies,
    handleActionSetFranchiseById,
    printRef,
    handlePrintClick,
    user_type,
    franchiseOptions,
    namePdf,
  } = useRLM1();

    return (
    <>
      <HeadingGroup
        title={"RLM 1"}
        className={`pro-mb-4`}
        buttonTitle={/*hasCreatePermission ?*/ "Export" || ""}
        handleClick={() =>
          handlePrintClick(rlm1ReportList?.data?.table?.rows?.length)
        }
        buttonProps={{
          disabled:
            rlm1ReportList?.data?.table?.rows?.length > 0 ? "" : "disabled",
        }}
        disabled={"disabled"}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={rlm1ReportList?.data?.filters}
          // onDateChange={handleDateChange}
          // isDateRange={false}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          // initialDateRange={date}
          // showDateRange
          searchable={false}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>

              {user_type != 2 ? (
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseies}
                    options={branchesOptions}
                    value={branchesOptions?.filter(
                      (item) =>
                        item?.label === rlm1Report?.selectedBranches?.label
                    )}
                    placeholder="Select Branch"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable={true}
                    isDisabled={(user_type == 3) ? true : false}
                  />
                </div>
              ) : (<></>)}
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseById}
                    options={franchiseOptions}
                    value={franchiseOptions?.filter(
                      (item) =>
                        item?.label === rlm1Report?.selectedFranchise?.label
                    )}
                    placeholder="Select Franchise"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isDisabled={((user_type == 2) || (rlm1Report?.selectedBranches?.label == "All") || (rlm1Report?.selectedBranches?.label == undefined)) ? true : false}
                    isClearable={true}
                  />
                </div>
            </>
          }
        // actionOptions={branchesOptions}
        // onActionOptionChange={handleActionSetFranchiseies}
        />

        {rlm1ReportList?.data?.table?.rows?.length > 0 ? (
          <div className={`${Style.table_wraper} pro-pt-3`}>
            <TableStructure
              showMonth={false}
              data={rlm1ReportList?.data?.table}
              printRef={printRef}
              state={rlm1Report}
              selectedBranch={namePdf ? namePdf : ""}
              title={"RLM 1"}
            />
            <table className="pro-table">
              <tbody>
                {rlm1ReportList?.data?.table?.labels?.map((label, index) => (
                  <tr key={index}>
                    {index === 0 ? <th>{label}</th> : <td>{label}</td>}

                    {rlm1ReportList?.data?.table.rows.map((row, rowIndex) => {
                      if (index === 0) {
                        return <th>{row[index]}</th>;
                      } else {
                        return <td key={rowIndex}>{row[index]}</td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <EmptyData subtitle={"Select the filter to generate the Report"} />
        )}

        {/* <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={rlm1ReportList?.data?.fields}
              moduleId={rlm1ReportList?.data?.module_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout> */}

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this item?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default RLM1;

import { useDispatch, useSelector } from "react-redux";
import styles from "./purchases.module.scss";
import {
  useGetPurchasesListDataByIdQuery,
  useGetPurchasesListDataQuery,
  usePurchasesDeleteMultipleMutation,
  usePurchasesDeleteSingleMutation,
  useUpdatePurchasesListDataMutation,
} from "../../../store/queries/purchases";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
  fetchPurchaseById,
  getCustomerDataByID,
  clearCustomerData,
} from "../../../store/slices/customers/customerDetailsPageSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const usePurchases = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customersID } = useParams();
  let user_type = localStorage.getItem("user_role");

  // const customerDetailsPage = useSelector((state) => state.purchase);

  const customerDetailsPage = useSelector((state) => state.customerDetailsPage);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const [selectedEdititem, setSelectedEdititem] = useState(false);
  const [is_Cancelled, setIsCancelled] = useState(false)
  const [is_Editable, setIsEditable] = useState(false)

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (customerDetailsPage.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (customerDetailsPage?.selectedItemsDetails?.id !== customersID) {
      dispatch(getCustomerDataByID(customersID));
    }
  }, []);

  const [updatePurchasesFields] = useUpdatePurchasesListDataMutation();

  const {
    data: purchaseList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPurchasesListDataByIdQuery({
    customerID: customersID,
    page_size: customerDetailsPage.currentPageSize,
    page: customerDetailsPage.currentPage,
    sort_by: customerDetailsPage.sortBy,
    sort_order: customerDetailsPage.sortOrder,
    start: customerDetailsPage.startDate,
    end: customerDetailsPage.endDate,
    search: customerDetailsPage.search,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = purchaseList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [purchaseList]);

  const hasDeletePermission = useMemo(() => {
    let permission = purchaseList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [purchaseList]);

  const hasEditPermission = useMemo(() => {
    let permission = purchaseList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [purchaseList]);

  const hasViewPermission = useMemo(() => {
    let permission = purchaseList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? false;
  }, [purchaseList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    // if (Array.isArray(ids)) {
    //   let isPurchaseExist = purchaseList?.rows.data.filter(
    //     (row) => row.id === ids[0]
    //   );
    //   if (
    //     isPurchaseExist.length > 0 &&
    //     isPurchaseExist[0]["is_confirmed"] == "Confirmed"
    //   ) {
    //     toast.error("Confirmed purchase cannot be deleted!");
    //     return;
    //   }

    //   setShowDeleteConfirm(true);
    //   setSelectedItemID(ids);
    // }

    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            toast.success("Selected item has been deleted.");
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          handleUnknownError(setShowDeleteConfirm);
        });
    } else {
      setShowDeleteConfirm(false);
      handleUnknownError(setShowDeleteConfirm);
    }
  };
  const handleSort = (label) => {
    if (customerDetailsPage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            customerDetailsPage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleUnknownError = () => {
    alert("Sorry something went wrong!");
  };

  const handleEditAction = (ids) => {
    
      dispatch(fetchPurchaseById(ids[0]))
        .unwrap()
        .then((result) => {
          navigate(
            `/customers/purchase/${customersID}/edit-purchase/${ids[0]}`
          );
        });
  };

  const checkIsActive = (id) => {
    if (Array.isArray(id)) {
      let isPurchaseExist = purchaseList?.rows.data.filter(
        (row) => row.id === id[0]
      );
      if (
        isPurchaseExist.length > 0 &&
        isPurchaseExist[0]["is_confirmed"] == "Cancelled") {
        setIsCancelled(true);
        return;
      }
      else if(isPurchaseExist.length > 0 &&
        isPurchaseExist[0]["is_confirmed"] == "Confirmed") {
          setIsEditable(true);
        }
      else {
        setIsCancelled(false);
        setIsEditable(false);
      }
    }
  }

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleAddPurchase = () => {
    navigate(`/customers/purchase/${customersID}/add-purchase`);
    // dispatch(
    //   globalUpdateConfig((state) => {
    //     state.showEditModal = false;
    //   })
    // );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
      status: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      franchise: (feild, data) => (
        <p className="pro-mb-0">{data[feild].name}</p>
      ),
      invoice_no: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
              className="link-primary"
            >
              {data[feild]}
            </Link>
          ) : data.is_confirmed === "Cancelled" ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
              className="pro-link-danger"
            >
              {data[feild]}
            </Link>
          ) : data.is_confirmed === "Not Confirmed" ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
              className="pro-link-warning"
            >
              {data[feild]}
            </Link>
          ) : (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
            >
              {data[feild]}
            </Link>
          )}
        </p>
      ),
      customer_name: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" && hasViewPermission ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
              className="link-primary"
            >
              {data[feild]}
            </Link>
          ) : hasViewPermission && data.is_confirmed === "Not Confirmed" ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
            >
              {data[feild]}
            </Link>
          ) : (
            data[feild]
          )}
        </p>
      ),

      id: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" && hasViewPermission ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
              className="link-primary"
            >
              {data[feild]}
            </Link>
          ) : hasViewPermission && data.is_confirmed === "Not Confirmed" ? (
            <Link
              to={`/customers/purchase/${customerDetailsPage?.customerDetails?.id}/detail-purchase/${data["id"]}`}
            >
              {data[feild]}
            </Link>
          ) : (
            data[feild]
          )}
        </p>
      ),
      is_confirmed: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" ? (
            <span className={`${styles.conform_pill} ${styles.green}`}>
              {data[feild]}
            </span>
          ) : data.is_confirmed === "Cancellation Requested" ? (
            <span className={`${styles.conform_pill} ${styles.blue}`}>
              {data[feild]}
            </span>
          ) : data.is_confirmed === "Not Confirmed" ? (
            <span className={`${styles.conform_pill} ${styles.orange}`}>
              {data[feild]}
            </span>
          ) : (
            <span className={`${styles.conform_pill} ${styles.red}`}>
              {data[feild]}
            </span>
          )}
        </p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleAddNote = (data) => {
    if (Array.isArray(data)) {
      let isPurchaseExist = purchaseList?.rows.data.filter(
        (row) => row.id === data[0]
      );
      if (
        isPurchaseExist.length > 0 &&
        isPurchaseExist[0]["is_confirmed"] == "Cancelled") {
        toast.error("Can't Request Cancellation for Cancelled purchase!");
        return;
      }
      else {
        dispatch(
          updateConfig((state) => {
            state.showNotesCk = true;
            state.selectedItemData = data;
          })
        );
      }
    }
  };

  const handleCloseNotesCk = (close = true) => {
    dispatch(
      updateConfig((state) => {
        state.showNotesCk = false;
      })
    );
  };

  return {
    purchaseList,
    customerDetailsPage,
    currentPage: customerDetailsPage.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updatePurchasesFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    handleAddPurchase,
    handleAddNote,
    handleCloseNotesCk,
    user_type,
    is_Cancelled,
    is_Editable,
    checkIsActive
  };
};

export default usePurchases;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../../store/slices/customers/customersSlice";

const useMultiTabForm = (setShowform) => {
  const dispatch = useDispatch();

  const {
    franchiseId,
    visaTypeId,
    countryId,
    stateId,
    isActive,
    startDate,
    endDate,
    nationalityId,
  } = useSelector((state) => state.customers);

  const validationSchema = Yup.object({
    franchise: Yup.object().nullable(),
    visaType: Yup.object().nullable(),
    nationality: Yup.object().nullable(),
    country: Yup.object().nullable(),
    state: Yup.object().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    franchise: franchiseId,
    visaType: visaTypeId,
    nationality: nationalityId,
    startDate: startDate,
    endDate: endDate,
    country: countryId,
    state: stateId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        franchise,
        visaType,
        nationality,
        startDate,
        endDate,
        country,
        state,
      } = values;

      if (
        franchise === "" &&
        visaType === "" &&
        nationality === "" &&
        country === "" &&
        state === "" &&
        startDate === "" &&
        endDate === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <BasicForm
        formik={formik}
        handleResetExtraFilters={handleResetExtraFilters}
      />
    );
  };

  const handleResetExtraFilters = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    formik,
    renderTabContent,
    handleResetExtraFilters,
  };
};

export default useMultiTabForm;

import React from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useLeads from "./useLeads";

const Leads = () => {
  const { drawerMenu } = useLeads();
  return (
    <CommonLayout drawerMenu={drawerMenu} >
      <Outlet />
    </CommonLayout>
  );
};

export default Leads;

import React from "react";
import Style from "./purchaseForm.module.scss";
import TransactionDetails from "./TransactionDetails";
import TransactionForm from "./TransactionForm";
import PaymentMode from "./paymentMode";
import useSurrenderForm from "./useSurrenderForm";

const SurrenderFormContainer = () => {
  const {
    formik,
    currencyOptions,
    paymentModeOptions,
    isLoading,
    disable,
    balanceVerified,
    handleRemoveTransaction,
    handleAddTransaction,
  } = useSurrenderForm();

  return (
    <>
      <div className="row gx-5">
        <div className="col-8 pro-ps-6 pro-pe-5">
          <div className="row gx-6">
            <div className="col-6">
              <h6 className="pro-ttl pro-mb-4">Transaction</h6>
              {formik.values.transactions.map((transaction, index) => (
                <TransactionForm
                  key={index}
                  transaction={transaction}
                  index={index}
                  currencyOptions={currencyOptions}
                  formik={formik}
                  handleRemoveTransaction={handleRemoveTransaction}
                />
              ))}
              {balanceVerified ? (
                <button
                  type="button"
                  className="pro-btn pro-btn-secondary"
                  onClick={(e) => handleAddTransaction(e)}
                >
                  Add Transaction
                </button>
              ) : (
                <></>
              )}
            </div>

            <div className="col-6">
              <PaymentMode
                formik={formik}
                paymentModeOptions={paymentModeOptions}
              />
            </div>
          </div>
        </div>

        <div className={`col-4 ${Style.transaction_table_root_wrap} pro-px-5`}>
          <div
            className={`${Style.transaction_table_root} pro-pt-5 pro-px-2 pro-d-flex pro-flex-column`}
          >
            <TransactionDetails formik={formik} />

            <div
              className={`pro-py-5 pro-w-100 pro-mt-auto ${Style.btn_container}`}
            >
              <button
                disabled={!balanceVerified }
                onClick={formik.handleSubmit}
                className="pro-btn pro-btn-primary lg pro-w-100"
              >
                {isLoading ? "Loading..." : "Proceed to payment"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurrenderFormContainer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  search: "",
  franchiseId: "", // New variable for franchise ID
  isConfirmedId: "", // New variable for active status
  startDate: "", // New variable for start date
  endDate: "", // New variable for end date
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
};

export const addItemToTable = createAsyncThunk(
  "purchases/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchPurchaseById = createAsyncThunk(
  "customersDetailsPage/fetchById",
  async (purchaseId, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/purchase/show/${purchaseId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "v1/surrender/delete",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/surrender/delete/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "customers/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/testimonial/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "customers/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRemark = createAsyncThunk(
  "v1/purchase/cancel-request",
  async ({id, remark}, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`v1/purchase/cancel-request/${id}`, remark);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllFranchises = createAsyncThunk(
  "customersRegForm/getAllFranchise",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/franchise`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cancellationRequestSurrenderSlice = createSlice({
  name: "cancellationRequestSurrender",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.franchiseId = action.payload.franchise;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.franchiseId = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},
    [fetchPurchaseById.pending]: (state, action) => {
      state.surrenderDetailsLoading = "loading";
    
    },
    [fetchPurchaseById.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data;
      state.surrenderDetailsLoading = "success";
    
    },
    [fetchPurchaseById.rejected]: (state, action) => {
      state.surrenderDetailsLoading = "error";
    },
  },
});

export const {
  updateConfig,
  setEditTestimonail,
  resetExtraFilters,
  setStoreFormValues,
} = cancellationRequestSurrenderSlice.actions;

export default cancellationRequestSurrenderSlice.reducer;

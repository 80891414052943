import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import useRLM2 from "./useRLM2";
import Style from "./rlm2.module.scss";
import TableStructure from "../TableStructure";
import EmptyData from "../../Global/EmptyData";
import Select from "react-select";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const RLM2 = () => {
  const {
    rlm2ReportList,
    rlm2Report,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handleDateRangeChange,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    date,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    // hasViewPermission,
    hasEditPermission,
    branchesOptions,
    handleActionSetBranches,
    printRef,
    handlePrintClick,
    franchiseOptions,
    user_type,
    handleActionSetFranchiseById,
    handleActionSetFranchiseies,
    namePdf
   } = useRLM2();

  
  return (
    <>
      <HeadingGroup
        title={"RLM 2"}
        className={`pro-mb-4`}
        buttonTitle={/*hasCreatePermission ?*/ "Export" || ""}
        handleClick={handlePrintClick}
        buttonProps={{
          disabled:
            rlm2ReportList?.data?.table?.rows?.length > 0 ? "" : "disabled",
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={rlm2ReportList?.data?.filters}
          // onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          // isDateRange={false}
          // showDateRange
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          initialDateRange={date}
          datePickerProps={true}
          showActions={false}
          searchable={false}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>

              {user_type != 2 ? (
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseies}
                    options={branchesOptions}
                    value={branchesOptions?.filter(
                      (item) =>
                        item?.label === rlm2Report?.selectedBranches?.label
                    )}
                    placeholder="Select Branch"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable={true}
                    isDisabled={(user_type == 3) ? true : false}
                  />
                </div>
              ) : (<></>)}
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseById}
                    options={franchiseOptions}
                    value={franchiseOptions?.filter(
                      (item) =>
                        item?.label === rlm2Report?.selectedFranchise?.label
                    )}
                    placeholder="Select Franchise"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isDisabled={((user_type == 2) || (rlm2Report?.selectedBranches?.label == "All") || (rlm2Report?.selectedBranches?.label == undefined)) ? true : false}
                    isClearable={true}
                  />
                </div>

              {/* <div className="col-auto dropdown-filter">
                <Select
                  onChange={handleActionSetBranches}
                  options={branchesOptions}
                  value={branchesOptions?.filter(
                    (item) =>
                      item?.label === rlm2Report?.selectedBranches?.label
                  )}
                  placeholder="Select Branch"
                  classNamePrefix={`pro-input`}
                  className={`pro-input lg search-filter-select`}
                />
              </div> */}
            </>
          }
          // actionOptions={branchesOptions}
          // onActionOptionChange={handleActionSetBranches}
        />

        {rlm2ReportList?.data?.table?.rows?.length > 0 ? (
          <div className={Style.table_wraper}>
            <TableStructure
              data={rlm2ReportList?.data?.table}
              printRef={printRef}
              state={rlm2Report}
              title={"RLM 2"}
              showMonth={false}
              selectedBranch={namePdf ? namePdf : ""}
            />
            <table className="pro-table">
              <tbody>
                {rlm2ReportList?.data?.table?.labels?.map((label, index) => (
                  <tr key={index}>
                    {index === 0 ? <th>{label}</th> : <td>{label}</td>}

                    {rlm2ReportList?.data?.table.rows?.map((row, rowIndex) => {
                      if (index === 0) {
                        return <th>{row[index]}</th>;
                      } else {
                        return <td key={rowIndex}>{row[index]}</td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <EmptyData subtitle={"Select the filter to generate the Report"} />
        )}

        {rlm2ReportList?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={rlm2ReportList?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {/* <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={rlm2ReportList?.data?.fields}
              moduleId={rlm2ReportList?.data?.module_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout> */}

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default RLM2;

import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch } from "react-redux";

import { Button } from "@wac-ui-dashboard/wac_component_library";
import {getAllFranchises } from "../../../../store/slices/customers/registerCustomersSlice";
import { getAllLeads } from "../../../../store/slices/Leads/LeadsSlice";
const LeadFilterForm = ({ formik, handleResetExtraFilters }) => {
  const dispatch = useDispatch();
  const [franchiseOptions, setFranchise] = useState([]);
  const [leadTypeOptions, setLeadType] = useState([]);

  useEffect(() => {
    dispatch(getAllFranchises())
      .unwrap()
      .then((result) => {
        setFranchise(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

      dispatch(getAllLeads())
      .unwrap()
      .then((result) => {
        setLeadType(
          result?.data?.data?.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Lead Type *
          </label>
          <Select
            name="lead_type_id"
            options={leadTypeOptions} // replace with your franchise options
            onChange={(selectedOption) =>
              formik.setFieldValue("lead_type_id", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.lead_type_id && formik.touched.lead_type_id
                ? "error"
                : ""
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.lead_type_id}
          />
          {formik.touched.lead_type_id && formik.errors.lead_type_id && (
            <span className="error-text">{formik.errors.lead_type_id}</span>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Franchise
          </label>
          <Select
            name="franchise"
            options={franchiseOptions} // replace with your franchise options
            onChange={(selectedOption) =>
              formik.setFieldValue("franchise", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.franchise && formik.touched.franchise && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.franchise}
          />
          {formik.touched.franchise && formik.errors.franchise && (
            <div className="error">{formik.errors.franchise}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.startDate && formik.touched.startDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <div className="error">{formik.errors.startDate}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            End Date
          </label>
          <input
            type="date"
            name="endDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.endDate && formik.touched.endDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            min={formik?.values?.startDate ? formik.values.startDate : ""}
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <div className="error">{formik.errors.endDate}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={handleResetExtraFilters}
        >
          Reset
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={false}
          onClick={formik.handleSubmit}
        >
          Filter
        </Button>
      </div>
    </div>
  );
};

export default LeadFilterForm;

import React, { useState } from "react";
import useUserFilter from "./useUserFilter";

function Filter({ setShowform, refetch }) {
  const { renderTabContent } = useUserFilter(setShowform, refetch);

  return (
    <div>
      <form action={"javascript:void(0)"}>{renderTabContent()}</form>
    </div>
  );
}

export default Filter;

import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch } from "react-redux";

import { Button } from "@wac-ui-dashboard/wac_component_library";
import {
  getAllCountries,
  getAllStates,
  getAllRoles,
  getAllBranches,
} from "../../../../store/slices/Users/addNewUserSlice";
import useUserAddForm from "./useUserAddForm";

const BasicForm = ({ formik, setShowform, isEditForm, loading, isLoading}) => {

  const {
    user_data,
    user_type
  } = useUserAddForm();

  const dispatch = useDispatch();

  const [userRoleOptions, setUserRoleOptions] = useState([]);

  const [statesOptions, setStates] = useState([]);
  const [countriesOptions, setCountries] = useState([]);

  const [branchOptions, setBranches] = useState([]);

  useEffect(() => {
    dispatch(getAllStates())
      .unwrap()
      .then((result) => {
        setStates(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getAllCountries())
      .unwrap()
      .then((result) => {
        setCountries(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getAllRoles())
      .unwrap()
      .then((result) => {
        setUserRoleOptions(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getAllBranches())
      .unwrap()
      .then((result) => {
        setBranches(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user_type == 3) {
      let temp = userRoleOptions.find((item) => item.value == 2 )
      formik.setFieldValue("userRole", temp);
      
    }
  }, [userRoleOptions]);

  useEffect(() => {
    if (user_type == 3) {
        let tempBranch = branchOptions.find((item) => item.value == user_data )
        formik.setFieldValue("branch", tempBranch);
      
    }
  }, [branchOptions]);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="name" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Name *
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className={`pro-input lg ${
              formik.errors.name && formik.touched.name && "error"
            }`}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="error-text">{formik.errors.name}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="email" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Email *
          </label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Email Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={`pro-input lg ${
              formik.errors.email && formik.touched.email && "error"
            }`}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error-text">{formik.errors.email}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="username"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Username *
          </label>
          <input
            type="text"
            name="username"
            id="username"
            autoComplete="new-username"
            placeholder="Username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            className={`pro-input lg ${
              formik.errors.username && formik.touched.username && "error"
            }`}
          />
          {formik.touched.username && formik.errors.username && (
            <div className="error-text">{formik.errors.username}</div>
          )}
        </div>
      </div>
      {!formik.values.isEditForm && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="password"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Password *
            </label>
            <input
              type="password"
              name="password"
              id="password"
              autoComplete="new-password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={`pro-input lg ${
                formik.errors.password && formik.touched.password && "error"
              }`}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="error-text">{formik.errors.password}</div>
            )}
          </div>
        </div>
      )}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            User Role *
          </label>
          <Select
            name="userRole"
            options={userRoleOptions} // replace with your userRole options
            onChange={(selectedOption) => {
              formik.setFieldValue("userRole", selectedOption);
            }}
            onBlur={formik.handleBlur}
            value={formik?.values?.userRole}
            className={`pro-input lg ${
              formik.errors.userRole && formik.touched.userRole && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.userRole && formik.errors.userRole && (
            <div className="error-text">{formik.errors.userRole}</div>
          )}
        </div>
      </div>
      {formik?.values?.userRole?.value === 2 && (
        <>
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="country"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch *
            </label>
            <Select
              name="branch"
              options={branchOptions} // replace with your branch options
              onChange={(selectedOption) => {
                formik.setFieldValue("branch", selectedOption);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.branch}
              className={`pro-input lg ${
                formik.errors.branch && formik.touched.branch && "error"
              }`}
              classNamePrefix={`pro-input`}
            />
            {formik.touched.branch && formik.errors.branch && (
              <div className="error-text">{formik.errors.branch}</div>
            )}
          </div>
        </div>
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="purchase_invoice_code"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Purchase Invoice Code*
            </label>
            <input
              type="text"
              name="purchase_invoice_code"
              id="purchase_invoice_code"
              placeholder="Purchase Invoice Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.purchase_invoice_code}
              className={`pro-input lg ${
                formik.errors.purchase_invoice_code && formik.touched.purchase_invoice_code && "error"
              }`}
            />
            {formik.touched.purchase_invoice_code && formik.errors.purchase_invoice_code && (
              <div className="error-text">{formik.errors.purchase_invoice_code}</div>
            )}
          </div>
        </div>
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="surrender_invoice_code"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Surrender Invoice Code*
            </label>
            <input
              type="text"
              name="surrender_invoice_code"
              id="surrender_invoice_code"
              placeholder="Surrender Invoice Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.surrender_invoice_code}
              className={`pro-input lg ${
                formik.errors.surrender_invoice_code && formik.touched.surrender_invoice_code && "error"
              }`}
            />
            {formik.touched.surrender_invoice_code && formik.errors.surrender_invoice_code && (
              <div className="error-text">{formik.errors.surrender_invoice_code}</div>
            )}
          </div>
        </div>
        </>
        
        
      )}
      {(formik?.values?.userRole?.value === 2 || formik?.values?.userRole?.value === 3) && (
        <div>
          
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="gst_no"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              {formik?.values?.userRole?.value === 2 ? `GST Number*` : `GST Number`}
            </label>
            <input
              type="text"
              name="gst_no"
              id="gst_no"
              placeholder="Gst.No"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gst_no}
              className={`pro-input lg ${
                formik.errors.gst_no && formik.touched.gst_no && "error"
              }`}
            />
            {formik.touched.gst_no && formik.errors.gst_no && (
              <div className="error-text">{formik.errors.gst_no}</div>
            )}
          </div>
        </div>
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="license_no"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              {formik?.values?.userRole?.value === 2 ? `License Number*` : `License Number`}
            </label>
            <input
              type="Text"
              name="license_no"
              id="license_no"
              placeholder="License.No"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.license_no}
              className={`pro-input lg ${
                formik.errors.license_no && formik.touched.license_no && "error"
              }`}
            />
            {formik.touched.license_no && formik.errors.license_no && (
              <div className="error-text">{formik.errors.license_no}</div>
            )}
          </div>
        </div>
      </div>
      )}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Country *
          </label>
          <Select
            name="country"
            options={countriesOptions} // replace with your country options
            onChange={(selectedOption) =>
              formik.setFieldValue("country", selectedOption)
            }
            onBlur={formik.handleBlur}
            value={formik.values.country}
            className={`pro-input lg ${
              formik.errors.country && formik.touched.country && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.country && formik.errors.country && (
            <div className="error-text">{formik.errors.country}</div>
          )}
        </div>
      </div>
      {formik.values.country.value === 92 && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="state"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              State *
            </label>

            <Select
              name="state"
              options={statesOptions} // replace with your country options
              onChange={(selectedOption) =>
                formik.setFieldValue("state", selectedOption)
              }
              onBlur={formik.handleBlur}
              value={formik.values.state}
              className={`pro-input lg ${
                formik.errors.state && formik.touched.state && "error"
              }`}
              classNamePrefix={`pro-input`}
            />
            {formik.touched.state && formik.errors.state && (
              <div className="error-text">{formik.errors.state}</div>
            )}
          </div>
        </div>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Franchisee Address
        </label>
        <textarea
          id="franchisee_address"
          rows={4}
          name="franchisee_address"
          className={`pro-input lg ${
            formik.touched.franchisee_address &&
            formik.errors.franchisee_address &&
            " error"
          }`}
          {...formik.getFieldProps("franchisee_address")}
        ></textarea>
        {formik.touched.franchisee_address &&
          formik.errors.franchisee_address && (
            <span className="error-text">
              {formik.errors.franchisee_address}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Branch Address
        </label>
        <textarea
          id="branch_address"
          rows={4}
          name="branch_address"
          className={`pro-input lg ${
            formik.touched.branch_address &&
            formik.errors.branch_address &&
            " error"
          }`}
          {...formik.getFieldProps("branch_address")}
        ></textarea>
        {formik.touched.branch_address && formik.errors.branch_address && (
          <span className="error-text">{formik.errors.branch_address}</span>
        )}
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => {
            setShowform(false);
          }}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditForm
            ? loading
              ? "Please wait.."
              : "Update"
            : loading
            ? "Please wait.."
            : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

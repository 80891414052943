import React, { useEffect, useRef, useState } from "react";
import StippedTable from "../../Global/StippedTable";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Style from "./purchaseForm.module.scss";

import PurchasePrint from "../../AllPurchases/Purchases/print-page";
import { useFetchPurchaseData } from "./useFetchPurchaseData";
import { useConfirmPayment } from "./useConfirmPayment";
import usePrintPage from "./usePrint";

const ConfirmationScreen = () => {
  const { customersID, purchaseID } = useParams();

  const {
    purchaseData,
    tableDataArray,
    tableData4,
    tableData5,
    tableData6,
    tableData7,
  } = useFetchPurchaseData(purchaseID);

  const { isLoading, handleConfirmPayment, handleEdit } = useConfirmPayment(
    purchaseID,
    customersID
  );

  const { handlePrintClick, purchaseDataObj, printRef } =
    usePrintPage(purchaseData);

  if (!purchaseData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={`pro-px-4 ${Style.confirmation_preview_wrapper}`}>
        <div className="row gx-6 pro-pb-5">
          <div className="col-6">
            <h6 className="pro-ttl pro-mb-4">Customer Details</h6>

            <div className="pro-mb-4">
              <StippedTable data={tableData7} />
            </div>

            <h6 className="pro-ttl pro-mb-4">Transaction</h6>

            {tableDataArray.map((tableData, index) => (
              <div className="pro-mb-4" key={index}>
                <StippedTable data={tableData} />
              </div>
            ))}
          </div>

          <div className="col-6">
            <div className="pro-mb-4">
              <h6 className="pro-ttl pro-mb-4">CDF Details</h6>
              <StippedTable data={tableData4} />
            </div>
            <div className="pro-mb-4">
              <h6 className="pro-ttl pro-mb-4">Payment Details</h6>
              <StippedTable data={tableData5} />
            </div>
            <div className="pro-mb-4">
              <h6 className="pro-ttl pro-mb-4">Transaction Details</h6>
              <StippedTable data={tableData6} />
            </div>

            <PurchasePrint ref={printRef} {...purchaseDataObj}></PurchasePrint>
          </div>
        </div>
        <div
          className={`pro-py-5 pro-px-5 pro-d-flex  ${Style.footer_btn_wrapper}`}
        >
          <div className="pro-d-flex">
            <button className="pro-btn pro-btn-outline lg" onClick={handleEdit}>
              Edit
            </button>
            <button
              className="pro-btn pro-btn-outline lg"
              onClick={handlePrintClick}
            >
              Print
            </button>
            {/* <button className="pro-btn pro-btn-outline lg pro-ms-2">print</button> */}
          </div>
          <button
            className="pro-btn pro-btn-primary lg pro-px-6 pro-ms-auto"
            onClick={handleConfirmPayment}
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading ? "Loading..." : "Confirm payment"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationScreen;

import React from "react";
import Style from "./tableStructure.module.scss";
import Assets from "../../../assets/Assets";
import { getRevFormatedDate } from "../../../utils/functions/table";

const TableStructure = ({
  data,
  printRef,
  state,
  title,
  selectedBranch,
  showMonth,
}) => {
  const dateStringStart = getRevFormatedDate(state?.startDate);
  const dateStringEnd = getRevFormatedDate(state?.endDate);

  let dateStart = undefined;
  let dateEnd = undefined;

  if (dateStringStart) {
    const datePartsStart = dateStringStart?.split(" ");
    const formattedDateStart = datePartsStart?.[0];
    dateStart = formattedDateStart
    ? formattedDateStart.split("-").reverse().join("-")
    : null;
  }

  if (dateStringEnd) {
    const datePartsEnd = dateStringEnd?.split(" ");
    const formattedDateEnd = datePartsEnd?.[0];
    dateEnd = formattedDateEnd
    ? formattedDateEnd.split("-").reverse().join("-")
    : null;
  }

  function extractMonthFromDate(dateText) {
    // Create a new Date object by parsing the date text
    const dateObj = new Date(dateText);

    // Get the month from the Date object
    // Note: getMonth() returns the month index starting from 0 (January = 0, February = 1, and so on)
    const monthIndex = dateObj.getMonth();

    // Convert month index to month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[monthIndex];

    return monthName;
  }

  return (
    <div
      className={`${Style.table_root} pro-mt-4 d-none`}
      style={{ display: "none" }}
    >
      <table ref={printRef}>
        <tbody>
          <tr>
            <td
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <table
                align="center"
                border="0"
                cellspacing="0"
                role="presentation"
                cellpadding="0"
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  borderBottom: "5px solid #003a63",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "76px",
                        height: "30px",
                        paddingBottom: "12px",
                      }}
                    >
                      <a
                        href
                        target="_blank"
                        style={{ width: "76px", height: "30px" }}
                      >
                        <img
                          src={Assets.HEADERLOGO}
                          alt="luluforex-logo"
                          width={76}
                          height={30}
                          style={{
                            display: "inline",
                            textAlign: "center",
                            width: "76px",
                            height: "30px",
                          }}
                        />
                      </a>
                    </td>
                    <td
                      style={{
                        fontSize: "17px",
                        fontWeight: 700,
                        letterSpacing: "0.34px",
                        textAlign: "right",
                        paddingBottom: "12px",
                        color: "#003A63",
                      }}
                    >
                      {title}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingTop: "27px",
                fontSize: "12px",
                fontWeight: 400,
                color: "#000",
                paddingBottom: "14px",
                paddingLeft: "30px",
                paddingRight: "30px",
                textAlign: "center",
              }}
            >
              Daily Summary and Balance Book (Foreign Currency Notes / Coins) -
              {showMonth === true
                ? `Month : ${extractMonthFromDate(dateStart)}`
                : `Date: ${dateStart ?? ""} to ${dateEnd ?? ""} `}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#000",
                paddingBottom: "14px",
                paddingLeft: "30px",
                paddingRight: "30px",
                textAlign: "center",
              }}
            >
              Name and address of Restricted Money Changer : {selectedBranch}
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingBottom: "18px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <table className="pro-table">
                <tbody>
                  {data?.labels?.map((label, index) => (
                    <tr key={index}>
                      {index === 0 ? <th>{label}</th> : <td>{label}</td>}

                      {data.rows.map((row, rowIndex) => {
                        if (index === 0) {
                          return <th>{row[index]}</th>;
                        } else {
                          return <td key={rowIndex}>{row[index]}</td>;
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                padding: "36px 30px 0 40px",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "33.3%",
                      }}
                    >
                      Encls:
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "33.3%",
                      }}
                    >
                      Stamp:
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "20%",
                      }}
                    >
                      (Signature of Authorised Official)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingTop: "57px",
                        width: "33.3%",
                      }}
                    >
                      Place:
                    </td>
                    <td />
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingTop: "57px",
                        width: "20%",
                      }}
                    >
                      Name :
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: "57px", width: "33.3%" }} />
                    <td style={{ paddingTop: "57px", width: "33.3%" }} />
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        paddingTop: "57px",
                        width: "20%",
                      }}
                    >
                      Designation :
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableStructure;

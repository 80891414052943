import React from "react";
import Style from "./actionBtns.module.scss";

const ActionBtns = ({
  SubmitAction,
  cancelAction,
  isRight,
  submitText,
  cancelText,
}) => {
  return (
    <div className={Style.root}>
      <div className={`col-12 pro-d-flex ${isRight ? `pro-justify-end` : ""}`}>
        {cancelText && (
          <button
            className="pro-btn-link lg pro-px-5"
            onClick={() => cancelAction()}
          >
            <span>{cancelText}</span>
          </button>
        )}
        {submitText && (
          <button
            className="pro-btn pro-btn-primary lg min"
            onClick={() => SubmitAction()}
          >
            <span>{submitText}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ActionBtns;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./surrenderDetail.module.scss";
import { useSelector } from "react-redux";
import { fetchSurrenderById } from "../../../store/slices/surrender/surrenderDetailsPageSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomerProfileShimmer from "../Shimmers/CustomerProfileShimmer";

const SurrenderDetail = () => {
	const surrenderDetailsPage = useSelector((state) => state.surrenderDetailsPage);
	const date = new Date(surrenderDetailsPage?.selectedItemsDetails?.created_at);

	let formatter = date.toLocaleDateString("en-GB", {
		year: "numeric",
		month: "long",
		day: "numeric"
	});

	return surrenderDetailsPage.surrenderDetailsLoading === "loading" ? (

		<CustomerProfileShimmer />

	) : (
		<div className={`${styles.root} pro-d-flex pro-flex-column`}>
			<div className={`${styles.profile_top} pro-py-5`}>
				<h4 className="pro-ttl pro-mb-1">
					Surrender Details
				</h4>
				<p className="pro-mb-0">
					<span>{surrenderDetailsPage?.selectedItemsDetails?.surrender_no}</span>
					{surrenderDetailsPage?.selectedItemsDetails?.status ===
						"Confirmed" ? (
						<span
							className={`pro-badge badge-success ${styles.success} pro-ms-3`}
						>
							{surrenderDetailsPage?.selectedItemsDetails?.status}
						</span>
					) : surrenderDetailsPage?.selectedItemsDetails?.status ===
						"Cancellation Requested" ? (
						<span
							className={`pro-badge ${styles.blue} pro-ms-3`}
						>
							{surrenderDetailsPage?.selectedItemsDetails?.status}
						</span>
					) : (
						<span className={`pro-badge badge-danger pro-ms-3 `}>
							{surrenderDetailsPage?.selectedItemsDetails?.status}
						</span>
					)}
				</p>
			</div>
			<div className={`${styles.table_wrap} pro-py-5`}>
				<h6 className="pro-mb-4 pro-ttl">Details</h6>
				<table className={styles.table}>
					<tbody>
						<tr>
							<td>
								<p className="pro-font-sm pro-fw-medium">Created At</p>
							</td>
							<td>
								<p>{formatter}</p>
							</td>
						</tr>
						{localStorage.getItem('user_role') === '1' || '3' ? (
							<tr>
								<td>
									<p className="pro-font-sm pro-fw-medium">Surrendered By</p>
								</td>
								<td>
									<p>{surrenderDetailsPage?.selectedItemsDetails?.franchise?.name}</p>
								</td>
							</tr>
						) : (null)}

{surrenderDetailsPage?.selectedItemsDetails?.cancellation_remarks?.[0] ? (
							<tr>
								<td>
									<p className="pro-font-sm pro-fw-medium">Remark</p>
								</td>
								<td>
									<p>{surrenderDetailsPage?.selectedItemsDetails?.cancellation_remarks[0].remark}</p>
								</td>
							</tr>
						) : (null)}

						<tr>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default SurrenderDetail;

import { useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import moment from "moment";

const usePrintPage = (purchaseData) => {
  const printRef = useRef(null);

  const {
    franchise,
    payment_mode,
    branchContact,
    customer_name,
    passportNumber,
    customerAddress,
    currency_purchase_data_without_trash,
    cgst,
    sgst,
    igst,
    ugst,
    totalIncludingTax,
    totalInWords,
    customer,
    created_at,
    payout_amount,
    invoice_no,
  } = purchaseData ?? {};

  const singleDigits = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];

  const teenDigits = [
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tensDigits = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const bigNumbers = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
  ];

  function capitalizeAllWords(text) {
    return text.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  function convertNumberToWords(number) {
    if (number === "") {
      return "";
    }

    if (number === 0) {
      return "zero";
    }

    const words = [];

    let numStr = number?.toString();

    while (numStr?.length % 3 !== 0) {
      numStr = "0" + numStr;
    }

    for (let i = 0; i < numStr.length; i += 3) {
      const chunk = parseInt(numStr.substr(i, 3));

      if (chunk !== 0) {
        const hundreds = Math.floor(chunk / 100);
        const tens = Math.floor((chunk % 100) / 10);
        const ones = chunk % 10;
        let chunkWords = "";

        if (hundreds > 0) {
          chunkWords += singleDigits[hundreds] + " hundred ";
        }

        if (tens === 1 && ones > 0) {
          chunkWords += teenDigits[ones - 1] + " ";
        } else {
          if (tens > 0) {
            chunkWords += tensDigits[tens] + " ";
          }
          if (ones > 0) {
            chunkWords += singleDigits[ones] + " ";
          }
        }

        const bigNumberIndex = numStr.length / 3 - i / 3 - 1;
        if (bigNumberIndex >= 1 && chunkWords.trim() !== "") {
          chunkWords += bigNumbers[bigNumberIndex] + " ";
        }

        words.push(chunkWords.trim());
      }
    }

    return words.join(" ").trim();
  }

  const beautifyDate = (uglyDate) => {
    if (uglyDate != "" && uglyDate !== undefined) {
      return new Date(uglyDate)?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  const currencyData =
  currency_purchase_data_without_trash?.map((currencyItem) => ({
      currencyName: currencyItem?.currency?.name || "",
      type: currencyItem?.purchase_mode_name,
      amount: currencyItem?.currency_volume || "0.00",
      ratePer: currencyItem?.rate || "0.00 / 1",
      rsEquivalent: currencyItem?.inr_volume || "0.00",
    })) || [];

  // Assigning the extracted values to purchaseData
  const purchaseDataObj = {
    branchName: `LULU FOREX -  ${franchise?.name || ""}`,
    rbiLicenseNumber: `RBI LIC.NO. :${franchise?.rbi_license_number || ""}`,
    gstInNumber: `GST IN NO. :${franchise?.gstin_number || ""}`,
    cin: `Cin :${franchise?.cin || ""}`,
    branchAddress: franchise?.franchisee_address || franchise?.branch_address,
    // branchContact: branchContact || "+914844187757",
    validityText:
      "(Valid only for three months from the date of purchase of foreign currency)",
    serialNumber: invoice_no,
    date: beautifyDate(created_at),
    customerNumber: customer?.customer_code || "",
    customerName: customer?.full_name || "",
    passportNumber: passportNumber || "",
    nationality: "INDIA",
    behalfOf: "INDIVIDUAL CLIENTS",
    customerAddress: customer?.address,
    currencyData: currencyData,
    exchangeTransactionFee: "- 0.00",
    cgst: cgst || "- 0.00",
    sgst: sgst || "- 0.00",
    igst: igst || "- 0.00",
    ugst: ugst || `0.00`,
    roundOff: "- 0.00",
    totalIncludingTax: totalIncludingTax || "- 0.00",
    total: payout_amount,
    totalInWords: capitalizeAllWords(convertNumberToWords(payout_amount)),
    paymentDetails: `Paid ${payout_amount} By ${
      payment_mode?.payment_mode || ""
    }`,
    undertakingText:
      "I CONFIRM THAT I HAVE NOT ENCASED FOREIGN CN/TC MORE THAN 1000 USD OR EQUIVALENT UPTO DATE IN THIS MONTH IN CASH",
    authorizedSignatory: `For LULU FOREX - ${franchise?.name || ""}`,
    note: "This certificate should be carefully preserved to facilitate reconversion of the rupee balance, if any, into foreign currency at the time of departure from India and/or of payment of the passage fare/freight cost in rupees, if necessary.",
    gstAndCinDetails: `GST IN NO. :${franchise?.gstin_number || ""} |Cin :${
      franchise?.cin || ""
    }`,
  };

  const handlePrintClick = (data) => {
    const element = printRef.current;

    let Tablelength = 100;

    html2pdf(element, {
      margin: [2, 10],
      filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: {
        precision: 32,
        unit: "mm",
        format: "ledger",
        orientation: "landscape",
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  return { currencyData, handlePrintClick, purchaseDataObj, printRef };
};

export default usePrintPage;

import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useForgotPassword from "./useForgotPassword";

const ForgotPassword = () => {
  const { emailRef } = useOutletContext();

  const {
    otp,
    errors,
    otpStatus,
    email,
    handleSubmit,
    handleChange,
    handleBlur,
    handleLoginClick,
  } = useForgotPassword(emailRef);
  
  return (
    <Auth
      title={"Forgot Password"}
      buttonText={"Submit"}
      handleClick={handleSubmit}
      actionText={"Back to login"}
      handleActionClick={handleLoginClick}
      loading={otpStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Enter OTP sent to your email address
        </label>
        <input
          value={otp}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          className={`pro-input lg ${errors && "error"}`}
        />
        {errors && <span className="error-text">{errors}</span>}
      </div>
    </Auth>
  );
};

export default ForgotPassword;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchPurchaseById } from "../../../../store/slices/Purchases/purchasesSlice";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
import moment from "moment";

const usePurchaseDetailTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { purchaseID, customersID } = useParams();
  const printRef = useRef(null);

  const selectedItemsDetails = useSelector((state) => state.purchasesList);

  useEffect(() => {
    dispatch(fetchPurchaseById(purchaseID));
  }, []);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      currency: (feild, data) => <p className="pro-mb-0">{data[feild].name}</p>,
      inr_volume: (feild, data) => <p className="pro-mb-0">₹{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleLinkClick = (item) => {
    if (item === "Purchase") {
      if (customersID == undefined && purchaseID != undefined) {
        navigate(`/purchases`);
      }

      if (customersID != undefined && purchaseID != undefined) {
        navigate(`/customers/purchase/${customersID}`);
      }
    }
  };

  const beautifyDate = (uglyDate) => {
    if (uglyDate != "" && uglyDate !== undefined) {
      return new Date(uglyDate)?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  const handlePrintClick = (data) => {
    
    const element = printRef.current;

    html2pdf(element, {
      margin: [2, 10],
      filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: {
        precision: 32,
        unit: "mm",
        format: "ledger",
        orientation: "landscape",
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  return {
    getRow,
    handleLinkClick,
    selectedItemsDetails,
    printRef,
    handlePrintClick,
    beautifyDate,
  };
};

export default usePurchaseDetailTable;

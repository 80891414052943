import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  calculateAmount,
  resetValues,
  fetchActiveCurrency,
  fetchActivePaymentModes,
  createPurchaseByCustomerID,
  updatePurchaseByCustomerID,
} from "../../../store/slices/purchase/purchaseSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchPurchaseById } from "../../../store/slices/customers/customerDetailsPageSlice";

const usePurchaseForm = () => {
  const dispatch = useDispatch();
  let { customersID, purchaseID } = useParams();

  const navigate = useNavigate();

  const { calculatedAmount } = useSelector((state) => state.purchase);
  const { selectedItemsDetails,customerDetails } = useSelector(
    (state) => state.customerDetailsPage
  );

 

  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState([]);

  const [paymentModeOptions, setPaymentModeOptions] = useState([]);
  const [isCDFrequired, setIsCDFrequired] = useState(false);
  const [total, setTotal] = useState(0);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  useEffect(() => {
    if (
      customersID != undefined &&
      purchaseID != undefined &&
      selectedItemsDetails == ""
    ) {
      dispatch(fetchPurchaseById(purchaseID))
        .unwrap()
        .then((result) => {});
    }
  }, [customersID, purchaseID, selectedItemsDetails]);



  // Fetch currency options from the API
  useEffect(() => {
    dispatch(fetchActiveCurrency()).then((result) => {
      const rawArray = result.payload.data;

      if (rawArray.length > 0) {
        const convertedData = rawArray.map((item) => {
          return {
            value: item.id,
            label: `${item.name} (${item.code})`,
          };
        });

        setCurrencyOptions(convertedData);
      }
    });

    dispatch(fetchActivePaymentModes()).then((result) => {
      const rawArray = result.payload.data;

      if (rawArray.length > 0) {
        const convertedData = rawArray.map((item) => {
          return {
            value: item.id,
            label: item.payment_mode,
          };
        });

        setPaymentModeOptions(convertedData);
      }
    });
  }, []);

  const cdfDetailsValidation = {
    cdfNumber: Yup.string().required("CDF Number is required"),
    amountDeclared: Yup.string().required("Amount Declared is required"),
    cdf_date: Yup.string().required("Date of Birth is required"),
    customsOfficePlace: Yup.string().required(
      "Customs Office/Place is required"
    ),
  };

  const validationSchema = Yup.object().shape({
    ...{
      paymentMode: Yup.object().required("Payment Mode is required"),

      transactions: Yup.array()
        .of(
          Yup.object().shape({
            currencyName: Yup.object().required("Currency Name is required"),
            paymentType: Yup.string().required("Payment Type is required"),
            currencyVolume: Yup.string().required(
              "Currency Volume is required"
            ),
            rate: Yup.string().required("Rate is required"),
          })
        )
        .required("At least one transaction is required"),
    },
    ...(isCDFrequired ? cdfDetailsValidation : {}),
  });

  let initialValues;

  if (Object.keys(selectedItemsDetails).length === 0) {
    // Create mode
    initialValues = {
      cdfNumber: "",
      amountDeclared: "",
      cdf_date: "",
      customsOfficePlace: "",
      paymentMode: "",
      payoutAmount: "",
      transactions: [
        {
          currencyName: "",
          paymentType: "",
          currencyVolume: "",
          rate: "",
          inrVolume: "",
        },
      ],
    };
  } else {
    // Edit mode
    const transactions = selectedItemsDetails.currency_purchase_data_without_trash.map(
      (transaction) => ({
        currencyName: {
          label: `${transaction?.currency?.name} (${transaction?.currency?.code})`,
          value: transaction?.currency?.id,
        },
        paymentType: transaction.purchase_mode,
        currencyVolume: transaction.currency_volume.toString(),
        rate: transaction.rate.toString(),
        inrVolume: transaction.inr_volume.toString(),
      })
    );

    initialValues = {
      cdfNumber: selectedItemsDetails.cdf_number,
      amountDeclared: selectedItemsDetails.amount_int_cdf,
      cdf_date:
        selectedItemsDetails.cdf_date != null
          ? selectedItemsDetails.cdf_date.replace(" 00:00:00", "")
          : "",
      customsOfficePlace: selectedItemsDetails.customer_office_place,
      paymentMode: {
        label: selectedItemsDetails?.payment_mode?.payment_mode,
        value: selectedItemsDetails?.payment_mode?.id,
      },
      payoutAmount: selectedItemsDetails.payout_amount,
      transactions,
    };
  }

  useEffect(() => {
    if (isCDFrequired === true) {
      formik.setFieldTouched("cdfNumber", true);
      formik.setFieldTouched("amountDeclared", true);
      formik.setFieldTouched("cdf_date", true);
      formik.setFieldTouched("customsOfficePlace", true);
    }
  }, [isCDFrequired]);

  const addDefaultTimeIfNotPresent = (dateTimeString) => {
    if (dateTimeString == "") {
      return "";
    }
    var defaultTime = "00:00:00";
    // Check if the date string contains a timestamp
    if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(dateTimeString)) {
      return dateTimeString; // Date string already has a timestamp, return as is
    } else {
      // Append the default time to the date string
      return dateTimeString + " " + defaultTime;
    }
  };

  // Use `initialValues` accordingly for edit or create functionality

  const handleSubmit = (values) => {
    setIsLoading(true);

    const formData = {
      customer_id: customersID,
      cdf_status: "1",
      cdf_number: values.cdfNumber,
      amount_int_cdf: values.amountDeclared,
      cdf_date: addDefaultTimeIfNotPresent(values.cdf_date),
      customer_office_place: values.customsOfficePlace,
      currency: values.transactions.map((item) => {
        return {
          currency_id: item.currencyName.value,
          currency_type: item.paymentType,
          currency_volume: item.currencyVolume,
          rate: item.rate,
          inr_volume: item.inrVolume,
        };
      }),
      transaction_amount: total,
      cgst: calculatedAmount?.CGST === undefined ? 0 : calculatedAmount?.CGST,
      sgst: calculatedAmount?.SGST === undefined ? 0 : calculatedAmount?.SGST,
      igst: calculatedAmount?.IGST === undefined ? 0 : calculatedAmount?.IGST,
      ugst: calculatedAmount?.UGST === undefined ? 0 : calculatedAmount?.UGST,
      amount_to_customer: calculatedAmount?.amount_to_customer,
      payment_mode_id: values.paymentMode.value,
      payout_amount: calculatedAmount?.amount_to_customer,
      is_confirmed: "0",
    };

    let apiCallInstance, message;
    if (customersID != undefined && purchaseID != undefined) {
      apiCallInstance = dispatch(
        updatePurchaseByCustomerID({ purchaseID: purchaseID, data: formData })
      );

      message = "Purchase has been updated.";
    } else {
      apiCallInstance = dispatch(createPurchaseByCustomerID(formData));
      message = "New purchase has been done.";
    }

    apiCallInstance
      .unwrap()
      .then((result) => {
        if (result.success) {
          setIsLoading(false);
          setIsCDFrequired(false);
          toast.success(message);
          navigate(
            `/customers/purchase/${customersID}/confirm-payment/${result.data.id}`
          );
          formik.resetForm();
        } else {
          toast.error("Sorry something went wrong.");
        }
      })
      .catch((err) => {
        const error = [
          "Currency Declaration Form need to be filled",
          "Payment mode should not be Cash",
        ];
        var position = error.indexOf(err.message);

        if (position === 0) {
          setIsLoading(false);
          setIsCDFrequired(true);
          toast.error(error[position]);
        } else if (position === 1) {
          setIsLoading(false);
          toast.error(error[position]);
        } else {
          toast.error(err.message);
          setIsLoading(false);
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const calculateTotalInrVolume = () => {
    // var hello = Number(formik?.values?.transaction);
    const totalInrVolume = formik.values.transactions.reduce(
      (sum, transaction) => {
        return sum + Number(transaction.inrVolume);
      },
      0
    );

    return totalInrVolume;
  };
  const calculateTotalTransactionVolume = () => {
    let totalInrVolume = 0;

    formik.values.transactions.forEach((transaction) => {
      totalInrVolume += parseFloat(transaction.inrVolume) || 0;
    });

    setTotal(totalInrVolume.toFixed(2));
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const totalInrVolume = calculateTotalInrVolume();

      const params = {
        customer_id: customersID, // Replace with the actual customerId value
        transaction_amount: totalInrVolume,
      };
      if (totalInrVolume == 0) {
        dispatch(resetValues());
      } else {
        dispatch(calculateAmount(params));
      }
    }, 500); // Set the debounce delay here (e.g., 500 milliseconds)

    return () => clearTimeout(debounceTimer);
  }, [formik.values.transactions]);
  useEffect(() => {
    calculateTotalTransactionVolume();
  }, [formik]);

  return {
    formik,
    total,
    currencyOptions,
    paymentModeOptions,
    isLoading,
    customerDetails,
    setIsLoading,
    isCDFrequired,
  };
};

export default usePurchaseForm;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetRlm3ReportDataQuery,
  useUpdateRlm3ReportDataMutation,
} from "../../../store/queries/reportsRLM3";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
  getFranchiseById,
  getListData
} from "../../../store/slices/Reports/reportsRLM3Slice";
import { addDays } from "date-fns";
import { getRevFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { getAllFranchise } from "../../../store/slices/customers/registerCustomersSlice";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { toast } from "react-toastify";

const useRLM3 = () => {
  const dispatch = useDispatch();

  const rlm3Report = useSelector((state) => state.rlm3Report);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [franchiseiesOptions, setFranchise] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [namePdf, setName] = useState("");
  const printRef = useRef(null);
  const [franchiseOptions, setFranchiseOption] = useState([]);
  let user_type = localStorage.getItem("user_role");
  let user_data = localStorage.getItem("login_data");
  let branch_id = localStorage.getItem("branch_data");

  const [date, setDate] = useState({
    startDate: rlm3Report.startDate,
    endDate: rlm3Report.endDate,
    key: "selection",
  });

  const getCurrentTimeIn24HourFormat = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = "00";
    const minutes = "00";
    const seconds = "00";
    return `${year}-${month}-${day} ${getCurrentTimeIn24HourFormat(date)}`;
  };
  
  const getMonthInText = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = date.getMonth();
    return months[monthIndex];
  };

  useEffect(() => {
    
    dispatch(getAllFranchise())
      .unwrap()
      .then((result) => {
        
        if (user_type == 3) {
          setFranchise(
            result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          );
        } 
        else if(user_type == 2) {
          setSeleOption(branch_id);
        }
        else {
          setFranchise([
            ...[
              {
                label: "All",
                value: "",
              },
            ],
            ...result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]);
          dispatch(
            updateConfig((state) => {
              state.selectedFranchise = {
                label: "All",
                value: "",
              };
            })
          );
        }
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
        if (user_type == 3) {
          if(user_data) {
            let selectedBranchOPtion = franchiseiesOptions.find((item) => (item.value == user_data));
            if(selectedBranchOPtion) {
              setName(selectedBranchOPtion.label);
              dispatch(
                updateConfig((state) => {
                  state.selectedFranchise = selectedBranchOPtion
                })
              );
              setSeleOption(user_data);
            }
          }
        } 
  }, [franchiseiesOptions]);

  useEffect(() => {    
    if(user_type == 2) {
      if(user_data) {
        let selectedFranchise = franchiseOptions.find((item) => (item.value == user_data));
        setName(selectedFranchise?.label);
        dispatch(
          updateConfig((state) => {
            state.selectedFranchiseOption = selectedFranchise
          })
          )
      }
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [franchiseOptions]);


  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (rlm3Report.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateRlm3ReportDataMutation();
  const [skip, setSkip] = useState(true);

  const {
    data: rlm3ReportList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetRlm3ReportDataQuery({
    page_size: rlm3Report.currentPageSize,
    page: rlm3Report.currentPage,
    sort_by: rlm3Report.sortBy,
    sort_order: rlm3Report.sortOrder,
    start: rlm3Report.startDate,
    end: rlm3Report.endDate,
    start_date: getRevFormatedDate(rlm3Report.startDate) + " 00:00:00",
    end_date:
      getRevFormatedDate(rlm3Report.endDate) +
      ` 23:59:59`,

    search: rlm3Report.search,
    branch_id: rlm3Report.selectedFranchise?.value,
    franchise_id: rlm3Report?.selectedFranchiseOption
  }, {skip: skip});



	useEffect(() => {
		const selectedFranchise = rlm3Report?.selectedFranchiseOption;
		const selectedBranch = rlm3Report.selectedFranchise;

		if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type == 2) {
			setSkip(false);
		}
		else if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type != 2) {
			setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm3Report?.selectedFranchiseOption]);

	useEffect(() => {
		const selectedBranch = rlm3Report.selectedFranchise
		
		if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value && (user_type == 3 || user_type != 2)) {
			setSkip(false);
		}
		else if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value == "" && (user_type != 3 || user_type != 2)) {
			setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm3Report.selectedFranchise]);
  

  const handleActionSetFranchiseies = (franchise) => {
    // setSeleOption(franchise);
    setName(franchise?.label)
    dispatch(
      updateConfig((state) => {
        state.selectedFranchise = franchise;
      })
    );
    if(franchise?.value) {
      setSeleOption(franchise?.value);
    }
    else {
      setFranchiseOption([]);
      dispatch(
        updateConfig((state) => {
          state.selectedFranchiseOption = "";
        })
      );
    }
  };

  const hasCreatePermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.length ?? permission?.[0]?.can_create;
  }, [rlm3ReportList]);

  const hasViewPermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.length ?? permission?.[0]?.can_view;
  }, [rlm3ReportList]);

  const hasEditPermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.length ?? permission?.[0]?.can_edit;
  }, [rlm3ReportList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const convertDateFormat = (inputDateString) => {
    // Create a new Date object with the input date string
    var inputDate = new Date(inputDateString);

    // Extract the year, month, and day from the Date object
    var year = inputDate.getFullYear();
    var month = String(inputDate.getMonth() + 1).padStart(2, "0");
    var day = String(inputDate.getDate()).padStart(2, "0");

    // Create the desired output date string
    var outputDateString = year + "-" + month + "-" + day + " 00:00:00";

    return outputDateString;
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = convertDateFormat(range.startDate);
        state.endDate = convertDateFormat(range.endDate);
      })
    );
    setSelectedMonth(getMonthInText(range.startDate));
  };

  const handlePrintClick = (data) => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [15, 0, 10, 0],
      filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 800,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ testimonial_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            // refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          handleUnknownError(setShowDeleteConfirm);
        });
    } else {
      setShowDeleteConfirm(false);
      handleUnknownError(setShowDeleteConfirm);
    }
  };
  const handleSort = (label) => {
    if (rlm3Report.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rlm3Report.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleUnknownError = () => {
    alert("Sorry something went wrong!");
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      rupee_equivalent: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.toFixed(2) ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };


  const setSeleOption = (id) => {
    dispatch(getFranchiseById(id))
    .unwrap()
    .then((result) => {
      if (result?.data?.message === "Success") {
        if(user_type == 2) {
          let temp = result?.data?.data?.filter((item) => item.id == user_data)
          setFranchiseOption(
            temp?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
        else {
          setFranchiseOption(
            result?.data?.data?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
      } else {
        toast.error(result?.data?.message);
      }
      
    });
  }

  const handleActionSetFranchiseById = (data) => {
    setName(data?.label)
    dispatch(
      updateConfig((state) => {
        state.selectedFranchiseOption = data;
      })
    );
  };
  
  return {
    rlm3ReportList,
    rlm3Report,
    currentPage: rlm3Report.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handleDateRangeChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    // refetch,
    handleDelete,
    showform,
    date,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    handleActionSetFranchiseies,
    franchiseiesOptions,
    printRef,
    handlePrintClick,
    selectedMonth,
    franchiseOptions,
    handleActionSetFranchiseById,
    user_type,
    namePdf
  };
};

export default useRLM3;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setFilterValues, resetExtraFilters } from "../../../../store/slices/Users/adminUsersSlice";

const useUserFilter = (setShowform) => {
  
  const dispatch = useDispatch();

  const { userRole, startDate, endDate } = useSelector((state) => state.users);

  const validationObject = {
    userRole: Yup.object().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  };

  const validationSchema = Yup.object().shape(validationObject);

  const initialValues = {
    userRole: userRole,
    startDate: startDate,
    endDate: endDate,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        userRole,
        startDate,
        endDate,
      } = values;
      if (
        userRole === "" &&
        startDate === "" &&
        endDate === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        dispatch(setFilterValues(values));
      }
    },
  });

  const handleResetFilter = () => {
    // dispatch(setFilterValues(false));
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  const renderTabContent = () => {
    return (
      <BasicForm
        formik={formik}
        handleResetFilter={handleResetFilter}
      />
    );
  };

  // const handleResetExtraFilters = () => {
  //   dispatch(resetExtraFilters());
  //   formik.resetForm();
  //   setShowform(false);
  // };

  return {
    formik,
    renderTabContent,
    handleResetFilter
  };
};

export default useUserFilter;

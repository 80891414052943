import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  activeNationalities,
  getVisaTypes,
} from "../../../../store/slices/customers/registerCustomersSlice";
import { useDispatch } from "react-redux";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import countryData from "../../../../assets/data/countryCode";
const BasicForm = ({ formik, setActiveTab, tabs, setShowform, }) => {
  const dispatch = useDispatch();

  const [nationalitiesList, setNationalitiesList] = useState([]);
  const [visaType, setVisaType] = useState([]);
  const [CountryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    dispatch(activeNationalities())
      .unwrap()
      .then((result) => {
        setNationalitiesList(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getVisaTypes())
      .unwrap()
      .then((result) => {
        setVisaType(
          result.data.data?.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    setCountryCodes(
      countryData.map((item) => ({
        label: `${item.label}`,
        value: item.value,
      }))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            First Name *
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.firstName && formik.touched.firstName && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className="error-text">{formik.errors.firstName}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Last Name *
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.lastName && formik.touched.lastName && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="error-text">{formik.errors.lastName}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <div className="input-country-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile Number *
            </label>
            <div className="input-main-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  name="dialCode"
                  options={CountryCodes} // replace with your dialCode options
                  onChange={(selectedOption) =>
                    formik.setFieldValue("dialCode", selectedOption)
                  }
                  className={` ${
                    formik.errors.dialCode && formik.touched.dialCode && "error"
                  }
                multi-select country-select`}
                  classNamePrefix={`pro-input`}
                  onBlur={formik.handleBlur}
                  value={formik.values.dialCode}
                />
              </div>
              <input
                pattern="[0-9]{10}"
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
                onChange={(event) => {
                  // Remove any non-digit characters from the input
                  const numericValue = event.target.value.replace(/\D/g, "");
                  // Update the formik value with the numeric value
                  formik.setFieldValue("mobileNumber", numericValue);
                }}
                className={`pro-input lg ${
                  formik.errors.mobileNumber &&
                  formik.touched.mobileNumber &&
                  "error"
                }`}
                onBlur={formik.handleBlur}
                value={formik.values.mobileNumber}
              />
            </div>
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className="error-text">{formik.errors.mobileNumber}</div>
            )}
            {formik.errors.mobileNumber === undefined &&
              formik.touched.dialCode &&
              formik.errors.dialCode && (
                <div className="error-text">{formik.errors.dialCode}</div>
              )}
          </div>
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Email 
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.email && formik.touched.email && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error-text">{formik.errors.email}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Date of Birth 
          </label>
          <input
            type="date"
            name="dateOfBirth"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.dateOfBirth && formik.touched.dateOfBirth && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
            max={new Date().toISOString().split("T")[0]} // Set max date to today
          />
          {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
            <div className="error-text">{formik.errors.dateOfBirth}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Nationality *
          </label>
          <Select
            name="nationality"
            options={nationalitiesList} // replace with your nationality options
            onChange={(selectedOption) =>
              formik.setFieldValue("nationality", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.nationality && formik.touched.nationality && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.nationality}
          />
          {formik.touched.nationality && formik.errors.nationality && (
            <div className="error-text">{formik.errors.nationality}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Visa Type *
          </label>
          <Select
            name="visaType"
            options={visaType} // replace with your visaType options
            onChange={(selectedOption) =>
              formik.setFieldValue("visaType", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.visaType && formik.touched.visaType && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.visaType}
          />
          {formik.touched.visaType && formik.errors.visaType && (
            <div className="error-text">{formik.errors.visaType}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button type="button" className={"pro-btn-link lg pro-px-3"} onClick={() => setShowform(false)}>
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={
          //   formik?.errors?.firstName != undefined ||
          //   formik?.errors?.lastName != undefined ||
          //   formik?.errors?.mobileNumber != undefined ||
          //   formik?.errors?.email != undefined ||
          //   formik?.errors?.dateOfBirth != undefined ||
          //   formik?.errors?.nationality != undefined
          // }
          onClick={() => {
            setActiveTab(tabs[1]);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

// customHooks/useConfirmPayment.js
import { useState } from "react";
import { useDispatch } from "react-redux";
import { confirmPayment } from "../../../store/slices/customers/customerDetailsPageSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useConfirmPayment = (purchaseID, customersID) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/customers/purchase/${customersID}/edit-purchase/${purchaseID}`);
  };

  const handleConfirmPayment = () => {
    setIsLoading(true);
    const paymentData = {
      is_confirmed: "1",
    };

    dispatch(confirmPayment({ id: purchaseID, paymentData }))
      .unwrap()
      .then((result) => {
        if (result.data.success === true) {
          toast.success("Payment successful.");
          navigate(`/customers/purchase/${customersID}/?success=true`);
        }
      })
      .catch((err) => {
        toast.error("Sorry, something went wrong!");
        navigate(`/`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, handleConfirmPayment, handleEdit };
};

import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useCustomers = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Customers",
      link: "/",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/customers", true),
    },
    // {
    //   title: "Transactions",
    //   link: "/customers/transactions",
    //   iconAsset: "DrawerIcon3",
    //   icon: (
    //     <span className="material-symbols-outlined x4">workspace_premium</span>
    //   ),
    //   active: checkIfActiveRoute("/customers/transactions"),
    // },
  ];
  // const drawerMenuPermission = useMemo(() => {
  //   let menus = globalState.dashboard_permissions?.flatMap?.(
  //     (menu) => menu.menu_name
  //   );

  //   menus = ["Customers", "Transactions"];

  //   return drawerMenu
  //     .filter((menu) => menus?.includes?.(menu.title))
  //     .map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);

  return {
    drawerMenu
  };
};

export default useCustomers;

import { useSelector } from "react-redux";
import { useMemo } from "react";
import Customers from "../components/Customers";
import Surrender from "../components/Surrender";
import AllPurchases from "../components/AllPurchases";
import Reports from "../components/Reports";
import Leads from "../components/Leads";
import Admin from "../components/Admin";
import Permissions from "../components/Permissions";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);


  const UsersComponents = [
    {
      component: <Customers />,
      permission: "Customers",
      path: "customers",
    },
    {
      component: <Surrender />,
      permission: "Surrenders",
      path: "surrender",
    },
    {
      component: <AllPurchases />,
      permission: "Purchase",
      path: "purchases",
    },
    {
      component: <Reports />,
      permission: "Report",
      path: "reports",
    },
    {
      component: <Leads />,
      permission: "Leads",
      path: "leads",
    },
    {
      component: <Permissions />,
      permission: "User Roles",
      path: "permissions",
    },
    {
      component: <Admin />,
      permission: "Admin",
      path: "admin",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.dashboard_permissions?.flatMap?.((menu) => 
      menu.menu_name);
    if (!menus) {
      menus = [];
    }
    return menus;

    //eslint-disable-next-line
  }, [globalState?.dashboard_permissions, location?.pathname]);


  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };

    return user.permissions?.includes(permission);
  }

  const allowedUserComponent = UsersComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  return {
    allowedUserComponent,
  };
};

export default useRoutes;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { startOfMonth } from "date-fns";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedBranches: "",
  selectedFranchise: "",
  startDate: startOfMonth(new Date()),
  endDate: new Date(),
  branchesOptions: [],
  franchiseData: []
};

export const getFranchiseById = createAsyncThunk(
  "v1/listing/franchise",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        id ? `/v1/listing/franchise/${id}` : `/v1/listing/franchise`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getListData = createAsyncThunk(
  "v1/reports/rlm2-report",
  async ({data}, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get('v1/reports/rlm2-report', { params: data });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const addItemToTable = createAsyncThunk(
  "testimonials/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "testimonials/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(
        `/admin/testimonial/delete?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "testimonials/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/testimonial/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "testimonials/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const reportsRLM3Slice = createSlice({
  name: "reportsRLM2Slice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
  },
  extraReducers: {
    [getFranchiseById.fulfilled]: (state, action) => {
      state.franchiseData = action.payload.data;
    
    },
  },
});

export const { updateConfig, setEditTestimonail } = reportsRLM3Slice.actions;

export default reportsRLM3Slice.reducer;

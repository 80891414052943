import { combineReducers } from "@reduxjs/toolkit";

import { staff } from "./queries/settings";
import { usersRtk } from "./queries/users";
import roleSlice from "./slices/Settings/roleSlice";

import globalSlice from "./slices/Global";

import { profile } from "./queries/Profile";

import customersSlice from "./slices/customers/customersSlice";
import customerDetailsPageSlice from "./slices/customers/customerDetailsPageSlice";
import registerCustomersSlice from "./slices/customers/registerCustomersSlice";

import testimonialSlice from "./slices/Settings/testimonialsSlice";
import faqs from "./slices/Settings/faqsSlice";

import { faqsRtk } from "./queries/cms";

import { dashboard } from "./queries/Dashboard";

import userSlice from "./slices/Users/adminUsersSlice";
import userRolesSlice from "./slices/UserRoles/userRolesSlice";
import surrenderSlice from "./slices/surrender/surrenderSlice";
import purchaseSlice from "./slices/purchase/purchaseSlice";
import purchasesSlice from "./slices/Purchases/purchasesSlice";
import surrenderDetailsPageSlice from "./slices/surrender/surrenderDetailsPageSlice";
import leadsSlice from "./slices/Leads/LeadsSlice";

import reportsRLM1Slice from "./slices/Reports/reportsRLM1Slice";
import reportsRLM2Slice from "./slices/Reports/reportsRLM2Slice";
import reportsRLM3Slice from "./slices/Reports/reportsRLM3Slice";
import purchaseRegisterReportSlice from "./slices/Reports/purchaseRegisterReportSlice";
import currencySlice from "./slices/Currency/CurrencyListSlice";

import { customer } from "./queries/customers";
import { purchase } from "./queries/purchases";
import { surrender } from "./queries/surrenders";
import { adminUser } from "./queries/admin";
import { userRoles } from "./queries/roles";
import { leads } from "./queries/leads";
import { rlm1Report } from "./queries/reportsRLM1/";
import { rlm2Report } from "./queries/reportsRLM2/";
import { rlm3Report } from "./queries/reportsRLM3/";
import addNewUserSlice from "./slices/Users/addNewUserSlice";
import { purchaseReport } from "./queries/PurchaseRegisterReport";
import { currencyRtk } from "./queries/currency";
import { cancelRequest } from "./queries/cancellationRequest";
import cancellationRequestSlice from "./slices/CancellationRequest/cancellationRequestSlice";
import { cancellationRequestSurrender } from "./queries/cancellationRequestSurrender";
import cancellationRequestSurrenderSlice from "./slices/CancellationRequestSurrender/cancellationRequestSurrenderSlice";

const appReducer = combineReducers({
  [staff.reducerPath]: staff.reducer,
  [profile.reducerPath]: profile.reducer,
  [faqsRtk.reducerPath]: faqsRtk.reducer,
  [usersRtk.reducerPath]: usersRtk.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [customer.reducerPath]: customer.reducer,
  [surrender.reducerPath]: surrender.reducer,
  [purchase.reducerPath]: purchase.reducer,
  [adminUser.reducerPath]: adminUser.reducer,
  [userRoles.reducerPath]: userRoles.reducer,
  [leads.reducerPath]: leads.reducer,
  [rlm1Report.reducerPath]: rlm1Report.reducer,
  [rlm2Report.reducerPath]: rlm2Report.reducer,
  [rlm3Report.reducerPath]: rlm3Report.reducer,
  [purchaseReport.reducerPath]: purchaseReport.reducer,
  [currencyRtk.reducerPath]: currencyRtk.reducer,
  [cancelRequest.reducerPath]: cancelRequest.reducer,
  [cancellationRequestSurrender.reducerPath]: cancellationRequestSurrender.reducer,
  cancellationRequestSurrender: cancellationRequestSurrenderSlice,
  cancelRequest: cancellationRequestSlice,
  customers: customersSlice,
  purchases: purchaseSlice,
  surrender: surrenderSlice,
  purchasesList: purchasesSlice,
  customerDetailsPage: customerDetailsPageSlice,
  surrenderDetailsPage: surrenderDetailsPageSlice,
  registerCustomers: registerCustomersSlice,
  purchase: purchaseSlice,
  users: userSlice,
  newUser: addNewUserSlice,
  userRoles: userRolesSlice,
  leads: leadsSlice,
  rlm1Report: reportsRLM1Slice,
  rlm2Report: reportsRLM2Slice,
  rlm3Report: reportsRLM3Slice,
  purchaseRegisterReport: purchaseRegisterReportSlice,
  currency: currencySlice,

  role: roleSlice,

  global: globalSlice,
  testimonials: testimonialSlice,
  faqs: faqs,
});

export default appReducer;

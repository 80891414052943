import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import { getMenuPermissions, updateConfig } from "../../../store/slices/Global";
import { useDispatch } from "react-redux";
import useRoutes from "../../../routes/useRoutes";

const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);
  const {
    allowedUserComponent,
  } = useRoutes();


  useEffect(() => {
    if (
      !globalState.isLogged &&
      pathname === "/"
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }

    //eslint-disable-next-line
  }, [pathname]);


  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedUserComponent?.length > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);


      if (allowedUserComponent?.length > 0) {
        navigate(
          `/${allowedUserComponent?.map((item) => item?.path)?.[0]}`
        );
      } 
    }

    //eslint-disable-next-line
  }, [
    allowedUserComponent,
  ]);

  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: "/",
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Customers",
      link: "/customers",
      isButton: false,
      active: checkIfActiveRoute("/customers"),
    },
    {
      label: "Report",
      link: "/reports",
      isButton: false,
      active: checkIfActiveRoute("reports"),
    },
    {
      label: "Surrenders",
      link: "/surrender",
      isButton: false,
      active: checkIfActiveRoute("/surrender"),
    },
    {
      label: "Admin",
      link: "/admin",
      isButton: false,
      active: checkIfActiveRoute("/admin"),
    },
    {
      label: "User Roles",
      link: "/permissions",
      isButton: false,
      active: checkIfActiveRoute("/permissions"),
    },
    {
      label: "Purchase",
      link: "/purchases",
      isButton: false,
      active: checkIfActiveRoute("/purchases"),
    },
    {
      label: "Leads",
      link: "/leads",
      isButton: false,
      active: checkIfActiveRoute("/leads"),
    },

    {
      label: "Branch Currency Rate",
      link: "/currency-rate",
      isButton: false,
      active: checkIfActiveRoute("/currency-rate"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  var words = [
    "surrender/detail-surrender",
    "customers/purchases",
    "purchases/detail-purchase",
  ];
  const checkIsDetailPage = words.some((i) => pathname.includes(i));

  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu.menu_name,
    ]);

    // menus = [
    //   "Customers",
    //   "Reports",
    //   "Surrender",
    //   "Admin",
    //   "Roles & Permisson",
    //   "Purchases",
    //   "Leads"
    // ];

    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    handleGoBack,
    checkIsDetailPage,
  };
};

export default useCommonLayout;

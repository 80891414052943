import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setFilterValues } from "../../../../store/slices/Currency/CurrencyListSlice";

const useUserFilter = (setShowform, refetch) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails, filter } = useSelector((state) => state.currency);

  const validationObject = {
    currency: Yup.object().nullable(),
    branch: Yup.object().nullable(),
  };

  const validationSchema = Yup.object().shape(validationObject);

  const initialValues = {
    currency: filter.currency || "",
    branch: filter.branch || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize:true,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      if (values.currency === "" && values.branch === "") {
        setShowform(false);
        toast.error("Please select any field.");
      } else {
        dispatch(
          setFilterValues({
            values,
          })
        );
        setShowform(false);
      }
    },
  });

  const handleResetFilter = () => {
    dispatch(setFilterValues(false));
  };

  const renderTabContent = () => {
    return (
      <BasicForm
        formik={formik}
        setShowform={setShowform}
        isEditForm={selectedItemsDetails !== "" ? true : false}
        handleResetFilter={handleResetFilter}
      />
    );
  };

  return {
    formik,
    renderTabContent,
  };
};

export default useUserFilter;

import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";

import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";
import CustomersList from "../components/Customers/CustomersList";
import Customers from "../components/Customers";

import Reports from "../components/Reports";
import RLM1 from "../components/Reports/RLM1";
import RLM2 from "../components/Reports/RLM2";
import RLM3 from "../components/Reports/RLM3";
import Surrender from "../components/Surrender";
import SurrenderList from "../components/Surrender/SurrenderList";
import Purchases from "../components/Customers/Purchases";
import Admin from "../components/Admin";
import UsersList from "../components/Admin/UsersList";
import Permissions from "../components/Permissions";
import AllPurchases from "../components/AllPurchases";
import PurchasesList from "../components/AllPurchases/Purchases";
import PermissionsList from "../components/Permissions/PermissionsList";
import Leads from "../components/Leads";
import LeadsList from "../components/Leads/LeadsList";
import CaptureEmail from "../components/Auth/CaptureEmailForm";
import PurchaseForm from "../components/PurchaseForm";
import PurchaseFormContainer from "../components/Customers/PurchaseForm";
import ConfirmationScreen from "../components/Customers/PurchaseForm/ConfirmationScreen";
import SurrenderFormContainer from "../components/Surrender/surrenderForm";
import SurrenderFormLayout from "../components/Surrender/surrenderForm/surrenderFormLayout";
import SurrenderDetailTable from "../components/Surrender/surrenderDetailTable";
import PurchaseDetailTable from "../components/AllPurchases/Purchases/purchaseDetailTable";
import ManageRoles from "../components/Permissions/Roles/ManageRoles";
import PurchaseRegisterReport from "../components/Reports/Purchase Register Report";
import CurrencyRate from "../components/CurrencyRate";
import CurrencyRateList from "../components/CurrencyRate/CurrencyRateList";
import MonthYearPicker from "../components/test";
import CancellationRequest from "../components/AllPurchases/cancellationRequest";
import CancellationRequestSurrender from "../components/Surrender/cancellationRequestSurrender";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />

        <Route path="customers" element={<Customers />}>
          <Route path="" element={<CustomersList />} />
          <Route path="purchase/:customersID" element={<Purchases />}></Route>
          <Route
            path="purchase/:customersID/detail-purchase/:purchaseID"
            element={<PurchaseDetailTable />}
          ></Route>
        </Route>

        <Route
          path="/customers/purchase/:customersID/add-purchase"
          element={<PurchaseForm />}
        >
          <Route path="" element={<PurchaseFormContainer />} />
        </Route>

        <Route
          path="/customers/purchase/:customersID/edit-purchase/:purchaseID"
          element={<PurchaseForm />}
        >
          <Route path="" element={<PurchaseFormContainer />} />
        </Route>

        <Route
          path="/customers/purchase/:customersID/confirm-payment/:purchaseID"
          element={<PurchaseForm />}
        >
          <Route path="" element={<ConfirmationScreen />} />
        </Route>

        <Route path="reports" element={<Reports />}>
          <Route path="" element={<RLM1 />}></Route>
          <Route path="rlm2" element={<RLM2 />}></Route>
          <Route path="rlm3" element={<RLM3 />}></Route>
          <Route
            path="purchase-register-report"
            element={<PurchaseRegisterReport />}
          ></Route>
        </Route>

        <Route path="surrender" element={<Surrender />}>
          <Route path="" element={<SurrenderList />} />
          <Route path="cancellation-request" element={<CancellationRequestSurrender />} />
          <Route
            path="cancellation-request/detail-surrender/:surrenderID"
            element={<SurrenderDetailTable />}
          ></Route>
          <Route
            path="detail-surrender/:surrenderID"
            element={<SurrenderDetailTable />}
          ></Route>
        </Route>
        <Route
          path="/surrender/edit-surrender/:surrenderId"
          element={<SurrenderFormLayout />}
        >
          <Route path="" element={<SurrenderFormContainer />} />
        </Route>

        <Route
          path="/surrender/add-surrender"
          element={<SurrenderFormLayout />}
        >
          <Route path="" element={<SurrenderFormContainer />} />
        </Route>
        <Route path="admin" element={<Admin />}>
          <Route path="" element={<UsersList />} />
        </Route>

        <Route path="currency-rate" element={<CurrencyRate />}>
          <Route path="" element={<CurrencyRateList />} />
        </Route>

        <Route path="permissions" element={<Permissions />}>
          <Route path="" element={<PermissionsList />} />
          <Route path="create-role" element={<ManageRoles />} />
        </Route>

        <Route path="purchases" element={<AllPurchases />}>
          <Route path="" element={<PurchasesList />}></Route>
          <Route path="cancellation-request" element={<CancellationRequest />}></Route>
          <Route
            path="cancellation-request/detail-purchase/:purchaseID"
            element={<PurchaseDetailTable />}
          ></Route>
          <Route
            path="detail-purchase/:purchaseID"
            element={<PurchaseDetailTable />}
          ></Route>
        </Route>

        <Route path="leads" element={<Leads />}>
          <Route path="" element={<LeadsList />} />
        </Route>

        <Route path="test" element={<MonthYearPicker />} />

        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password"
            element={<CaptureEmail />}
          ></Route>
          <Route
            path="forgot-password/:token"
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="reset-password/:token"
            element={<ResetPassword />}
          ></Route>
        </Route>
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;

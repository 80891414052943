// TransactionForm.js
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import Style from "./purchaseForm.module.scss";

const TransactionForm = ({ transaction, index, currencyOptions, formik }) => {

  const handleCurrencyChange = (selectedOption) => {
    // Reset the rate of the particular group when currencyName changes
    formik.setFieldValue(`transactions.${index}.currencyName`, selectedOption);

    setTimeout(() => {
      // Reset the rate value
      formik.setFieldValue(`transactions.${index}.rate`, "");

      // Reset the INR Volume value
      formik.setFieldValue(`transactions.${index}.inrVolume`, "");
    }, 100);
  };

  const handleCurrencyVolumeChange = (event) => {
    const { value } = event.target;

    // Check if the currencyName is selected
    if (!formik.values.transactions[index].currencyName) {
      toast.error("Currency is not selected!");
      return; // Exit the function if currencyName is not selected
    }

    // Check if the value is a valid number
    if (isNaN(value)) {
      return; // Exit the function if the value is not a number
    }

    formik.setFieldValue(
      `transactions[${index}].currencyVolume`,
      value != "" ? value : ""
    );

    // Calculate INR Volume based on currency volume and rate
    const rate = formik.values.transactions[index].rate;
    const inrVolume = value * rate;
    formik.setFieldValue(`transactions.${index}.inrVolume`, inrVolume);
  };

  const handleRateChange = (event) => {
    const { value } = event.target;

    // Check if the currencyName is selected
    if (!formik.values.transactions[index].currencyName) {
      toast.error("Currency is not selected!");
      return; // Exit the function if currencyName is not selected
    }

    // Check if the value is a valid number
    if (isNaN(value)) {
      return; // Exit the function if the value is not a number
    }

    formik.setFieldValue(`transactions.${index}.rate`, value);

    // Calculate INR Volume based on currency volume and rate
    const currencyVolume = formik.values.transactions[index].currencyVolume;
    const inrVolume = currencyVolume * value;
    const formattedInrVolume = parseFloat(inrVolume.toFixed(2));
    formik.setFieldValue(`transactions.${index}.inrVolume`, formattedInrVolume);
  };

  return (
    <div
      className={`pro-mb-5 pro-pb-2 ${Style.purchase_form_wrap}`}
      key={index}
    >
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Currency Name {index === 0 ? "*" : ""}
        </label>
        <Select
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.currencyName &&
            formik.touched.transactions?.[index]?.currencyName &&
            "error"
          }`}
          classNamePrefix={`pro-input`}
          value={transaction.currencyName}
          onChange={handleCurrencyChange} // Handle currency change event
          onBlur={() =>
            formik.setFieldTouched(`transactions.${index}.currencyName`, true)
          }
          name={`transactions.${index}.currencyName`}
          options={currencyOptions}
        />

        {formik.errors.transactions?.[index]?.currencyName &&
          formik.touched.transactions?.[index]?.currencyName && (
            <span className="error-text">
              {formik.errors.transactions[index].currencyName}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-2 pro-fw-medium">
          Payment Type {index === 0 ? "*" : ""}
        </label>
        <div className="row gx-6">
          <div className="col-auto">
            <div className="pro-check-box">
              <input
                type="radio"
                className="pro-check"
                id={`radio1-${index}`}
                name={`radio-${index}`}
                checked={transaction.paymentType === 1}
                value="1"
                onChange={(e) =>
                  formik.setFieldValue(`transactions[${index}].paymentType`, 1)
                }
              />
              <label htmlFor={`radio1-${index}`} className="pro-check-label">
                Currency
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box">
              <input
                type="radio"
                className="pro-check"
                id={`radio2-${index}`}
                name={`radio-${index}`}
                checked={transaction.paymentType === 2}
                value="2"
                onChange={(e) => {
                  formik.setFieldValue(`transactions[${index}].paymentType`, 2);
                }}
              />
              <label htmlFor={`radio2-${index}`} className="pro-check-label">
                Travel cheque
              </label>
            </div>
          </div>
        </div>
        {(formik.touched.transactions?.[index]?.paymentType ||
          formik.touched.transactions?.[index]?.radio) &&
          formik.errors.transactions?.[index]?.paymentType && (
            <span className="error-text">
              {formik.errors.transactions[index].paymentType}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Currency Volume {index === 0 ? "*" : ""}
        </label>
        <input
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.currencyVolume &&
            formik.touched.transactions?.[index]?.currencyVolume &&
            "error"
          }`}
          value={transaction.currencyVolume}
          onChange={handleCurrencyVolumeChange}
          onBlur={formik.handleBlur}
          name={`transactions[${index}].currencyVolume`}
        />
        {formik.errors.transactions?.[index]?.currencyVolume &&
          formik.touched.transactions?.[index]?.currencyVolume && (
            <span className="error-text">
              {formik.errors.transactions[index].currencyVolume}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Rate {index === 0 ? "*" : ""}
        </label>
        <input
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.rate &&
            formik.touched.transactions?.[index]?.rate &&
            "error"
          }`}
          value={transaction.rate}
          onChange={handleRateChange}
          onBlur={formik.handleBlur}
          name={`transactions[${index}].rate`}
        />
        {formik.errors.transactions?.[index]?.rate &&
          formik.touched.transactions?.[index]?.rate && (
            <span className="error-text">
              {formik.errors.transactions[index].rate}
            </span>
          )}
      </div>

      <div className="input-wrap">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          INR Volume {index === 0 ? "*" : ""}
        </label>
        <input
          readOnly
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.inrVolume &&
            formik.touched.transactions?.[index]?.inrVolume &&
            "error"
          }`}
          value={transaction.inrVolume}
          onChange={(e) =>
            formik.setFieldValue(
              `transactions[${index}].inrVolume`,
              e.target.value
            )
          }
          onBlur={formik.handleBlur}
          name={`transactions[${index}].inrVolume`}
        />
        {formik.errors.transactions?.[index]?.inrVolume &&
          formik.touched.transactions?.[index]?.inrVolume && (
            <span className="error-text">
              {formik.errors.transactions[index].inrVolume}
            </span>
          )}
      </div>

      {index > 0 && (
        <button
          type="button"
          className={`pro-btn pro-btn-outline pro-mt-4 pro-ms-auto ${Style.remove_btn}`}
          onClick={() =>
            formik.setFieldValue(
              "transactions",
              formik.values.transactions.filter((_, i) => i !== index)
            )
          }
        >
          <span class="material-symbols-outlined">delete</span>
          <span>Remove Transaction</span>
        </button>
      )}
    </div>
  );
};

export default TransactionForm;

import { useDispatch, useSelector } from "react-redux";
import {
	useGetRlm1ReportDataQuery,
	useUpdateRlm1ReportDataMutation,
} from "../../../store/queries/reportsRLM1";
import { addDays } from "date-fns";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import Style from "./customers.module.scss";
import { getFranchiseById, getListData, updateConfig } from "../../../store/slices/Reports/reportsRLM1Slice";
import { getRevFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
	getAllBranches,
	getAllFranchise,

} from "../../../store/slices/customers/registerCustomersSlice";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { toast } from "react-toastify";

const useRLM1 = () => {
	const dispatch = useDispatch();

	const rlm1Report = useSelector((state) => state.rlm1Report);
	const { branchLoding } = useSelector((state) => state.registerCustomers)

	const { showEditModal } = useSelector((state) => state.global);
	const [showform, setShowform] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [selectedItemID, setSelectedItemID] = useState("");
	const [branchesOptions, setBranches] = useState([]);
	const [franchiseOptions, setFranchise] = useState([]);
	const [namePdf, setName] = useState("");
	const printRef = useRef(null);
	let user_type = localStorage.getItem("user_role");
	let user_data = localStorage.getItem("login_data");
	let branch_id = localStorage.getItem("branch_data");

	useEffect(() => {

		dispatch(getAllBranches())
			.unwrap()
			.then((result) => {

				if (user_type == 3) {
					setBranches(
						result.data.data?.map((item) => ({
							label: item.name,
							value: item.id,
						}))
					);
				}
				else if (user_type == 2) {
					setSeleoption(branch_id);
				}
				else {
					setBranches([
						...[
							{
								label: "All",
								value: "",
							},
						],
						...result.data.data?.map((item) => ({
							label: item.name,
							value: item.id,
						})),
					]);
					dispatch(
						updateConfig((state) => {
							state.selectedBranches = {
								label: "All",
								value: "",
							};
						})
					);
				}
			})
			.catch((err) => { });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user_type == 3) {
			if (user_data) {
				let selectedBranchOPtion = branchesOptions.find((item) => (item.value == user_data));
				if (selectedBranchOPtion) {
					setName(selectedBranchOPtion.label);
					dispatch(
						updateConfig((state) => {
							state.selectedBranches = selectedBranchOPtion
						})
					);
					setSeleoption(user_data);
				}
			}
		}
	}, [branchesOptions]);

	useEffect(() => {
		if (user_type == 2) {
			if (user_data) {
				let selectedFranchise = franchiseOptions.find((item) => (item.value == user_data));
				setName(selectedFranchise?.label);
				dispatch(
					updateConfig((state) => {
						state.selectedFranchise = selectedFranchise
					})
				)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [franchiseOptions]);

	let paginationOptions = [
		{ label: "10 per page", value: 10 },
		{ label: "20 per page", value: 20 },
		{ label: "30 per page", value: 30 },
	];

	const [date, setDate] = useState({
		startDate: rlm1Report.startDate,
		endDate: rlm1Report.endDate,
		key: "selection",
	});

	useEffect(() => {
		if (rlm1Report.selectedItemsDetails !== "" && !showform) {
			dispatch(
				updateConfig((state) => {
					state.selectedItemsDetails = "";
				})
			);
		}
		// eslint-disable-next-line
	}, [showform]);

	// useEffect(() => {
	//   handleDateChange(new Date());
	// }, []);

	const [updateTestimonialsFields] = useUpdateRlm1ReportDataMutation();
	const [skip, setSkip] = useState(true);

	useEffect(() => {
		const selectedFranchise = rlm1Report?.selectedFranchise;
		const selectedBranch = rlm1Report?.selectedBranches;

		if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type == 2) {
			setSkip(false);
			
		}
		else if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type != 2) {
			setSkip(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm1Report?.selectedFranchise]);

	useEffect(() => {
		const selectedBranch = rlm1Report.selectedBranches
		
		if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value && (user_type == 3 || user_type != 2)) {
			setSkip(false)
		}
		else if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value == "" && (user_type != 3 || user_type != 2)) {
			setSkip(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm1Report?.selectedBranches]);


	const {
	  data: rlm1ReportList = {},
	  isFetching,
	  isLoading,
	  refetch,
	} = useGetRlm1ReportDataQuery({
	  page_size: rlm1Report.currentPageSize,
	  page: rlm1Report.currentPage,
	  sort_by: rlm1Report.sortBy,
	  sort_order: rlm1Report.sortOrder,
	  start_date: getRevFormatedDate(rlm1Report.startDate) + " 00:00:00",
	  end_date:
	    getRevFormatedDate(rlm1Report.endDate) +
	    ` 23:59:59`,
	  search: rlm1Report.search,
	  branch_id: rlm1Report?.selectedBranches,
	  franchise_id: rlm1Report?.selectedFranchise
	}, {skip: skip});


	

	const hasCreatePermission = useMemo(() => {
		let permission = rlm1ReportList?.data?.permission?.filter((p) =>
			Object.keys(p).includes("can_create")
		);

		return permission?.length ?? permission?.[0]?.can_create;
	}, [rlm1ReportList]);

	const hasViewPermission = useMemo(() => {
		let permission = rlm1ReportList?.data?.permission?.filter((p) =>
			Object.keys(p).includes("can_view")
		);

		return permission?.length ?? permission?.[0]?.can_view;
	}, [rlm1ReportList]);

	const hasEditPermission = useMemo(() => {
		let permission = rlm1ReportList?.data?.permission?.filter((p) =>
			Object.keys(p).includes("can_edit")
		);

		return permission?.length ?? permission?.[0]?.can_edit;
	}, [rlm1ReportList]);

	const handleActionSetFranchiseies = (franchise) => {
		setName(franchise?.label)
		dispatch(
			updateConfig((state) => {
				state.selectedBranches = franchise;
			})
		);
		if (franchise?.value) {
			setSeleoption(franchise?.value);
		}
		else {
			setFranchise([]);
			dispatch(
				updateConfig((state) => {
					state.selectedFranchise = "";
				})
			);
		}

	};

	const setSeleoption = (id) => {
		dispatch(getFranchiseById(id))
			.unwrap()
			.then((result) => {
				if (result?.data?.message === "Success") {
					if (user_type == 2) {
						let temp = result?.data?.data?.filter((item) => item.id == user_data)
						setFranchise(
							temp?.map((item) => (
								{
									label: item?.name,
									value: item?.id,
								}
							))
						);
					}
					else {
						setFranchise(
							result?.data?.data?.map((item) => (
								{
									label: item?.name,
									value: item?.id,
								}
							))
						);
					}

				} else {
					toast.error(result?.data?.message);
				}

			});
	}

	const handleActionSetFranchiseById = (data) => {
		setName(data?.label)
		dispatch(
			updateConfig((state) => {
				state.selectedFranchise = data;
			})
		);
	};

	const handlePagination = (page) => {
		dispatch(
			updateConfig((state) => {
				state.currentPage = page;
			})
		);
	};

	const handlePageSize = ({ value: page_size }) => {
		dispatch(
			updateConfig((state) => {
				state.currentPageSize = page_size;
				state.currentPage = 1;
			})
		);
	};

	const convertDateFormat = (inputDateString) => {
		// Create a new Date object with the input date string
		var inputDate = new Date(inputDateString);

		// Extract the year, month, and day from the Date object
		var year = inputDate.getFullYear();
		var month = String(inputDate.getMonth() + 1).padStart(2, "0");
		var day = String(inputDate.getDate()).padStart(2, "0");

		// Create the desired output date string
		var outputDateString = year + "-" + month + "-" + day;

		return outputDateString;
	};

	const handleDateChange = (range) => {
		dispatch(
			updateConfig((state) => {
				state.currentPage = 1;
				state.startDate = convertDateFormat(range) + " 00:00:00";
				state.endDate = convertDateFormat(range) + " 23:59:59";
			})
		);
	};

	const handleDelete = async (ids) => {
		setShowDeleteConfirm(true);
		setSelectedItemID(ids);
	};

	const handleDeleteAction = async () => { };
	const handleSort = (label) => {
		if (rlm1Report.sortBy === label) {
			dispatch(
				updateConfig((state) => {
					state.currentPage = 1;
					state.sortOrder = rlm1Report.sortOrder === "asc" ? "desc" : "asc";
				})
			);
		} else {
			dispatch(
				updateConfig((state) => {
					state.currentPage = 1;
					state.sortBy = label;
					state.sortOrder = "asc";
				})
			);
		}
	};

	const handleEditAction = (ids) => { };

	const handleSearch = (e) => {
		dispatch(
			updateConfig((state) => {
				state.search = e.target.value;
				state.currentPage = 1;
			})
		);
	};
	const handleEditClick = () => {
		dispatch(
			globalUpdateConfig((state) => {
				state.showEditModal = true;
			})
		);
	};

	const closeEditModal = () => {
		dispatch(
			globalUpdateConfig((state) => {
				state.showEditModal = false;
			})
		);
	};

	const getRow = (feild, data) => {
		const rows = {
			common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
			staff_id: (feild, data) => (
				<p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
			),
		};

		return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
	};

	const handlePrintClick = (data) => {
		const element = printRef.current;

		let Tablelength = rlm1ReportList?.data?.table?.rows?.length;

		html2pdf(element, {
			margin: [15, 0, 10, 0],
			filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
			image: { type: "jpeg", quality: 1 },
			html2canvas: {
				width:
					Tablelength <= 5
						? 650
						: Tablelength > 5 && Tablelength <= 10
							? 820
							: Tablelength > 10 && Tablelength <= 15
								? 1250
								: Tablelength > 15 && Tablelength <= 20
									? 1550
									: 2200,

				dpi: 100,
				letterRendering: true,
				useCORS: true,
				logging: true,
				scale: 4,
				scrollY: 0,
			},
			jsPDF: {
				unit: "mm",
				format: "a4",
				orientation: Tablelength <= 10 ? "portrait" : "landscape",
			},
			pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
			DisablePdfCompression: true,
		});
	};

	const handleDateRangeChange = (range) => {
		const startDate = range[0];
		const endDate = range[1];

		setDate({
			startDate,
			endDate,
		});

		dispatch(
			updateConfig((state) => {
				state.currentPage = 1;
				state.startDate = startDate;
				state.endDate = endDate;
			})
		);

		// const queryParameters = {
		// 	start_date: getRevFormatedDate(startDate) + " 00:00:00",
		// 	end_date:
		// 		getRevFormatedDate(endDate) + ` 23:59:59`,
		// 	branch_id: rlm1Report.selectedBranches?.value,
		// 	franchise_id: rlm1Report?.selectedFranchise?.value
		// }
		// getCall(queryParameters);

	};

	return {
		rlm1ReportList,
		rlm1Report,
		date,
		currentPage: rlm1Report.currentPage,
		paginationOptions,
		isFetching,
		isLoading,
		handleDateRangeChange,
		handlePagination,
		handleDateChange,
		handlePageSize,
		handleSort,
		handleSearch,
		getRow,
		handleEditClick,
		closeEditModal,
		showEditModal,
		// refetch,
		handleDelete,
		showform,
		setShowform,
		showDeleteConfirm,
		setShowDeleteConfirm,
		handleDeleteAction,
		handleEditAction,
		updateTestimonialsFields,
		hasCreatePermission,
		hasViewPermission,
		hasEditPermission,
		branchesOptions,
		handleActionSetFranchiseies,
		handleActionSetFranchiseById,
		printRef,
		handlePrintClick,
		user_type,
		franchiseOptions,
		namePdf,
		branchLoding,
		user_data,
		branch_id
	};
};

export default useRLM1;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  isLoading:false,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  customerDetails: {},
  editCustomer: false,
  selectedBranchDetails:[]
};

export const getAllStates = createAsyncThunk(
  "addNewUser/getAllStates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/state/active-states`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "addNewUser/getAllCountries",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/country/active-countries`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "addNewUser/getAllRoles",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/user-role/active`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBranches = createAsyncThunk(
  "addNewUser/getAllBranches",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/branches`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const addNewStore = createAsyncThunk(
  "addNewUser/addNewStore",
  async (storeData, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/store`, storeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "addNewUser/updateUser",
  async ({ id, formData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/update/${id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addNewUserSlice = createSlice({
  name: "addNewUser",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [addNewStore.pending]: (state, action) => {
      state.isLoading=true
    },
    [addNewStore.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [addNewStore.rejected]: (state, action) => {
      state.isLoading=false
    },
    [updateUser.pending]: (state, action) => {
      state.isLoading=true
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoading=false
    },
  },
});

export const { updateConfig } = addNewUserSlice.actions;

export default addNewUserSlice.reducer;

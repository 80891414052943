import { useDispatch, useSelector } from "react-redux";
import {
  useGetLeadsListDataQuery,
  useUpdateLeadsListDataMutation,
} from "../../../store/queries/leads";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
} from "../../../store/slices/Leads/LeadsSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { Button, ModalLayout } from "@wac-ui-dashboard/wac_component_library";
import { toast } from "react-toastify";

const useLeadsList = () => {
  const dispatch = useDispatch();

  const leads = useSelector((state) => state.leads);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [message, setMessage] = useState("");
  const [isModelShow, setIsModelShow] = useState(false);
  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const [filter, setFilter] = useState(true);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (leads.singleLeadInfo !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.singleLeadInfo = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateLeadsListDataMutation();

  const {
    data: LeadsList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetLeadsListDataQuery({
    length: leads.currentPageSize,
    page: leads.currentPage,
    sort_by: leads.sortBy,
    sort_order: leads.sortOrder,
    start: leads.startDate,
    end: leads.endDate,
    search: leads.search,
    franchise_id: leads.franchise.value,
    lead_type_id: leads.lead_type_id.value,
    start_date: leads.startDate ? leads.startDate + " 00:00:00" : "",
    end_date: leads.endDate ? leads.endDate + " 00:00:00" : "",
  });

  const hasCreatePermission = useMemo(() => {
    let permission = LeadsList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [LeadsList]);

  const hasDeletePermission = useMemo(() => {
    let permission = LeadsList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [LeadsList]);

  const hasEditPermission = useMemo(() => {
    let permission = LeadsList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [LeadsList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.data?.message === "Success") {
            toast.success(result?.data?.message);
          } else {
            toast.error(result?.data?.message);
          }
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };
  const handleSort = (label) => {
    if (leads.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = leads.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID(ids[0]))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleViewMessage = (Message) => {
    setMessage(Message);
    setIsModelShow(true);
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      customer_message: (feild, data) => (
        <p className="pro-mb-0">
          <Button
            onClick={() => {
              handleViewMessage(data?.customer_message);
            }}
            className="pro-btn pro-btn-primary pro-px-5 lg"
          >
            View Message
          </Button>
        </p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    LeadsList,
    leads,
    currentPage: leads.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    message,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    setIsModelShow,
    isModelShow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setIsFilterShow,
    isFilterShow,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    filter,
    setFilter,
  };
};

export default useLeadsList;

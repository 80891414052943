import React from "react";
import Style from "./purchaseForm.module.scss";
import TransactionDetails from "./TransactionDetails";
import TransactionForm from "./TransactionForm";
import CDFDetails from "./CDFDetails";
import usePurchaseForm from "./usePurchaseForm";
import { useEffect } from "react";

const PurchaseFormContainer = () => {
  const {
    formik,
    currencyOptions,
    paymentModeOptions,
    isLoading,
    customerDetails,
    total,
    isCDFrequired,
  } = usePurchaseForm();


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row gx-5">
          <div className="col-8 pro-ps-6 pro-pe-5">
            {/* <div className="pro-pb-4">
              <span className="badge ">
                {`${customerDetails?.full_name} | ${customerDetails?.customer_code}`}
              </span>
            </div> */}
            <div className="row gx-6">
              <div className="col-6 pro-pb-5">
                <h6 className="pro-ttl pro-mb-4">Transaction</h6>
                {formik.values.transactions.map((transaction, index) => (
                  <TransactionForm
                    key={index}
                    transaction={transaction}
                    index={index}
                    currencyOptions={currencyOptions}
                    formik={formik}
                  />
                ))}

                <button
                  type="button"
                  className="pro-btn pro-btn-secondary pro-w-100 lg"
                  onClick={() =>
                    formik.setFieldValue("transactions", [
                      ...formik.values.transactions,
                      {
                        currencyName: "",
                        radio: "",
                        currencyVolume: "",
                        rate: "",
                        inrVolume: "",
                      },
                    ])
                  }
                >
                  <span>Add Transaction</span>
                  <span class="material-symbols-outlined">add</span>
                </button>
              </div>

              <div className="col-6">
                <h6 className="pro-ttl pro-mb-4">CDF Details</h6>
                <CDFDetails
                  formik={formik}
                  paymentModeOptions={paymentModeOptions}
                  total={total}
                  isCDFrequired={isCDFrequired}
                />
              </div>
            </div>
          </div>

          <div
            className={`col-4 ${Style.transaction_table_root_wrap} pro-px-5`}
          >
            <div
              className={`${Style.transaction_table_root} pro-pt-5 pro-px-2 pro-d-flex pro-flex-column`}
            >
              <TransactionDetails
                formik={formik}
                total={total}
                customerDetails={customerDetails}
                customer={true}
              />

              <div
                className={`pro-py-5 pro-w-100 pro-mt-auto ${Style.btn_container}`}
              >
                <button
                  disabled={
                    window.location.href.indexOf("edit-purchase") !== -1
                      ? !formik.isValid
                      : !(formik.isValid && formik.dirty)
                  }
                  className="pro-btn pro-btn-primary lg pro-w-100"
                >
                  {isLoading ? "Loading..." : "Proceed to payment"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PurchaseFormContainer;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetUserRolesListDataQuery,
  useUpdateUserRolesListDataMutation,
} from "../../../store/queries/roles";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  deleteItemFromTable,
  editItemFromTableByID,
} from "../../../store/slices/UserRoles/userRolesSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const usePermissionsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRoles = useSelector((state) => state.userRoles);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (userRoles.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateUserRolesListDataMutation();

  const {
    data: userRolesList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUserRolesListDataQuery({
    page_size: userRoles.currentPageSize,
    page: userRoles.currentPage,
    sort_by: userRoles.sortBy,
    sort_order: userRoles.sortOrder,
    start: userRoles.startDate,
    end: userRoles.endDate,
    search: userRoles.search,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("success") == "true") {
      refetch();
    }
  }, []);

  const hasCreatePermission = useMemo(() => {
    let permission = userRolesList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [userRolesList]);

  const hasViewPermission = useMemo(() => {
    let permission = userRolesList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? false;
  }, [userRolesList]);

  const hasEditPermission = useMemo(() => {
    let permission = userRolesList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [userRolesList]);

  const hasDeletePermission = useMemo(() => {
    let permission = userRolesList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [userRolesList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.data?.message === "Success") {
            toast.success(result?.data?.message);
          } else {
            toast.error(result?.data?.message);
          }
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          handleUnknownError(setShowDeleteConfirm);
        });
    } else {
      setShowDeleteConfirm(false);
      handleUnknownError(setShowDeleteConfirm);
    }
  };
  const handleSort = (label) => {
    if (userRoles.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = userRoles.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleUnknownError = () => {
    alert("Sorry something went wrong!");
  };

  const handleEditAction = (ids) => {
    navigate({
      pathname: "/permissions/create-role",
      search: `role=${ids[0]}&update=true`,
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    userRolesList,
    userRoles,
    currentPage: userRoles.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    hasDeletePermission,
  };
};

export default usePermissionsList;

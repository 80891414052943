import React, { useEffect, useState } from "react";
import styles from "./purchaseDetail.module.scss";
import { useSelector } from "react-redux";
import CustomerProfileShimmer from "../Shimmers/CustomerProfileShimmer";

const PurchaseDetail = () => {
	const puchaseDetailsPage = useSelector((state) => state.purchasesList);
	const date = new Date(puchaseDetailsPage?.selectedItemsDetails?.created_at);
	let formatter = date.toLocaleDateString("en-GB", {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
  		minute: "2-digit",
  		second: "2-digit",

	});
	const CDFdate = new Date(puchaseDetailsPage?.selectedItemsDetails?.cdf_date);
	let cdfFormatter = CDFdate.toLocaleDateString("en-GB", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
	return puchaseDetailsPage?.surrenderDetailsLoading === "loading" ? (
		<div className={`${styles.root} pro-d-flex pro-flex-column`}>
			<CustomerProfileShimmer />
		</div>
	) : (
		<div className={`${styles.root} pro-d-flex pro-flex-column`}>
			<div className={`${styles.profile_top} `}>
				<div className="pro-py-5">
					<h4 className="pro-ttl pro-mb-2">Purchase Details</h4>
					<p className="pro-mb-0">
						<span>{puchaseDetailsPage?.selectedItemsDetails?.invoice_no} </span>
						{puchaseDetailsPage?.selectedItemsDetails?.is_confirmed ===
							"Confirmed" ? (
							<span
								className={`pro-badge badge-success ${styles.success} pro-ms-3`}
							>
								{puchaseDetailsPage?.selectedItemsDetails?.is_confirmed}
							</span>
						) : puchaseDetailsPage?.selectedItemsDetails?.is_confirmed ===
							"Cancellation Requested" ? (
							<span
								className={`pro-badge ${styles.blue} pro-ms-3`}
							>
								{puchaseDetailsPage?.selectedItemsDetails?.is_confirmed}
							</span>
						) : (
							<span className={`pro-badge badge-danger pro-ms-3 `}>
								{puchaseDetailsPage?.selectedItemsDetails?.is_confirmed}
							</span>
						)}
					</p>
				</div>
			</div>
			<div className={`${styles.table_wrap} `}>
				<div className="pro-pt-5">
					<h6 className="pro-mb-3 pro-ttl">Details</h6>
					<table className={styles.table}>
						<tbody>
							<tr>
								<td>
									<p className="pro-font-sm pro-fw-medium pro-mb-0">
										Payment Mode
									</p>
								</td>
								<td>
									<p className="pro-mb-0">
										{
											puchaseDetailsPage?.selectedItemsDetails?.payment_mode
												?.payment_mode
										}
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p className="pro-font-sm pro-fw-medium pro-mb-0">
										Created At
									</p>
								</td>
								<td>
									<p className="pro-mb-0">{formatter}</p>
								</td>
							</tr>

							{localStorage.getItem("user_role") === "1" || "3" ? (
								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											Purchased From
										</p>
									</td>
									<td>
										<p className="pro-mb-0">
											{
												puchaseDetailsPage?.selectedItemsDetails?.franchise
													?.name
											}
										</p>
									</td>
								</tr>
							) : null}

							<tr></tr>
						</tbody>
					</table>
				</div>
				{puchaseDetailsPage?.selectedItemsDetails?.cdf_number ? (
					<div className={`pro-pt-5`}>
						<h6 className="pro-mb-3 pro-ttl">CDF Details</h6>
						<table className={styles.table}>
							<tbody>
								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											CDF Number
										</p>
									</td>
									<td>
										<p className="pro-mb-0">
											{puchaseDetailsPage?.selectedItemsDetails?.cdf_number}
										</p>
									</td>
								</tr>

								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											CDF Amount
										</p>
									</td>
									<td>
										<p className="pro-mb-0">
											{puchaseDetailsPage?.selectedItemsDetails?.amount_int_cdf}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											CDF Date
										</p>
									</td>
									<td>
										<p className="pro-mb-0">{cdfFormatter}</p>
									</td>
								</tr>
								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											Customer Office Place
										</p>
									</td>
									<td>
										<p className="pro-mb-0">
											{
												puchaseDetailsPage?.selectedItemsDetails
													?.customer_office_place
											}
										</p>
									</td>
								</tr>
								<tr></tr>
							</tbody>
						</table>
					</div>
				) : null}
				{puchaseDetailsPage?.selectedItemsDetails?.cancellation_remarks?.[0] ? (
					<div className={`pro-pt-5`}>
						{/* <h6 className="pro-mb-3 pro-ttl">CDF Details</h6> */}
						<table className={styles.table}>
							<tbody>
								<tr>
									<td>
										<p className="pro-font-sm pro-fw-medium pro-mb-0">
											Remark
										</p>
									</td>
									<td>
										<p className="pro-mb-0">
											{puchaseDetailsPage?.selectedItemsDetails?.cancellation_remarks[0].remark}
										</p>
									</td>
								</tr>
								<tr></tr>
							</tbody>
						</table>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PurchaseDetail;

// CDFDetails.js
import React from "react";
import Select from "react-select";

const PaymentMode = ({ formik, paymentModeOptions }) => {
  return (
    <>
      <h6 className="pro-ttl pro-mb-4 pro-pt-3">Payment details</h6>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Payment Mode *
        </label>
        <Select
          isDisabled={
            window.location.href.indexOf("edit-surrender") !== -1 ? true : false
          }
          className={`pro-input lg ${
            formik.errors.paymentMode && formik.touched.paymentMode && "error"
          }`}
          value={formik.values.paymentMode}
          onChange={(selectedOption) =>
            formik.setFieldValue("paymentMode", selectedOption)
          }
          classNamePrefix={"pro-input"}
          onBlur={() => formik.setFieldTouched("paymentMode", true)}
          name="paymentMode"
          id="paymentMode"
          options={paymentModeOptions}
        />
        {formik.errors.paymentMode && formik.touched.paymentMode && (
          <span className="error-text">{formik.errors.paymentMode}</span>
        )}
      </div>
    </>
  );
};

export default PaymentMode;

import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import Style from "./purchaseReg.module.scss";
import TableStructurePurchaseRegisterReport from "../TableStructurePurchaseRegisterReport";
import usePurchaseRegisterReport from "./usePurchaseRegisterReport";
import { MyCalendar } from "../../Global/MonthYearCalander";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Select from "react-select";

const PurchaseRegisterReport = () => {
  const {
    date,
    rlm3ReportList,
    purchaseRegisterReport,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handleDateRangeChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    // hasViewPermission,
    hasEditPermission,
    franchiseiesOptions,
    handleActionSetFranchiseies,
    printRef,
    handlePrintClick,
    selectedMonth,
    user_type,
    franchiseOptions,
    branchesOptions,
    handleActionSetFranchiseById
  } = usePurchaseRegisterReport();

  return (
    <>
      <HeadingGroup
        title={"Purchase Register"}
        className={`pro-mb-4`}
        buttonTitle={/*hasCreatePermission ?*/ "Export" || ""}
        handleClick={handlePrintClick}
        buttonProps={{disabled:rlm3ReportList?.rows?.length  > 0 ? "" : "disabled"}}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={rlm3ReportList?.data?.filters}
          // extraFilters={<MyCalendar handleDateChange={handleDateChange} selectedMonth={selectedMonth} />}
          // onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          // showDateRange={false}
          // datePickerProps={true}
          showActions={false}
          searchable={false}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>

              {user_type != 2 ? (
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseies}
                    options={branchesOptions}
                    value={branchesOptions?.filter(
                      (item) =>
                        item?.label === purchaseRegisterReport?.selectedBranches?.label
                    )}
                    placeholder="Select Branch"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable={true}
                    isDisabled={(user_type == 3) ? true : false}
                  />
                </div>
              ) : (<></>)}
                <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetFranchiseById}
                    options={franchiseOptions}
                    value={franchiseOptions?.filter(
                      (item) =>
                        item?.label === purchaseRegisterReport?.selectedFranchise?.label
                    )}
                    placeholder="Select Franchise"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isDisabled={((user_type == 2) || (purchaseRegisterReport?.selectedBranches?.label == "All") || (purchaseRegisterReport?.selectedBranches?.label == undefined)) ? true : false}
                    isClearable={true}
                  />
                </div>
              
            </>
          }
          // initialDateRange={date}
        />

        {rlm3ReportList?.rows?.length > 0 && !isFetching ? (
          <>
            <div className={Style.table_wraper}>
              <TableStructurePurchaseRegisterReport
                printRef={printRef}
                showMonth={true}
                state={purchaseRegisterReport}
                title={"Purchase Register"}
                rlm3ReportList={rlm3ReportList}
                getRow={getRow}
                isFetching={isFetching}
                handleEditAction={handleEditAction}
                handleDelete={handleDelete}
              />
            </div>
            <Table
              // ref={printRef}
              multiSelect={false}
              data={rlm3ReportList?.rows || []}
              fields={rlm3ReportList?.field}
              showCheckBox={false}
              getRow={getRow}
              loading={isFetching}
              perpage={purchaseRegisterReport.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDelete}
              deletable={true}
            />
          </>
        ) : isFetching ? (
          <div className="app-loading fill"></div>
        ) : (
          <EmptyData subtitle={"Select the filter to generate the Report"} />
        )}

        {/* <Table
          ref={printRef}
          multiSelect={false}
          data={rlm3ReportList?.rows || []}
          uniqueID={"_id"}
          fields={rlm3ReportList?.field}
          showCheckBox={hasEditPermission ? true : false}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={purchaseRegisterReport.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDelete}
          deletable={true}
        /> */}

        {rlm3ReportList?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={rlm3ReportList?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={rlm3ReportList?.data?.fields}
              moduleId={rlm3ReportList?.data?.module_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default PurchaseRegisterReport;

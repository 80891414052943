import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

const VisaForm = ({ formik, setActiveTab, tabs, setShowform }) => {
  function isVisaNumberRequired() {
    return (
      (formik.values?.visaType.label === "Non Resident" ||
        formik.values?.visaType.label === "Resident") &&
      formik.values.nationality.label !== "India"
    );
  }

  return (
    <div>
      {/* Render form fields for the "Visa" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="visaNumber"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Visa Number {isVisaNumberRequired() ? "*" : ""}
          </label>
          <input
            type="text"
            name="visaNumber"
            id="visaNumber"
            placeholder="Visa Number"
            onChange={(event) => {
              // Remove any non-digit characters from the input
              const numericValue = event.target.value.replace(/\D/g, "");
              // Update the formik value with the numeric value
              formik.setFieldValue("visaNumber", numericValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.visaNumber}
            className={`pro-input lg ${
              formik.errors.visaNumber && formik.touched.visaNumber && "error"
            }`}
          />
          {formik.touched.visaNumber && formik.errors.visaNumber && (
            <div className="error-text">{formik.errors.visaNumber}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="visaIssueAt"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Visa Issue At {isVisaNumberRequired() ? "*" : (formik.values.visaNumber ? "*" : "")}
          </label>
          <input
            type="date"
            name="visaIssueAt"
            id="visaIssueAt"
            placeholder="Visa Issue At"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visaIssueAt}
            className={`pro-input lg ${
              formik.errors.visaIssueAt && formik.touched.visaIssueAt && "error"
            }`}
            max={new Date().toISOString().split("T")[0]} // Set max date to today
          />
          {formik.touched.visaIssueAt && formik.errors.visaIssueAt && (
            <div className="error-text">{formik.errors.visaIssueAt}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="visaIssueDate"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Visa Issue Date {isVisaNumberRequired() ? "*" : (formik.values.visaNumber ? "*" : "")}
          </label>
          <input
            type="date"
            name="visaIssueDate"
            id="visaIssueDate"
            placeholder="Visa Issue Date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visaIssueDate}
            className={`pro-input lg ${
              formik.errors.visaIssueDate &&
              formik.touched.visaIssueDate &&
              "error"
            }`}
            max={new Date().toISOString().split("T")[0]} // Set max date to today
          />
          {formik.touched.visaIssueDate && formik.errors.visaIssueDate && (
            <div className="error-text">{formik.errors.visaIssueDate}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="visaExpiryDate"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Visa Expiry Date {isVisaNumberRequired() ? "*" : (formik.values.visaNumber ? "*" : "")}
          </label>
          <input
            type="date"
            name="visaExpiryDate"
            id="visaExpiryDate"
            placeholder="Visa Expiry Date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visaExpiryDate}
            className={`pro-input lg ${
              formik.errors.visaExpiryDate &&
              formik.touched.visaExpiryDate &&
              "error"
            }`}
            min={formik.values.visaIssueDate}
          />
          {formik.touched.visaExpiryDate && formik.errors.visaExpiryDate && (
            <div className="error-text">{formik.errors.visaExpiryDate}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
         type="button"
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={
          //   formik?.errors?.visaNumber != undefined ||
          //   formik?.errors?.visaIssueAt != undefined ||
          //   formik?.errors?.visaIssueDate != undefined ||
          //   formik?.errors?.visaExpiryDate != undefined
          // }
          onClick={() => setActiveTab(tabs[3])}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default VisaForm;

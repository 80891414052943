import { CreateWidget } from "@wac-ui-dashboard/wac_component_library";
import useCreateRoleModal from "./useCreateRoleModal";

const CreateRoleModal = () => {
  const { roleState, handleRoleClick } = useCreateRoleModal();

  return (
    <div className="pro-m-4">
      <CreateWidget
        description={"Start fresh with a blank role template."}
        icon={<span className="material-symbols-outlined">draft</span>}
        handleClick={handleRoleClick}
      />
      {roleState?.allRoles?.length !== 0 && (
        <CreateWidget
          description={"Clone settings from previous roles"}
          items={roleState?.allRoles}
          icon={<span className="material-symbols-outlined">description</span>}
          handleClick={handleRoleClick}
        />
      )}
    </div>
  );
};

export default CreateRoleModal;

import React from "react";
import { Outlet } from "react-router-dom";
import useSurrenderLayout from "./useSurrenderLayout";
import CommonLayout from "../../../pages/Layouts/CommonLayout";

const SurrenderFormLayout = () => {
  const { drawerMenu } = useSurrenderLayout();
  return (
    <CommonLayout drawerMenu={drawerMenu} dashboard={true}>
      <Outlet />
    </CommonLayout>
  );
};

export default SurrenderFormLayout;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";
import AddressForm from "./AddressForm";
import VisaForm from "./VisaForm";
import IdentityForm from "./IdentityForm";
import {
  createNewCustomer,
  resetEditAction,
  updateCustomer,
} from "../../../../store/slices/customers/registerCustomersSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { emailRegExp } from "../../../../utils/functions/table";

const useMultiTabForm = (setShowform, refetch) => {
  const [activeTab, setActiveTab] = useState({
    label: "Basic",
    completed: 100,
  });

  const [moveToTab, setMoveToTab] = useState(false);

  const dispatch = useDispatch();

  const { editCustomer, customerDetails, isLoading } = useSelector(
    (state) => state.registerCustomers
  );

  const emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const validationSchema = Yup.object({
    // Define your form field validations here
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    dialCode: Yup.object().required("Dial Code is required"),
    mobileNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile Number must be a 10-digit number")
      .matches(/^[0-9]*$/, "Mobile Number must contain only numbers"),
    email:Yup.string()
    .email('Invalid email address'),

    dateOfBirth: Yup.date()
    .max(new Date(), "Date must be today or earlier"),
  
    visaType: Yup.object().required("Visa Type is required"),
    // Yup.string()
    //   .email("Please enter a valid email")
    //   .test("email", "Invalid email format", function (value) {
    //     // Custom email validation using regular expression
    //     // Check for valid email format only if the value is not empty
    //     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    //     return !value || emailRegex.test(value);
    //   }),
    //  Yup.string().matches(
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //   "Invalid Email address"
    // ),
    // dateOfBirth: Yup.date().required("Date of Birth is required"),
    nationality: Yup.object().required("Nationality is required"),
    country: Yup.object().required("Country is required"),
    state: Yup.object().when(["country.value"], ([country], schema) => {
      if (country != undefined) {
        return country === 92 ? schema.required("State is required") : schema;
      } else {
        return schema.required("State is required");
      }
    }),
    city: Yup.string().required("City is required"),
    address: Yup.string().required("Address is required"),
    // permanentAddress: Yup.string().when(
    //   ["country.value", "visaType.label"],
    //   (country, visaType, schema) => {
    //     if (visaType === "Non Resident" && country === "India") {
    //       return schema.required("Permanent Address is required");
    //     } 
    //     return schema.nullable();
    //   }
    // ),

    permanentAddress: Yup.string()
    .when(
      ["visaType.label", "country.value"],
      (values, schema) => {
        const [visaType, country] = values || [];

        if (visaType === "Non Resident" && country === 92) {
          return schema.required("Permanent Address is required");
        }

        return schema.nullable();
      }
    ),
    
    
    
    visaNumber: Yup.string().when(
      ["visaType.label", "nationality.label"],
      (values, schema) => {
        const [visaType, nationality] = values || [];

        if (visaType === "Non Resident" && nationality !== "India") {
          return schema.required("Visa Number is required");
        }

        if (visaType === "Resident" && nationality !== "India") {
          return schema.required("Visa Number is required");
        }

        return schema.nullable();
      }
    ),
    visaIssueAt: Yup.date()
    .when(
      ["visaType.label", "nationality.label","visaNumber"],
      (values, schema) => {
        const [visaType, nationality, visaNumber] = values || [];

        if (visaType === "Non Resident" && nationality !== "India") {
          return schema.required("Visa Issue At is required");
        }

        if (visaType === "Resident" && nationality !== "India") {
          return schema.required("Visa Issue At is required");
        }
        if(visaNumber) {
          return schema.required("Visa Issue At is required");
        }

        return schema.nullable();
      }
    )
    .max(new Date(), "Date must be today or earlier"),

    visaIssueDate: Yup.date().when(
      ["visaType.label", "nationality.label", "visaNumber"],
      (values, schema) => {
        const [visaType, nationality, visaNumber] = values || [];

        if (visaType === "Non Resident" && nationality !== "India") {
          return schema.required("Visa Issue Date is required");
        }

        if (visaType === "Resident" && nationality !== "India") {
          return schema.required("Visa Issue Date is required");
        }
        if(visaNumber) {
          return schema.required("Visa Issue Date is required");
        }

        return schema.nullable();
      }
    )
    .max(new Date(), "Date must be today or earlier"),

    visaExpiryDate: Yup.date().when(
      ["visaType.label", "nationality.label", "visaNumber"],
      (values, schema) => {
        const [visaType, nationality, visaNumber] = values || [];

        if (visaType === "Non Resident" && nationality !== "India") {
          return schema.required("Visa Expiry Date is require");
        }

        if (visaType === "Resident" && nationality !== "India") {
          return schema.required("Visa Issue Date is required");
        }
        if(visaNumber) {
          return schema.required("Visa Issue Date is required");
        }

        return schema.nullable()
        .test("valid-year", "Date must be within the next 10 years", function (value) {
          if (!value) {
            // Allow empty value
            return true;
          }
  
          const currentYear = new Date().getFullYear();
          const selectedYear = new Date(value).getFullYear();
  
          // Check if the selected year is within the next 10 years
          const isWithinNext10Years = selectedYear >= currentYear && selectedYear <= currentYear + 10;
  
          return isWithinNext10Years;
        });;
      }
    ),
    group: Yup.array().of(
      Yup.object().shape({
        idType: Yup.object().required("ID Type is required"),
        idNumber: Yup.string().required("ID Number is required"),
        idIssueDate: Yup.date()
        .required("ID Issue Date is required")
        .max(new Date(), "Date must be today or earlier"),
        idExpiryDate: Yup.date()
        .required("ID Expiry Date is required")
        .test("valid-year", "Date must be within the next 10 years", function (value) {
          if (!value) {
            // Allow empty value
            return true;
          }
  
          const currentYear = new Date().getFullYear();
          const selectedYear = new Date(value).getFullYear();
  
          // Check if the selected year is within the next 10 years
          const isWithinNext10Years = selectedYear >= currentYear && selectedYear <= currentYear + 10;
  
          return isWithinNext10Years;
        }),
      })
    ),
  });

 

  const nationalityOptions = {
    label: customerDetails?.nationality?.name,
    value: customerDetails?.nationality?.id,
  };
  const visaTypeOptions = {
    label: customerDetails?.visa_details?.id_type,
    value: customerDetails?.visa_details?.visa_type,
  };
  const countryOptions = {
    label: customerDetails?.country?.name,
    value: customerDetails?.country?.id,
  };
  const stateOptions = {
    label: customerDetails?.state?.name,
    value: customerDetails?.state?.id,
  };

  const dialCodeOptions = {
    label: customerDetails?.dialCode || "+91",
    value: customerDetails?.dialCode || "+91",
  };
  const removeTime = (dateString) => {
    try {
      if (typeof dateString === "undefined") {
        return ""; // Return an empty string or any appropriate default value
      }

      return dateString?.split(" ")[0];
    } catch (error) {}
  };

  // Set the initial values based on whether edit is true or false
  const initialValues = editCustomer
    ? {
        firstName: customerDetails?.first_name,
        lastName: customerDetails?.last_name,
        dialCode: dialCodeOptions,
        mobileNumber: customerDetails?.phone_number,
        email: customerDetails?.email ?? "",
        dateOfBirth: removeTime(customerDetails?.dob),
        nationality: nationalityOptions,
        country: countryOptions,
        state: stateOptions,
        city: customerDetails?.city,
        address: customerDetails?.address,
        permanentAddress: customerDetails?.permanent_address,
        visaType: visaTypeOptions,
        visaNumber: customerDetails?.visa_details?.visa_number,
        visaIssueAt: removeTime(customerDetails?.visa_details?.issued_at),
        visaIssueDate: removeTime(customerDetails?.visa_details?.issued_date),
        visaExpiryDate: removeTime(customerDetails?.visa_details?.expiry_date),
        group: customerDetails?.identities.map((identity) => ({
          idType: {
            label: identity?.id_type_name,
            value: identity?.id_type,
          },
          idNumber: identity?.id_number,
          idIssueDate: removeTime(identity?.issue_date),
          idExpiryDate: removeTime(identity?.expiry_date),
        })),
      }
    : {
        firstName: "",
        lastName: "",
        dialCode: dialCodeOptions,
        mobileNumber: "",
        email: "",
        dateOfBirth: "",
        nationality: "",
        country: "",
        state: "",
        city: "",
        address: "",
        permanentAddress: "",
        visaType: "",
        visaNumber: "",
        visaIssueAt: "",
        visaIssueDate: "",
        visaExpiryDate: "",
        group: [
          {
            idType: "",
            idNumber: "",
            idIssueDate: "",
            idExpiryDate: "",
          },
        ],
      };

  const formik = useFormik(
    {
    initialValues,
    validationSchema,
    // enableReinitialize: true,
   
    onSubmit: (values) => {
      const formdata = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        dialCode: values.dialCode.value,
        phone_number: values.mobileNumber,
        address: values.address,
        city: values.city,
        country_id: values.country.value,
        state_id: values.state.value,
        nationality_id: values.nationality.value,
        permanent_address: values.permanentAddress,
        dob: values.dateOfBirth != "" && values.dateOfBirth != undefined ? values.dateOfBirth + " 00:00:00" : "",
        is_active: 0,
        visa_type: values.visaType.value,
        visa_number: values.visaNumber,
        issued_at:
          values.visaIssueAt != "" && values.visaIssueAt != undefined
            ? values.visaIssueAt + " 00:00:00"
            : "",
        issued_date:
          values.visaIssueDate !== "" && values.visaIssueDate != undefined
            ? values.visaIssueDate + " 00:00:00"
            : "", //bug
        expiry_date:
          values.visaExpiryDate !== "" && values.visaExpiryDate != undefined
            ? values.visaExpiryDate + " 00:00:00"
            : "", //bug
      };

      formdata.cid = values.group.map(function (item) {
        return {
          cid_id_type: item.idType.value,
          cid_id_number: item.idNumber,
          cid_issue_date: item.idIssueDate + " 00:00:00",
          cid_expiry_date: item.idExpiryDate + " 00:00:00",
        };
      });

      let dispatchAction, successMessage;

      if (editCustomer) {
    
        dispatchAction = dispatch(updateCustomer({id: customerDetails.id, params: formdata,})
        );
        // resetForm();
        successMessage = "Customer has been updated successfully.";
      } else {
        dispatchAction = dispatch(createNewCustomer(formdata));
        // resetForm();
        successMessage = "New customer has been added successfully.";
      }

      dispatchAction
        .unwrap()
        .then((result) => {
          if (result.data.success === true) {
            refetch();
            toast.success(successMessage);
            if (editCustomer) {
              dispatch(resetEditAction());
            }
            setShowform(false);
          } else {
            toast.error("Sorry something went wrong.");
          }
        })
        .catch((err) => {
          Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
            if (fieldName === "phone_number") {
              formik.setFieldError("mobileNumber", errorMessage);
              setActiveTabByName("Basic");
            }
            if (fieldName === "email") {
              formik.setFieldError("email", errorMessage);
              setActiveTabByName("Basic");
            }
          });
        });

      // Handle form submission here
    },
  });

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "firstName",
        "lastName",
        "dialCode",
        "mobileNumber",
        // "email",
        // "dateOfBirth",
        "nationality",
        "visaType"
      ],
    },
    {
      label: "Address",
      fields: ["country", "state", "city", "address", "permanentAddress"],
    },
    {
      label: "Visa",
      fields: ["visaNumber", "visaIssueAt", "visaIssueDate", "visaExpiryDate"],
    },
    {
      label: "Identity",
      fields: ["idType", "idNumber", "idIssueDate", "idExpiryDate"],
    },
  ];

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "firstName",
        "lastName",
        "dialCode",
        "mobileNumber",
        // "email",
        // "dateOfBirth",
        "nationality",
        "visaType"
      ]),
    },
    {
      label: "Address",
      completed: calculateCompletionStatus([
        "country",
        "state",
        "city",
        "address",
        "permanentAddress",
      ]),
    },
    {
      label: "Visa",
      completed: calculateCompletionStatus([
        "visaNumber",
        "visaIssueAt",
        "visaIssueDate",
        "visaExpiryDate",
      ]),
    },
    {
      label: "Identity",
      completed: calculateCompletionStatus([
        "idType",
        "idNumber",
        "idIssueDate",
        "idExpiryDate",
      ]),
    },
  ];

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab.label === active);
    setActiveTab(updatedTab);
  }

  function calculateCompletionStatus(fields) {
    if (
      fields.includes("state") &&
      fields.includes("permanentAddress") &&
      formik.values.country.value !== "india"
    ) {
      fields = ["country", "city", "address"];
    }

    if (
      formik.values.visaType.label === "Resident" &&
      formik.values.nationality.label == "India" &&
      fields.includes("visaNumber")
    ) {
      fields = ["visaNumber", "visaIssueAt", "visaIssueDate", "visaExpiryDate"];
    }

    var completedFields = fields.filter((field) => {
      if (Array.isArray(formik.values[field])) {
        return formik.values[field].length > 0;
      } else {
        if (
          field === "idType" ||
          field === "idNumber" ||
          field === "idIssueDate" ||
          field === "idExpiryDate"
        ) {
          if (formik.values.group.length > 0) {
            return !!formik.values.group?.[0][field];
          }
        } else {
          return !!formik.values[field];
        }
      }
    });

    // if (
    //   formik.values.visaType.label === "Resident" &&
    //   formik.values.nationality.label == "India" &&
    //   fields.includes("visaNumber")
    // ) {
    //   return 100;
    // } else {
    //   return (completedFields.length / fields.length) * 100;
    // }


    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleFieldChange = (event, index, fieldName) => {
    const { value } = event.target;
    const { group } = formik.values;
    group[index][fieldName] = value;
    formik.setFieldValue("group", group);
  };

  const handleRemove = (index) => {
    const { group } = formik.values;
    group.splice(index, 1);
    formik.setFieldValue("group", group);
  };

  const handleAdd = () => {
    const { group } = formik.values;
    group.push({ idType: "", idNumber: "", issueDate: "", expiryDate: "" });
    formik.setFieldValue("group", group);
  };

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
  }, [formik.errors]);

  const handleMoveToErrorTab = function () {
 
 
    const firstErrorTab = Object.keys(formik.errors)[0];
    
    if(formik.errors != []) {
      formik.handleSubmit();
    }
    if (firstErrorTab === "group") {
      setActiveTabByName("Identity");
      return "";
    }
    if (firstErrorTab && firstErrorTab !== activeTab?.label) {
      const errorTab = tabsAndFields.find((tab) =>
        {
          return tab.fields.includes(firstErrorTab)
        }
      );

      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const renderTabContent = () => {
    switch (activeTab?.label) {
      case "Basic":
        return (
          <>
          <BasicForm
            formik={formik}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setShowform={setShowform}
          />
          </>
         
        );
      case "Address":
        return (
          <AddressForm
            formik={formik}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setShowform={setShowform}
          />
        );
      case "Visa":
        return (
          <VisaForm
            formik={formik}
            setActiveTab={setActiveTab}
            tabs={tabs}
            setShowform={setShowform}
          />
        );
      case "Identity":
        return (
          <IdentityForm
            formik={formik}
            handleRemove={handleRemove}
            handleAdd={handleAdd}
            isLoading={isLoading}
            setShowform={setShowform}
            checkError={handleMoveToErrorTab}
          />
        );
      default:
        return null;
    }
  };

  return {
    tabs,
    isLoading,
    activeTab,
    handleTabClick,
    formik,
    renderTabContent,
    editCustomer,
  };
};

export default useMultiTabForm;

import React, { useState } from "react";
import useMultiTabForm from "./useMultiTabForm";
import Style from '../customers.module.scss';
import { FormTabs } from "../../../Global/FormTabs";

function MultiTabForm({ setShowform, refetch }) {
  const { tabs, activeTab, handleTabClick, formik, renderTabContent } =
    useMultiTabForm(setShowform, refetch);

  return (
    <div>
      <FormTabs
        tabs={tabs}
        active={activeTab?.label}
        handleClick={handleTabClick}
      />
      <form onSubmit={formik.handleSubmit}>{renderTabContent()}</form>
    </div>
  );
}

export default MultiTabForm;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  addNewCurrency,
  updateCurrencyRate,
} from "../../../../store/slices/Currency/addNewCurrencySlice";

const useCurrencyAddForm = (setShowform, refetch) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.currency);
  const { isLoading } = useSelector((state) => state.newUser);
  const [loading, setLoading] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState({
    label: selectedItemsDetails?.currency?.name,
    value: selectedItemsDetails?.currency?.id,
  });

  const validationObject = {
    currency: Yup.object().required("Currency is required"),
    rate: Yup.string()
      .required("Rate is required")
      .test("is-valid-number", "Invalid rate number", (value) => {
        if (value === undefined || value === null || value === "") return false;
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue);
      })
      .min(0, "Rate must be greater than or equal to 0")
      .nullable(),
  };

  const validationSchema = Yup.object().shape(validationObject);

  const initialValues = {
    currency: "",
    rate: "",
  };

  useEffect(() => {
    if (selectedItemsDetails) {
      // Update the initialValues if selectedItemsDetails is not empty

      initialValues.currency = selectedCurrency || "";
      initialValues.rate = selectedItemsDetails.rate || "";
      initialValues.isEditForm = true;
      // Set other initial values as needed
    }
  }, [selectedItemsDetails]);

  const onSubmitCreate = async (values, setFieldError) => {
  
    // API call for creating a new user
    const { currency, rate } = values;

    const formData = {
      currency_id: currency.value,
      rate: rate,
    };

    return await dispatch(addNewCurrency(formData));
  };

  const onSubmitUpdate = async (values, setFieldError) => {
    // API call for updating an existing user
    const { currency, rate } = values;

    const formData = {
      currency_id: currency.value,
      rate: rate,
    };

    // Make the API call for updating the user using the appropriate endpoint or method
    // For example:
    return await dispatch(
      updateCurrencyRate({ id: selectedItemsDetails.id, formData: formData })
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {
        setLoading(true);
        const result = values.isEditForm
          ? await onSubmitUpdate(values, setFieldError)
          : await onSubmitCreate(values, setFieldError);
        if (result.payload.success) {
          setLoading(false);
          const successMessage = values.isEditForm
            ? "Curreny rate has been updated successfully."
            : "Curreny rate has been added successfully.";

          toast.success(successMessage);
          setShowform(false);
          resetForm();
          refetch();
        } else {
          setLoading(false);
          if (result.payload.errors) {
            Object.keys(result.payload.errors).forEach((fieldName) => {
              setFieldError(fieldName, result.payload.errors[fieldName][0]);
            });
          } else {
            toast.error(result.message || "Something went wrong.");
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.status === 401) {
          toast.error("Unauthorized access. Please login again.");
        } else if (Object.keys(err.errors).length > 0) {
          const responseErrors = err.errors;
          Object.keys(responseErrors).forEach((fieldName) => {
            setFieldError(fieldName, responseErrors[fieldName][0]);
          });
        } else {
          toast.error("Something went wrong.");
        }
      }
    },
  });

  const renderTabContent = () => {
    return (
      <BasicForm
        formik={formik}
        setShowform={setShowform}
        isLoading={isLoading}
        loading={loading}
        isEditForm={selectedItemsDetails !== "" ? true : false}
      />
    );
  };

  return {
    formik,
    renderTabContent,
    loading,
    setLoading,
  };
};

export default useCurrencyAddForm;

import React, { useEffect, useState } from "react";
import Style from "./addStaffForm.module.scss";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getAllLeads } from "../../../../store/slices/Leads/LeadsSlice";

const AddFormFields = ({ formik, franchiseOptions }) => {

  const dispatch = useDispatch();
  const [leadTypeOptions, setLeadType] = useState([]);

  useEffect(() => {
    dispatch(getAllLeads())
      .unwrap()
      .then((result) => {
        setLeadType(
          result?.data?.data?.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={Style.root}>
      <div className="col-12">
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Lead Type *
          </label>

          <Select
          id="customer_lead_type"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${formik.touched.customer_lead_type && formik.errors.customer_lead_type && " error"
            }`}
          classNamePrefix="pro-input"
          options={leadTypeOptions}
          value={leadTypeOptions?.filter(
            (m) => formik.values.customer_lead_type === m?.value
          )}
          onBlur={formik.handleBlur("customer_lead_type")}
          onChange={(value) => {
            formik.setFieldValue("customer_lead_type", value?.value || null);
          }}
        />

          {formik.touched.customer_lead_type && formik.errors.customer_lead_type && (
            <span className="error-text">{formik.errors.customer_lead_type}</span>
          )}
        </div>
      </div>
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="customer_name"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Customer Name *
          </label>
          <input
            type="text"
            className={`pro-input lg ${
              formik.errors.customer_name && formik.touched.customer_name
                ? "error"
                : ""
            }`}
            id="customer_name"
            name="customer_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customer_name}
          />
          {formik.touched.customer_name && formik.errors.customer_name && (
            <span className="error-text">{formik.errors.customer_name}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="customer_phone"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Customer Phone *
          </label>
          <input
            type="tel"
            className={`pro-input lg ${
              formik.errors.customer_phone && formik.touched.customer_phone
                ? "error"
                : ""
            }`}
            id="customer_phone"
            name="customer_phone"
            onChange={(event) => {
              // Remove any non-digit characters from the input
              const numericValue = event.target.value.replace(/\D/g, "");
              // Update the formik value with the numeric value
              formik.setFieldValue("customer_phone", numericValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.customer_phone}
          />
          {formik.touched.customer_phone && formik.errors.customer_phone && (
            <span className="error-text">{formik.errors.customer_phone}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="customer_address"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Customer Address *
          </label>
          <input
            type="text"
            className={`pro-input lg ${
              formik.errors.customer_address && formik.touched.customer_address
                ? "error"
                : ""
            }`}
            id="customer_address"
            name="customer_address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customer_address}
          />
          {formik.touched.customer_address &&
            formik.errors.customer_address && (
              <span className="error-text">
                {formik.errors.customer_address}
              </span>
            )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="customer_email"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Customer Email
          </label>
          <input
            type="email"
            className={`pro-input lg ${
              formik.errors.customer_email && formik.touched.customer_email
                ? "error"
                : ""
            }`}
            id="customer_email"
            name="customer_email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customer_email}
          />
          {formik.touched.customer_email && formik.errors.customer_email && (
            <span className="error-text">{formik.errors.customer_email}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="customer_message"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Customer Message *
          </label>
          <textarea
            className={`pro-input lg ${
              formik.errors.customer_message && formik.touched.customer_message
                ? "error"
                : ""
            }`}
            id="customer_message"
            name="customer_message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.customer_message}
          ></textarea>
          {formik.touched.customer_message &&
            formik.errors.customer_message && (
              <span className="error-text">
                {formik.errors.customer_message}
              </span>
            )}
        </div>

        {/* <div className="input-wrap pro-mb-4">
          <label
            htmlFor="franchise_id"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Franchise
          </label>
          <Select
            className="pro-input lg"
            classNamePrefix={`pro-input`}
            id="franchise_id"
            name="franchise_id"
            options={franchiseOptions}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={franchiseOptions?.filter(
              (p) =>
                p.value?.toString() === formik.values.franchise_id?.toString()
            )}
            onChange={(value) =>
              formik.setFieldValue("franchise_id", value?.value)
            }
            onBlur={formik.handleBlur}
            
          />
          {formik.touched.franchise_id && formik.errors.franchise_id && (
            <span className="error-text">{formik.errors.franchise_id}</span>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default AddFormFields;

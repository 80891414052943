import React, { useState } from "react";
import useCurrencyAddForm from "./useCurrencyAddForm";

function CurrencyAddForm({ setShowform, refetch }) {
  const { renderTabContent } = useCurrencyAddForm(setShowform, refetch);

  return (
    <div>
      <form action={"javascript:void(0)"}>{renderTabContent()}</form>
    </div>
  );
}

export default CurrencyAddForm;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CancelRequestFilterForm from "./CancelRequestFilterForm";
import { resetExtraFilters, setStoreFormValues } from "../../../../store/slices/CancellationRequestSurrender/cancellationRequestSurrenderSlice";

const useFilterForm = (setShowform) => {
  const dispatch = useDispatch();

  const {
    franchiseId,
    startDate,
    endDate,
  } = useSelector((state) => state.cancellationRequestSurrender);
  const validationSchema = Yup.object({
    franchise: Yup.object().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    franchise: franchiseId,
    startDate: startDate,
    endDate: endDate,
  
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        franchise,
        startDate,
        endDate,
      } = values;

      if (
        franchise === "" &&
        startDate === "" &&
        endDate === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <CancelRequestFilterForm
        formik={formik}
        handleResetExtraFilters={handleResetExtraFilters}
      />
    );
  };

  const handleResetExtraFilters = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    formik,
    renderTabContent,
    handleResetExtraFilters,
  };
};

export default useFilterForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  isCDFrequired: false,
  selectedItemsDetails: "",
};

export const calculateAmount = createAsyncThunk(
  "purchase/calculateAmount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/purchase/calculate-amount", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchActiveCurrency = createAsyncThunk(
  "purchase/fetchActiveCurrency",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/currency/active-currency");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchActivePaymentModes = createAsyncThunk(
  "purchase/fetchActivePaymentModes",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/payment-mode/active-paymentmodes");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPurchaseByCustomerID = createAsyncThunk(
  "purchase/createPurchaseByCustomerID",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/purchase/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePurchaseByCustomerID = createAsyncThunk(
  "purchase/updatePurchaseByCustomerID",
  async ({ purchaseID, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/purchase/update/${purchaseID}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const purchaseSlice = createSlice({
  name: "purchaseSlice",
  initialState,
  reducers: {
    resetValues: (state) => {
      state.calculatedAmount = null;
      state.error = null;
    },
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [calculateAmount.pending]: (state, action) => {
      // Handle the pending action here
    },
    [calculateAmount.fulfilled]: (state, action) => {
      // Update state with the calculated amount
      state.calculatedAmount = action.payload.data.data;
      state.isCDFrequired = true;
    },
    [calculateAmount.rejected]: (state, action) => {
      // Update state with the error message
      state.error = action.payload;
    },
  },
});
export const { resetValues, updateConfig } = purchaseSlice.actions;

export default purchaseSlice.reducer;

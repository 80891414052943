import { current } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Global";

const useOrderColoum = ({ tableFields, moduleId, updateData, refetchData }) => {
  // eslint-disable-next-line
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  let fieldsToChange = Object.entries(tableFields);

  fieldsToChange = fieldsToChange.reduce(
    (a, v) => ({ ...a, [v[0]]: { ...v[1], key_id: v[0] } }),
    {}
  );

  const [characters, setCharacters] = useState(() => {
    return Object.values(fieldsToChange);
  });

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCharacters(items);
  }

  const handleSubmit = () => {
    let tempData = [];
    // eslint-disable-next-line
    Object.values(characters).map((item, index) => {
      let data = {
        column_name: item.key_id,
        label: item.label,
        position: index,
        sortable: item.sortable,
        hidden: characters?.[index]?.hidden,
      };
      tempData.push(data);
    });

    const obj = {
      module_id: moduleId,
      fields: tempData,
    };

    updateData(obj).then((response) => {
      if (response?.data?.status_code === 200) {
        refetchData?.();
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
        toast.success("Fields updated Successfully!");
      } else if (response?.status_code === 400) {
        toast.error(response?.message);
      }
    });
  };

  const handleCheckbox = (Label) => {
    function getKeyByValue(object, Label) {
      return Object.keys(object).find((key) => object[key].label === Label);
    }

    let tempData = characters;
    let key = getKeyByValue(tempData, Label);
    tempData[key] = { ...tempData[key], hidden: !tempData[key].hidden };

    setCharacters(Object.values(tempData));
  };
  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  return {
    characters,
    message,
    handleCancel,
    handleSubmit,
    handleOnDragEnd,
    handleCheckbox,
  };
};

export default useOrderColoum;

import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
// import { useSelector } from "react-redux";


const useSurrender = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  let user_type = localStorage.getItem("user_role");
  // const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Surrender List",
      link: "/surrender",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/surrender", true),
    },
  ];
  if (user_type === "1") {
    drawerMenu.push({
      title: "Cancellation Requests",
      link: "/surrender/cancellation-request",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> cancel_presentation </span>,
      active: checkIfActiveRoute("/surrender/cancellation-request", true),
    });
  }
  // const drawerMenuPermission = useMemo(() => {
  //   let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
  //     ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
  //   ]);

  //   menus = ["Surrender List"];

  //   return drawerMenu
  //     .filter((menu) => menus?.includes?.(menu.title))
  //     .map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);


  return {
    drawerMenu
  };
};

export default useSurrender;

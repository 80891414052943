import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch } from "react-redux";

import { Button } from "@wac-ui-dashboard/wac_component_library";
import { getCurrency } from "../../../../store/slices/Currency/addNewCurrencySlice";

const BasicForm = ({ formik, setShowform, isEditForm, loading, isLoading }) => {
  const dispatch = useDispatch();

  const [userRoleOptions, setUserRoleOptions] = useState([]);

  const [statesOptions, setStates] = useState([]);
  const [countriesOptions, setCountries] = useState([]);

  useEffect(() => {
    dispatch(getCurrency())
      .unwrap()
      .then((result) => {
        setUserRoleOptions(
          result.data.data?.map((item) => ({
            label: `${item.name} (${item.code})`,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Currency *
          </label>
          <Select
            name="currency"
            options={userRoleOptions} // replace with your currency options
            onChange={(selectedOption) => {
              formik.setFieldValue("currency", selectedOption);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.currency}
            className={`pro-input lg ${
              formik.errors.currency && formik.touched.currency && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.currency && formik.errors.currency && (
            <div className="error-text">{formik.errors.currency}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="rate" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Rate *
          </label>
          <input
            type="rate"
            name="rate"
            id="rate"
            placeholder="Rate"
            onChange={(e) => {
              // Allow only numbers, decimal point, backspace, and some specific control keys
              const isValidInput =
                /^[0-9.]$/.test(e.nativeEvent.data) ||
                e.nativeEvent.inputType === "deleteContentBackward" || // For handling backspace in Safari
                e.ctrlKey ||
                e.altKey ||
                e.metaKey;

              if (!isValidInput) {
                e.preventDefault();
              } else {
                // Handle valid input and update the formik state
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.rate}
            className={`pro-input lg ${
              formik.errors.rate && formik.touched.rate && "error"
            }`}
          />
          {formik.touched.rate && formik.errors.rate && (
            <div className="error-text">{formik.errors.rate}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => {
            setShowform(false);
          }}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditForm
            ? loading
              ? "Please wait.."
              : "Update"
            : loading
            ? "Please wait.."
            : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useCCRateList from "./useCCRateList";
import CurrencyAddForm from "./CurrencyAddForm";
import Filter from "./Filter";
import ChangePasswordFormFieldsContainer from "./ChangePassword/Form";
import { useEffect } from "react";

const CCRateList = () => {
  const {
    usersList,
    currency,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    editItem,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateUsersFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    filterForm,
    setFilterForm,
    changePassword,
    setChangePassword,
    changePasswordUserID,
    setChangePasswordUserID,
    showEditModal,
    handleAddNewCurrencyRate,
  } = useCCRateList();


  return (
    <>
      <HeadingGroup
        title={"Currency Rate"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ?? false ? "Add new" : ""}
        handleClick={handleAddNewCurrencyRate}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col-auto col-xl-10">
            <SearchFilters
              data={usersList?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
            />
          </div>
          <div className="col-auto pro-ms-auto">
            <button
              onClick={() => {
                setFilterForm(true);
              }}
              className="pro-btn pro-btn-primary pro-px-5 lg"
            >
              Filter
            </button>
          </div>
        </div>

        <Table
          multiSelect={false}
          data={usersList?.rows?.data}
          uniqueID={"id"}
          fields={usersList?.field}
          isSelectBox
          showCheckBox={(hasEditPermission || hasDeletePermission) ?? false}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={currency.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDelete}
          editable={hasEditPermission ?? false}
          deletable={hasDeletePermission ?? false}
        />

        {usersList?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={usersList?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={usersList?.field}
              moduleId={usersList?.table_id}
              updateData={updateUsersFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={changePassword}
          handleClose={setChangePassword}
          title={"Change Password"}
        >
          <div className="pro-m-5">
            <ChangePasswordFormFieldsContainer
              setChangePassword={setChangePassword}
              changePasswordUserID={changePasswordUserID}
              setChangePasswordUserID={setChangePasswordUserID}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <OffCanvasLayout
          centered={false}
          show={showform}
          handleClose={() => {
            setShowform(false);
          }}
          backdrop="static"
          title={editItem ? "Edit Currency Rate" : "Add Currency Rate"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <CurrencyAddForm setShowform={setShowform} refetch={refetch} />
        </OffCanvasLayout>

        <OffCanvasLayout
          centered={false}
          show={filterForm}
          handleClose={() => {
            setFilterForm(false);
          }}
          backdrop="static"
          title={"Apply Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterForm} refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default CCRateList;

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getMenuPermissions,
  updateConfig,
} from "../../../store/slices/Global";
import { useEffect } from "react";

const useProtectRoute = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.global);
  const getMenus = async () => {
    const dashboardMenus = JSON.parse(
      localStorage.getItem("dashboard_permissions")
    );


    if (dashboardMenus) {
      dispatch(
        updateConfig((state) => {
          state.dashboard_permissions = dashboardMenus;
          state.dashboard_permissions_status = "fulfilled";
        })
      );
    } else {
      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }
  };

  useEffect(() => {
    getMenus();
    // eslint-disable-next-line
  }, []);
  return {
    state,
  };
};

export default useProtectRoute;

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  createLead,
  getAllFranchise,
  updateLeadData,
} from "../../../../store/slices/Leads/LeadsSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAddLeadForm = (setShowForm, refetch, lead) => {
  const [franchiseOptions, setFranchise] = useState([]);
  const { singleLeadInfo } = useSelector((state) => state.leads);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllFranchise())
      .unwrap()
      .then((result) => {
        setFranchise(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    customer_lead_type: Yup.string().required("Lead Type Required"),
    customer_name: Yup.string().required("Customer Name is Required"),
    customer_phone: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile Number must be a 10-digit number")
      .matches(/^[0-9]*$/, "Mobile Number must contain only numbers"),
    customer_address: Yup.string().required("Address is Required"),
    customer_email: Yup.string()
      .email("Please enter a valid email")
      .test("email", "Invalid email format", function (value) {
        // Custom email validation using regular expression
        // Check for valid email format only if the value is not empty
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
        return !value || emailRegex.test(value);
      }),
    customer_message: Yup.string().required("Message is Required"),
    // franchise_id: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      customer_lead_type: singleLeadInfo?.customer_lead_type_id || "",
      customer_name: singleLeadInfo?.customer_name || "",
      customer_phone: singleLeadInfo?.customer_phone || "",
      customer_address: singleLeadInfo?.customer_address || "",
      customer_email: singleLeadInfo?.customer_email || "",
      customer_message: singleLeadInfo?.customer_message || "",
      // franchise_id: singleLeadInfo.franchise_id ?? "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const leadData = {
        customer_lead_type: values.customer_lead_type,
        customer_name: values.customer_name,
        customer_phone: values.customer_phone,
        customer_address: values.customer_address,
        customer_message: values.customer_message,
        // franchise_id: values.franchise_id,
      };

      if (values.customer_email != "") {
        leadData.customer_email = values.customer_email;
      }

      const message =
        Object.keys(singleLeadInfo).length > 0
          ? "Lead has been updated successfully"
          : "New lead has been added successfully";

      const action =
        Object.keys(singleLeadInfo).length > 0
          ? updateLeadData({ id: singleLeadInfo.id, data: leadData })
          : createLead(leadData);
      await dispatch(action)
        .unwrap()
        .then((result) => {
          if (result?.success) {
            setIsLoading(false);
            setShowForm(false);
            refetch();
            toast.success(message);
          } else {
            setIsLoading(false);
            toast.error("Failed to submit lead");
          }
        })
        .catch((err) => {});
    } catch (error) {
      toast.error("Failed to submit lead");
    }
  };

  const handleFranchiseChange = (selectedOption) => {
    // formik.setFieldValue("franchise_id", selectedOption);
  };

 

  return {
    formik,
    handleFranchiseChange,
    isLoading,
    franchiseOptions,
  };
};

export default useAddLeadForm;

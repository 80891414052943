import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const MonthYearPicker = () => {
  // Set the initial state to the current date (or any default date)
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Function to handle when the user selects a new date (month and year)
  const handleDateChange = (item) => {
    // The `item` parameter contains the start date and end date of the selected range
    // In this case, we only need the start date to get the selected month and year
    const { startDate } = item;

    // Update the selectedDate state with the start date (selected month and year)
    setSelectedDate(startDate);
  };

  return (
    <div>
      <h1>Choose a Month and Year</h1>
      <DateRangePicker
        ranges={[{ startDate: selectedDate, endDate: selectedDate }]} // Set the selected range with the same start and end date
        onChange={handleDateChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
      />
    </div>
  );
};

export default MonthYearPicker;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  isLoading:false,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  activeNationalities: [],
  visaTypes: [],
  customerDetails: {},
  editCustomer: false,
  branchLoding: false,
};

export const activeNationalities = createAsyncThunk(
  "customersRegForm/activeNationalities",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/nationality/active-nationalities");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVisaTypes = createAsyncThunk(
  "customersRegForm/getVisaTypes",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/visa-type`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllIDTypes = createAsyncThunk(
  "customersRegForm/getAllIDTypes",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/cid-type`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllStates = createAsyncThunk(
  "customersRegForm/getAllStates",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/state/active-states`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "customersRegForm/getAllCountries",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/country/active-countries`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllFranchise = createAsyncThunk(
  "customersRegForm/getAllFranchise",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/branches`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAllFranchises = createAsyncThunk(
  "customersRegForm/getAllFranchise",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/franchise`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBranches = createAsyncThunk(
  "customersRegForm/getAllBranches",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/branches`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getAllIsConfirmed = createAsyncThunk(
  "customersRegForm/getAllFranchise",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/is-confirmed`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewCustomer = createAsyncThunk(
  "customersRegForm/createNewCustomer",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/customer/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const  updateCustomer = createAsyncThunk(
  "customersRegForm/updateCustomer",
  async ({ id, params }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/customer/update/${id}`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "customersRegForm/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/customer/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const updateItemFromTableByID = createAsyncThunk(
  "customersRegForm/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const registerCustomersSlice = createSlice({
  name: "registerCustomers",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
    resetEditAction: (state, action) => {
      state.customerDetails = {};
      state.editCustomer = false;
    },
  },
  extraReducers: {
    [activeNationalities.pending]: (state, action) => {},
    [activeNationalities.fulfilled]: (state, action) => {
      state.activeNationalities = action.payload.data.data;
    },
    [activeNationalities.rejected]: (state, action) => {},

    [getVisaTypes.pending]: (state, action) => {},
    [getVisaTypes.fulfilled]: (state, action) => {
      state.visaTypes = action.payload.data.data;
    },
    [getVisaTypes.rejected]: (state, action) => {},

    [getAllIDTypes.pending]: (state, action) => {},
    [getAllIDTypes.fulfilled]: (state, action) => {
      state.IDTypes = action.payload.data.data;
    },
    [getAllIDTypes.rejected]: (state, action) => {},

    [getAllStates.pending]: (state, action) => {},
    [getAllStates.fulfilled]: (state, action) => {
      state.allStates = action.payload.data.data;
    },
    [getAllStates.rejected]: (state, action) => {},

    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.customerDetails = action.payload.data.data;
      state.editCustomer = true;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},


    [createNewCustomer.pending]: (state, action) => {
      state.isLoading=true
    },
    [createNewCustomer.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [createNewCustomer.rejected]: (state, action) => {
      state.isLoading=false
    },
    [updateCustomer.pending]: (state, action) => {
      state.isLoading=true
    },
    [updateCustomer.fulfilled]: (state, action) => {
      state.isLoading=false
    },
    [updateCustomer.rejected]: (state, action) => {
      state.isLoading=false
    },
    [getAllBranches.pending]: (state, action) => {
      state.branchLoding = true;
    },
    [getAllBranches.fulfilled]: (state, action) => {
      state.branchLoding = false;
    },
    [getAllBranches.rejected]: (state, action) => {},
   
  },
});

export const { updateConfig, setEditTestimonail, resetEditAction } =
  registerCustomersSlice.actions;

export default registerCustomersSlice.reducer;

import React, { useEffect, useState } from "react";
import Style from "../purchaseForm.module.scss"; // Import the CSS file for styling
import { useSelector } from "react-redux";

const TransactionDetails = ({
  formik,
  total,
  customerDetails,
  customer = false,
}) => {
  const { calculatedAmount } = useSelector((state) => state.purchase);


  const filterMoney = (value) => {
    return value;
  };

  return (
    <div className="transaction-details">
      {customer ? (
        <>
          {" "}
          <h6 className={`pro-ttl pro-mb-4 ${Style.proTtl}`}>
            Customer Details
          </h6>
          <div className={Style.customer_table}>
            <table>
              <tbody>
                <tr>
                  <td className={`${Style.gray_text}`}>
                    <p className="pro-mb-0 pro-font-sm pro-fw-medium">Name</p>
                  </td>
                  <td>
                    <p className="pro-fw-medium pro-font-regular pro-mb-0">
                      {customerDetails?.full_name}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className={`${Style.gray_text}`}>
                    <p className="pro-mb-0 pro-font-sm pro-fw-medium">ID</p>
                  </td>
                  <td>
                    <p className="pro-fw-medium pro-font-regular pro-mb-0">
                      {customerDetails?.customer_code}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* <div className={`pro-mb-4 pro-pb-4 ${Style.customer_head}`}>
        <p className={`pro-mb-1 pro-fw-medium ${Style.cutsomer_title}`}>
          Name : <span>{customerDetails?.full_name}</span>
        </p>
        <p className={`pro-mb-0 pro-fw-medium ${Style.cutsomer_title}`}>
          ID : <span>{customerDetails?.customer_code}</span>
        </p>
      </div> */}

      <h6 className={`pro-ttl pro-mb-4 ${Style.proTtl}`}>
        Transaction Details
      </h6>
      <div className={`${Style.table_root}`}>
        <table className={`${Style.table}`}>
          <tbody>
            <tr>
              <td className={`${Style.gray_text}`}>
                <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                  Total Transaction amount
                </p>
              </td>
              <td>
                <p className="pro-fw-bold pro-font-regular pro-mb-0">
                  ₹{total}
                </p>
              </td>
            </tr>

            {!isNaN(calculatedAmount?.IGST) && (
              <tr>
                <td className={`${Style.gray_text}`}>
                  <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                    IGST amount
                  </p>
                </td>
                <td>
                  <p className="pro-fw-bold pro-font-regular pro-mb-0">
                    ₹
                    {calculatedAmount?.IGST === undefined
                      ? 0
                      : filterMoney(calculatedAmount?.IGST)}
                  </p>
                </td>
              </tr>
            )}
            {!isNaN(calculatedAmount?.SGST) && (
              <tr>
                <td className={`${Style.gray_text}`}>
                  <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                    SGST amount
                  </p>
                </td>
                <td>
                  <p className="pro-fw-bold pro-font-regular pro-mb-0">
                    ₹
                    {calculatedAmount?.SGST === undefined
                      ? 0
                      : filterMoney(calculatedAmount?.SGST)}
                  </p>
                </td>
              </tr>
            )}
            {!isNaN(calculatedAmount?.CGST) && (
              <tr>
                <td className={`${Style.gray_text}`}>
                  <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                    CGST amount
                  </p>
                </td>
                <td>
                  <p className="pro-fw-bold pro-font-regular pro-mb-0">
                    ₹
                    {calculatedAmount?.CGST === undefined
                      ? 0
                      : filterMoney(calculatedAmount?.CGST)}
                  </p>
                </td>
              </tr>
            )}
            {!isNaN(calculatedAmount?.UGST) && (
              <tr>
                <td className={`${Style.gray_text}`}>
                  <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                    UGST amount
                  </p>
                </td>
                <td>
                  <p className="pro-fw-bold pro-font-regular pro-mb-0">
                    ₹
                    {calculatedAmount?.UGST === undefined
                      ? 0
                      : filterMoney(calculatedAmount?.UGST)}
                  </p>
                </td>
              </tr>
            )}
            <tr>
              <td className={`${Style.gray_text}`}>
                <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                  Amount to customer
                </p>
              </td>
              <td>
                <p className="pro-fw-bold pro-font-regular pro-mb-0">
                  ₹
                  {calculatedAmount?.amount_to_customer === undefined
                    ? 0
                    : filterMoney(calculatedAmount?.amount_to_customer)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionDetails;

import React from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useCurrencyRate from "./useCurrencyRate";

const CurrencyRate = () => {
  const { drawerMenu } = useCurrencyRate();
  return (
    <CommonLayout drawerMenu={drawerMenu} >
      <Outlet />
    </CommonLayout>
  );
};

export default CurrencyRate;

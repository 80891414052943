import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  isLoading: false,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  customerDetails: {},
  editCustomer: false,
};

export const getCurrency = createAsyncThunk(
  "CurrencyRate/getCurrency",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/currency/active-currency`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBranches = createAsyncThunk(
  "CurrencyRate/getAllBranches",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/branches`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewCurrency = createAsyncThunk(
  "CurrencyRate/addNewStore",
  async (storeData, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/branch-currency-rate/store`,
        storeData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCurrencyRate = createAsyncThunk(
  "CurrencyRate/updateUser",
  async ({ id, formData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/branch-currency-rate/update/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addNewUserSlice = createSlice({
  name: "CurrencyRate",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [addNewCurrency.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addNewCurrency.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addNewCurrency.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [updateCurrencyRate.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateCurrencyRate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateCurrencyRate.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { updateConfig } = addNewUserSlice.actions;

export default addNewUserSlice.reducer;

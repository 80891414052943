import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  isLoading: false,
  error: null,
  currentPage: 1,
  currentPageSize: 10,
  search: "",
  sortBy: "id",
  sortOrder: "",
  data: [],
  franchise: "", // New variable for franchise ID
  startDate: "", // New variable for start date
  endDate: "", 
  showCreateModal: false,
  showEditModal: false,
  singleLeadInfo: "",
  lead_type_id: ""
};

export const fetchLeads = createAsyncThunk(
  "leads/fetchLeads",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/leads");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createLead = createAsyncThunk(
  "leads/createLead",
  async (leadData, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/lead-data/store", leadData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLead = createAsyncThunk(
  "leads/deleteLead",
  async (leadId, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(`/leads/${leadId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "leads/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/lead-data/delete/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "leads/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/lead-data/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLeadData = createAsyncThunk(
  "leads/updateLeadData",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/lead-data/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllFranchise = createAsyncThunk(
  "leads/getAllFranchise",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/franchise`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllLeads = createAsyncThunk(
  "listing/lead_type",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/listing/lead_type`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    
    setStoreFormValues: (state, action) => {
      state.franchise = action.payload.franchise;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.lead_type_id = action.payload.lead_type_id
     
    },
    resetExtraFilters: (state, action) => {
      state.franchise= "";
      state.startDate = "";
      state.endDate = "";
      state.lead_type_id = "";
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createLead.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.push(action.payload);
      })
      .addCase(createLead.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteLead.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.filter((lead) => lead.id !== action.payload.id);
      })
      .addCase(deleteLead.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editItemFromTableByID.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editItemFromTableByID.fulfilled, (state, action) => {
        state.singleLeadInfo = action.payload.data.data;
      })
      .addCase(editItemFromTableByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig,setStoreFormValues,resetExtraFilters } = leadsSlice.actions;

export default leadsSlice.reducer;

import { Header, MenuDrawer } from "@wac-ui-dashboard/wac_component_library";
import { Nav } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Assets from "../../../assets/Assets";
import HeaderActions from "./HeaderActions";
import useCommonLayout from "./useCommonLayout";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import Style from "./commonLayout.module.scss";
import CustomerProfile from "../../../components/Global/CustomerProfile";
import SurrenderDetail from "../../../components/Global/SurrenderDetail";
import PurchaseDetail from "../../../components/Global/PurchaseDetail";

const CommonLayout = ({ children, drawerMenu, dashboard = false, className }) => {
  const { navigations, menuDrawer, handleGoBack, checkIsDetailPage, globalState } = useCommonLayout();
  let { customersID, surrenderID, purchaseID } = useParams();
  
  
  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={Assets.HEADERLOGO}
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className={`pro-w-100 container-fluid pt-custom ${className} ${checkIsDetailPage ? `detail-page` : ''}`}>
        <div className={`row`}>
          {!dashboard ? (
            <div
              className={`col-auto wrapper pro-ps-0 ${Style.MenuDrawer_container}`}
            >
              {customersID != undefined  && purchaseID == undefined ? (
                <div className={Style.profile_container}>
                  <CustomerProfile />
                </div>
              ):customersID != undefined && purchaseID != undefined ? (
                <div className={Style.profile_container}>
                  <PurchaseDetail />
                </div>) : surrenderID != undefined ? (
                <div className={Style.profile_container}>
                  <SurrenderDetail />
                </div>
              ) : purchaseID != undefined ? (
                <div className={Style.profile_container}>
                  <PurchaseDetail />
                </div>
              ) : (
                <MenuDrawer
                  menus={drawerMenu}
                  Link={Link}
                  type={`type-2`}
                  Nav={Nav}
                  icon={
                    <span className="material-symbols-outlined ">
                      chevron_right
                    </span>
                  }
                  isGoBack={menuDrawer?.show}
                  goBackTitle={menuDrawer?.text}
                  goBackIcon={
                    <span className="material-symbols-outlined">
                      chevron_left
                    </span>
                  }
                  handleGoBack={handleGoBack}
                />
              )}
            </div>
          ) : (
            <></>
          )}
          <div className={`wrapper-table pro-pt-5 pro-pe-5 pro-ps-2`}>
            {children}
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CommonLayout;

// CDFDetails.js
import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const CDFDetails = ({ formik, paymentModeOptions, total,isCDFrequired }) => {
  const { calculatedAmount } = useSelector((state) => state.purchase);

  const filterMoney = (value) => {
    return value?.toFixed(2);
  };

  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          CDF Number {isCDFrequired ? "*" : ""}
        </label>
        <input
          type="text"
          className={`pro-input lg ${formik.errors.cdfNumber && "error"}`}
          value={formik.values.cdfNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="cdfNumber"
        />
        {formik.errors.cdfNumber && (
          <span className="error-text">{formik.errors.cdfNumber}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Amount Declared in CDF {isCDFrequired ? "*" : ""}
        </label>
        <input
          type="number"
          className={`pro-input lg ${formik.errors.amountDeclared && "error"}`}
          value={formik.values.amountDeclared}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="amountDeclared"
        />
        {formik.errors.amountDeclared && (
          <span className="error-text">{formik.errors.amountDeclared}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of CDF {isCDFrequired ? "*" : ""}
        </label>
        <input
          type="date"
          className={`pro-input lg ${formik.errors.cdf_date && "error"}`}
          value={formik.values.cdf_date}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="cdf_date"
        />
        {formik.errors.cdf_date && (
          <span className="error-text">{formik.errors.cdf_date}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Customs Office Place {isCDFrequired ? "*" : ""}
        </label>
        <input
          type="text"
          className={`pro-input lg ${
            formik.errors.customsOfficePlace && "error"
          }`}
          value={formik.values.customsOfficePlace}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="customsOfficePlace"
        />
        {formik.errors.customsOfficePlace && (
          <span className="error-text">{formik.errors.customsOfficePlace}</span>
        )}
      </div>
      <h6 className="pro-ttl pro-mb-4 pro-pt-3">Payment details</h6>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Payment Mode *
        </label>
        <Select
          className={`pro-input lg ${
            formik.errors.paymentMode && formik.touched.paymentMode && "error"
          }`}
          value={formik.values.paymentMode}
          onChange={(selectedOption) =>
            formik.setFieldValue("paymentMode", selectedOption)
          }
          classNamePrefix={`pro-input`}
          onBlur={formik.handleBlur}
          name="paymentMode"
          options={paymentModeOptions}
        />
        {formik.errors.paymentMode && formik.touched.paymentMode && (
          <span className="error-text">
            {formik.errors.paymentMode && formik.touched.paymentMode}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Payout amount
        </label>
        <input
          type="text"
          readOnly
          className={`pro-input lg ${formik.errors.payoutAmount && "error"}`}
          value={
            calculatedAmount?.amount_to_customer === undefined
              ? `₹0`
              : `₹${filterMoney(calculatedAmount?.amount_to_customer)}`
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="payoutAmount"
        />
  

        {formik.errors.payoutAmount && (
          <span className="error-text">{formik.errors.payoutAmount}</span>
        )}
      </div>
    </>
  );
};

export default CDFDetails;

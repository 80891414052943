import { useDispatch, useSelector } from "react-redux";
import {
  useGetRlm3ReportDataQuery,
  useUpdateRlm3ReportDataMutation,
} from "../../../store/queries/PurchaseRegisterReport";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  getFranchiseById,
  getListData,
  updateConfig,
} from "../../../store/slices/Reports/purchaseRegisterReportSlice";
import {
  getFormatedDate,
  getRevFormatedDate,
} from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { getAllBranches} from "../../../store/slices/customers/registerCustomersSlice";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { toast } from "react-toastify";

const usePurchaseRegisterReport = () => {
  const dispatch = useDispatch();

  const purchaseRegisterReport = useSelector(
    (state) => state.purchaseRegisterReport
  );

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  // const [franchiseiesOptions, setFranchise] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [branchesOptions, setBranches] = useState([]);
  const [franchiseOptions, setFranchise] = useState([]);
  const printRef = useRef(null);

  let user_type = localStorage.getItem("user_role");
  let user_data = localStorage.getItem("login_data");
  let branch_id = localStorage.getItem("branch_data");

  const [date, setDate] = useState({
    startDate: purchaseRegisterReport.startDate,
    endDate: purchaseRegisterReport.endDate,
    key: "selection",
  });

  useEffect(() => {
    
    dispatch(getAllBranches())
      .unwrap()
      .then((result) => {
        
        if (user_type == 3) {
          setBranches(
            result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          );
        } 
        else if(user_type == 2) {
          setSeleoption(branch_id);
        }
        else {
          setBranches([
            ...[
              {
                label: "All",
                value: "",
              },
            ],
            ...result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]);
          dispatch(
            updateConfig((state) => {
              state.selectedBranches = {
                label: "All",
                value: "",
              };
            })
          );
        }
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
        if (user_type == 3) {
          if(user_data) {
            let selectedBranchOPtion = branchesOptions.find((item) => (item.value == user_data));
            if(selectedBranchOPtion) {
              dispatch(
                updateConfig((state) => {
                  state.selectedBranches = selectedBranchOPtion
                })
              );
              setSeleoption(user_data);
            }
          }
        } 
  }, [branchesOptions]);

  useEffect(() => {    
    if(user_type == 2) {
      if(user_data) {
        let selectedFranchise = franchiseOptions.find((item) => (item.value == user_data));
        dispatch(
          updateConfig((state) => {
            state.selectedFranchise = selectedFranchise
          })
          )
      }
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [franchiseOptions]);

  

  const formatDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = "00";
    const minutes = "00";
    const seconds = "00";
    return `${year}-${month}-${day} ${getCurrentTimeIn24HourFormat(date)}`;
  };

  const getMonthInText = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = date.getMonth();
    return months[monthIndex];
  };

  const getCurrentTimeIn24HourFormat = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  // useEffect(() => {
  //   // Get the current date
  //   const currentDate = new Date();

  //   // Get the current month's start date
  //   const currentMonthStartDate = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     1
  //   );

  //   // Format the dates in the given format (YYYY-MM-DD 00:00:00)

  //   const formattedCurrentMonthStartDate = formatDateString(
  //     currentMonthStartDate
  //   );
  //   const formattedCurrentDate = formatDateString(currentDate);

  //   dispatch(
  //     updateConfig((state) => {
  //       state.currentPage = 1;
  //       state.startDate = formattedCurrentMonthStartDate;
  //       state.endDate = formattedCurrentDate;
  //     })
  //   );

  //   setSelectedMonth(getMonthInText(currentMonthStartDate));
  // }, []);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (purchaseRegisterReport.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateRlm3ReportDataMutation();
  const [skip, setSkip] = useState(true);

  const {
    data: rlm3ReportList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetRlm3ReportDataQuery({
    page_size: purchaseRegisterReport.currentPageSize,
    page: purchaseRegisterReport.currentPage,
    sort_by: purchaseRegisterReport.sortBy,
    sort_order: purchaseRegisterReport.sortOrder,
    start_date:
      getRevFormatedDate(purchaseRegisterReport.startDate) + " 00:00:00",
    end_date:
      getRevFormatedDate(purchaseRegisterReport.endDate) +
      ` 23:59:59`,

    search: purchaseRegisterReport.search,
    franchise_id: purchaseRegisterReport.selectedFranchise,
    branch_id: purchaseRegisterReport?.selectedBranches,
  }, {skip: skip});


	useEffect(() => {
		const selectedFranchise = purchaseRegisterReport?.selectedFranchise;
		const selectedBranch = purchaseRegisterReport?.selectedBranches;

		if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type == 2) {
			setSkip(false);
		}
		else if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type != 2) {
			setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchaseRegisterReport?.selectedFranchise]);

	useEffect(() => {
		const selectedBranch = purchaseRegisterReport.selectedBranches;
		if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value && (user_type == 3 || user_type != 2)) {
			setSkip(false);
		}
		else if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value == "" && (user_type != 3 || user_type != 2)) {
			setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purchaseRegisterReport?.selectedBranches]);

  const handleDownloadClick = () => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [0, 0, 10, 0],
      filename: `${
        "000" + "_" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 800,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  // const handleActionSetFranchiseies = (franchise) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedFranchise = franchise;
  //     })
  //   );
  // };

  const hasCreatePermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.length ?? permission?.[0]?.can_create;
  }, [rlm3ReportList]);

  const hasViewPermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.length ?? permission?.[0]?.can_view;
  }, [rlm3ReportList]);

  const hasEditPermission = useMemo(() => {
    let permission = rlm3ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.length ?? permission?.[0]?.can_edit;
  }, [rlm3ReportList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const convertDateFormat = (inputDateString) => {
    // Create a new Date object with the input date string
    var inputDate = new Date(inputDateString);

    // Extract the year, month, and day from the Date object
    var year = inputDate.getFullYear();
    var month = String(inputDate.getMonth() + 1).padStart(2, "0");
    var day = String(inputDate.getDate()).padStart(2, "0");

    // Create the desired output date string
    var outputDateString = year + "-" + month + "-" + day + " 00:00:00";

    return outputDateString;
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = convertDateFormat(range.startDate);
        state.endDate = convertDateFormat(range.endDate);
      })
    );
  };

  const handlePrintClick = () => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [15, 0, 10, 0],
      filename: `${
        "000" + "_" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 1350,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ testimonial_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            // refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          handleUnknownError(setShowDeleteConfirm);
        });
    } else {
      setShowDeleteConfirm(false);
      handleUnknownError(setShowDeleteConfirm);
    }
  };
  const handleSort = (label) => {
    if (purchaseRegisterReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            purchaseRegisterReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleUnknownError = () => {
    alert("Sorry something went wrong!");
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      invoice_no: (feild, data) => {
        return <p className="pro-mb-0">{data?.purchase?.invoice_no}</p>;
      },
      customer_name: (feild, data) => {
        return <p className="pro-mb-0">{data?.purchase?.customer_name}</p>;
      },
      address: (feild, data) => {
        return <p className="pro-mb-0">{data?.customer?.address}</p>;
      },
      id_type_name: (feild, data) => {
        return <p className="pro-mb-0">{data?.cid?.id_type_name}</p>;
      },
      id_number: (feild, data) => {
        return <p className="pro-mb-0">{data?.cid?.id_number}</p>;
      },
      code: (feild, data) => {
        return <p className="pro-mb-0">{data?.currency?.code}</p>;
      },
      payment_mode: (feild, data) => {
        return (
          <p className="pro-mb-0">
            {data?.purchase?.payment_mode?.payment_mode}
          </p>
        );
      },
      full_name: (feild, data) => {
        return <p className="pro-mb-0">{data?.customer?.full_name}</p>;
      },
      slno: (feild, data) => {
        return <p className="pro-mb-0">{data?.sl_no}</p>;
      },
      created_at: (feild, data) => {
        const date = new Date(data?.created_at);
        const niceDateString = date.toLocaleDateString();

        return <p className="pro-mb-0">{niceDateString}</p>;
      },
      inr_volume: (feild, data) => {
        return <p className="pro-mb-0">₹{data?.inr_volume}</p>;
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };


  const handleActionSetFranchiseies = (franchise) => {
    // setSeleoption(franchise);
    dispatch(
      updateConfig((state) => {
        state.selectedBranches = franchise;
      })
    );
    if(franchise?.value) {
      setSeleoption(franchise?.value);
    }
    else {
      setFranchise([]);
      dispatch(
        updateConfig((state) => {
          state.selectedFranchise = "";
        })
      );
    }
  };

  const setSeleoption = (id) => {
    dispatch(getFranchiseById(id))
    .unwrap()
    .then((result) => {
      if (result?.data?.message === "Success") {
        if(user_type == 2) {
          let temp = result?.data?.data?.filter((item) => item.id == user_data)
          setFranchise(
            temp?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
        else {
          setFranchise(
            result?.data?.data?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
      } else {
        toast.error(result?.data?.message);
      }
      
    });
  }

  const handleActionSetFranchiseById = (data) => {
    dispatch(
      updateConfig((state) => {
        state.selectedFranchise = data;
      })
    );
  };

  return {
    date,
    rlm3ReportList,
    purchaseRegisterReport,
    currentPage: purchaseRegisterReport.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handleDateRangeChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    // refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    handleDownloadClick,
    handleActionSetFranchiseies,
    printRef,
    handlePrintClick,
    selectedMonth,
    user_type,
    franchiseOptions,
    handleActionSetFranchiseById,
    branchesOptions
  };
};

export default usePurchaseRegisterReport;

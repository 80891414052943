import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { editItemFromTableByID } from "../../../store/slices/customers/customerDetailsPageSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import styles from "./customers.module.scss";
import {
  useGetPurchasesListDataQuery,
  useUpdatePurchasesListDataMutation,
} from "../../../store/queries/purchases";
import {
  deleteItemFromTable,
  updateConfig,
} from "../../../store/slices/Purchases/purchasesSlice";
import { Link } from "react-router-dom";

const usePurchaseList = () => {
  const dispatch = useDispatch();

  const customerDetailsPage = useSelector((state) => state.customerDetailsPage);

  const purchaseDetailsPage = useSelector((state) => state.purchasesList);
  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [isFilterShow, setIsFilterShow] = useState(false);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  useEffect(() => {
    if (customerDetailsPage.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updatePurchasesFields] = useUpdatePurchasesListDataMutation();

  const {
    data: purchaseList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPurchasesListDataQuery({
    page_size: purchaseDetailsPage.currentPageSize,
    page: purchaseDetailsPage.currentPage,
    sort_by: purchaseDetailsPage.sortBy,
    franchise_id: purchaseDetailsPage.franchiseId.value,
    is_confirmed: purchaseDetailsPage.isConfirmedId.value?.toString(),
    sort_order: purchaseDetailsPage.sortOrder,
    start_date: purchaseDetailsPage.startDate
      ? purchaseDetailsPage.startDate + " 00:00:00"
      : "",
    end_date: purchaseDetailsPage.endDate
      ? purchaseDetailsPage.endDate + " 00:00:00"
      : "",
    search: purchaseDetailsPage.search,
  });



  const hasCreatePermission = useMemo(() => {
    let permission = purchaseList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.length ?? permission?.[0]?.can_create;
  }, [purchaseList]);

  const hasViewPermission = useMemo(() => {
    let permission = purchaseList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.length ?? permission?.[0]?.can_view;
  }, [purchaseList]);

  const hasEditPermission = useMemo(() => {
    let permission = purchaseList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.length ?? permission?.[0]?.can_edit;
  }, [purchaseList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
          handleUnknownError(setShowDeleteConfirm);
        });
    } else {
      setShowDeleteConfirm(false);
      handleUnknownError(setShowDeleteConfirm);
    }
  };
  const handleSort = (label) => {
    if (purchaseDetailsPage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            purchaseDetailsPage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleUnknownError = () => {
    alert("Sorry something went wrong!");
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      invoice_no: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" ? (
            <Link to={`detail-purchase/${data["id"]}`} className="link-primary">
              {data[feild]}
            </Link>
          ) : data.is_confirmed === "Cancelled" ? (
            <Link to={`detail-purchase/${data["id"]}`} className="pro-link-danger">
              {data[feild]}
            </Link>
          ) : data.is_confirmed === "Not Confirmed" ? (
            <Link to={`detail-purchase/${data["id"]}`} className="pro-link-warning">
              {data[feild]}
            </Link>
          ) : (
            <Link to={`detail-purchase/${data["id"]}`}>{data[feild]}</Link>
          )}
        </p>
      ),
      id: (feild, data) => (
        <p className="pro-mb-0">
          {data.is_confirmed === "Confirmed" ? (
            <Link to={`detail-purchase/${data["id"]}`} className="link-primary">
              {data[feild]}
            </Link>
          ) : (
            <Link to={`detail-purchase/${data["id"]}`}>{data[feild]}</Link>
          )}
        </p>
      ),
      is_confirmed: (feild, data) => (
        <p className={`pro-mb-0 pro-text-center`}>
          {data.is_confirmed === "Confirmed" ? (
            <span
              className={`${styles.conform_pill} ${styles.green} ${styles.purcahsePill}`}
            >
              {data[feild]}
            </span>
          ) : data.is_confirmed === "Cancellation Requested" ? (
            <span className={`${styles.conform_pill} ${styles.blue}`}>
              {data[feild]}
            </span>
          )  : data.is_confirmed === "Not Confirmed" ? (
            <span className={`${styles.conform_pill} ${styles.orange}`}>
              {data[feild]}
            </span>
          ) : (
            <span
              className={`${styles.conform_pill} ${styles.red} ${styles.purcahsePill}`}
            >
              {data[feild]}
            </span>
          )}
        </p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    purchaseList,
    customerDetailsPage,
    purchaseDetailsPage,
    currentPage: purchaseDetailsPage.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    setIsFilterShow,
    isFilterShow,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updatePurchasesFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
  };
};

export default usePurchaseList;

import React from "react";
import PropTypes from "prop-types";
import styles from "./customerProfile.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ProfileCardShimmer from "../Shimmers/ProfileCardShimmer";
import CustomerProfileShimmer from "../Shimmers/CustomerProfileShimmer";

const CustomerProfile = () => {
  const customerDetailsPage = useSelector((state) => state.customerDetailsPage);

  const removeTime = (dateString) => {
    if (typeof dateString === "undefined") {
      return ""; // Return an empty string or any appropriate default value
    }

    return dateString?.split(" ")[0];
  };

  return customerDetailsPage.customerDetailsLoading === "loading" ? (
    <div className={`${styles.root}   pro-d-flex pro-flex-column`}>
      <CustomerProfileShimmer />
    </div>
  ) : (
    
    <div className={`${styles.root} pro-d-flex pro-flex-column`}>
      <div className={`${styles.profile_top} pro-pb-2`}>
        <div className="pro-w-100 pro-py-5">
          <h4 className="pro-ttl pro-mb-1">
            {customerDetailsPage?.customerDetails?.full_name}
          </h4>
          <p className="pro-mb-0">
            {customerDetailsPage?.customerDetails?.customer_code}
          </p>
        </div>
      </div>
      <div className={`${styles.table_wrap} pro-py-5`}>
        <div className="pro-pb-5">
          <h6 className="pro-mb-3 pro-ttl">Details</h6>
          <table className={styles.table}>
            <tbody>
              {customerDetailsPage?.customerDetails?.phone_number && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Mobile</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.phone_number}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.email && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Email</p>
                </td>
                <td>
                  <p className="pro-mb-0">
                    <a
                      href={`mailto:${customerDetailsPage?.customerDetails?.email}`}
                    >
                      {customerDetailsPage?.customerDetails?.email}
                    </a>
                  </p>
                </td>
              </tr>
              )}
              
              {customerDetailsPage?.customerDetails?.dob && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">DOB</p>
                </td>
                <td>
                  <p className="pro-mb-0">{removeTime(customerDetailsPage?.customerDetails?.dob)}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.address && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Address</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.address}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.permanent_address && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Permenent Address</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.permanent_address}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.city && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">City</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.city}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.state?.name && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">State</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.state.name}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.country?.name && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Country</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.country.name}</p>
                </td>
              </tr>
              )}

              {customerDetailsPage?.customerDetails?.nationality?.name && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Nationality</p>
                </td>
                <td>
                  <p className="pro-mb-0">{customerDetailsPage?.customerDetails?.nationality.name}</p>
                </td>
              </tr>
              )}
              {customerDetailsPage?.customerDetails?.visa_details?.id_type && (
                <tr>
                <td>
                  <p className="pro-font-sm pro-fw-medium pro-mb-0">Residence</p>
                </td>
                <td>
                  <p className="pro-mb-0">
                    {customerDetailsPage?.customerDetails?.visa_details.id_type}
                  </p>
                </td>
              </tr>
              )}
              
            </tbody>
          </table>
        </div>

        {customerDetailsPage?.customerDetails?.visa_details?.visa_number && (
          <div className="pro-pb-5">
            <h6 className="pro-mb-3 pro-ttl">Visa Details</h6>
            <table className={styles.table}>
              <tbody>
                {customerDetailsPage?.customerDetails?.visa_details?.visa_number && (
                  <tr>
                  <td>
                    <p className="pro-font-sm pro-fw-medium pro-mb-0">Visa Number</p>
                  </td>
                  <td>
                    <p className="pro-mb-0">
                      {
                        customerDetailsPage?.customerDetails?.visa_details
                          .visa_number
                      }
                    </p>
                  </td>
                </tr>
                )}
                {customerDetailsPage?.customerDetails?.visa_details?.issued_at && (
                  <tr>
                  <td>
                    <p className="pro-font-sm pro-fw-medium pro-mb-0">Issued At</p>
                  </td>
                  <td>
                    <p className="pro-mb-0">
                      {removeTime(
                        customerDetailsPage?.customerDetails?.visa_details
                          .issued_at
                      )}
                    </p>
                  </td>
                </tr>
                )}
                {customerDetailsPage?.customerDetails?.visa_details?.issued_date && (
                  <tr>
                  <td>
                    <p className="pro-font-sm pro-fw-medium pro-mb-0">Issued Date</p>
                  </td>
                  <td>
                    <p className="pro-mb-0">
                      {removeTime(
                        customerDetailsPage?.customerDetails?.visa_details
                          .issued_date
                      )}
                    </p>
                  </td>
                </tr>
                )}
                {customerDetailsPage?.customerDetails?.visa_details?.expiry_date && (
                  <tr>
                  <td>
                    <p className="pro-font-sm pro-fw-medium pro-mb-0">Expiry Date</p>
                  </td>
                  <td>
                    <p className="pro-mb-0">
                      {removeTime(
                        customerDetailsPage?.customerDetails?.visa_details
                          .expiry_date
                      )}
                    </p>
                  </td>
                </tr>
                )}
                
              </tbody>
            </table>
          </div>
        )}

        {customerDetailsPage?.customerDetails?.identities?.length > 0 && (
          <div>
            <h6 className="pro-mb-3 pro-ttl">ID Details</h6>
            <div className={styles.repeat_details}>
              <table className={styles.table}>
                <tbody>
                  {customerDetailsPage?.customerDetails?.identities.map(
                    (identity) => (
                      <>
                      {identity?.id_type_name && (
                        <tr key={identity.id}>
                          <td>
                            <p className="pro-font-sm pro-fw-medium pro-mb-0">ID Type</p>
                          </td>
                          <td>
                            <p className="pro-mb-0">{identity.id_type_name}</p>
                          </td>
                        </tr>
                      )}
                        {identity?.id_number && (
                          <tr>
                          <td>
                            <p className="pro-font-sm pro-fw-medium pro-mb-0">ID Number</p>
                          </td>
                          <td>
                            <p className="pro-mb-0">{identity.id_number}</p>
                          </td>
                        </tr>
                        )}
                        {identity?.issue_date && (
                          <tr>
                          <td>
                            <p className="pro-font-sm pro-fw-medium pro-mb-0">
                              Issue Date
                            </p>
                          </td>
                          <td>
                            <p className="pro-mb-0">{removeTime(identity.issue_date)}</p>
                          </td>
                        </tr>
                        )}
                        {identity?.expiry_date && (
                          <tr>
                          <td>
                            <p className="pro-font-sm pro-fw-medium pro-mb-0">
                              Expiry Date
                            </p>
                          </td>
                          <td>
                            <p className="pro-mb-0">{removeTime(identity.expiry_date)}</p>
                          </td>
                        </tr>
                        )}
                        
                      </>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerProfile;

import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../../store/slices/Settings/faqsSlice";


import { updatePasswordByUserID } from "../../../../../store/slices/Users/adminUsersSlice";

const useChangePassword = ({
  setChangePassword,
  refetch,
  changePasswordUserID,
  setChangePasswordUserID,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .required("New password is required")
      .min(6, "New password must be at least 6 characters long"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    setLoading(true);
    const { new_password, confirm_password } = values;

    // Perform additional validation or checks if needed
    if (new_password !== confirm_password) {
      setFieldError("confirm_password", "Passwords must match");
      return;
    }

    const formData = {
      userId: changePasswordUserID.id,
      postData: {
        password: confirm_password,
      },
    };

    // Dispatch action and handle success/error
    dispatch(updatePasswordByUserID(formData))
      .unwrap()
      .then((data) => {
        if (data.success) {
          resetForm();
          toast.success("Password updated successfully.");
          setChangePasswordUserID("");
          setChangePassword(false);
          setLoading(false);
          refetch();
        } else {
          resetForm();
          toast.success("Something went wrong!");
          setChangePasswordUserID("");
          setLoading(false);
          refetch();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    setLoading,
    formik,
    loading,
    handleCloseModal,
  };
};

export default useChangePassword;

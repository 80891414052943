import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  filter: {
    currency: "",
    branch: "",
  },
};

export const deleteItemFromTable = createAsyncThunk(
  "users/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/branch-currency-rate/delete/${params.user_id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEdit: (state, action) => {
      state.editItem = action.payload;
    },
    setEditCurrencyItem: (state, action) => {
      state.selectedItemsDetails = action.payload;
    },
    setFilterValues: (state, action) => {
      if (action.payload === false) {
        state.filter.currency = "";
        state.filter.branch = "";
      } else {
        state.filter.currency = action.payload.values.currency;
        state.filter.branch = action.payload.values.branch;
      }
    },
  },
  extraReducers: {},
});

export const { updateConfig, setEdit, setFilterValues, setEditCurrencyItem } =
  currencySlice.actions;

export default currencySlice.reducer;

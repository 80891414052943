import React, { useState } from "react";
import useUserAddForm from "./useUserAddForm";

function UserAddForm({ setShowform, refetch }) {
  const { renderTabContent } = useUserAddForm(setShowform, refetch);

  return (
    <div>
      <form action={"javascript:void(0)"}>{renderTabContent()}</form>
    </div>
  );
}

export default UserAddForm;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetAdminUsersListDataQuery,
  useUpdateAdminUsersListDataMutation,
} from "../../../store/queries/admin";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
  updateUserStatus,
} from "../../../store/slices/Users/adminUsersSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useUsersList = () => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users);

  const {selectedItemsDetails} = users;

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [filterForm, setFilterForm] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordUserID, setChangePasswordUserID] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const options = [
    { value: "0", label: "Active" },
    { value: "1", label: "Inactive" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (users.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateUsersFields] = useUpdateAdminUsersListDataMutation();

  const {
    data: usersList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAdminUsersListDataQuery({
    length: users.currentPageSize,
    page: users.currentPage,
    sort_by: users.sortBy,
    sort_order: users.sortOrder,
    search: users.search,
    user_role: users?.userRole?.value,
    start_date: users.startDate
      ? users.startDate + " 00:00:00"
      : "",
    end_date: users.endDate
      ? users.endDate + " 00:00:00"
      : "",
  });

  const hasCreatePermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [usersList]);

  const hasDeletePermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [usersList]);

  const hasEditPermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [usersList]);



  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ user_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result?.data?.message === "Success") {
            toast.success(result?.data?.message);
          } else {
            toast.error(result?.data?.message);
          }
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleSort = (label) => {
    if (users.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = users.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ user_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const toggleStatusById = (selected, userID) => {
    const postData = {
      is_active: selected ? "0" : "1",
      rejection_remark: selected ? "" : "Deactivated",
    };
    dispatch(updateUserStatus({ postData: postData, userId: userID }))
      .unwrap()
      .then((result) => {
        refetch();
      })
      .catch((err) => {});
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      is_active: (field, data) => {
        // <p className="pro-mb-0">
        //   {" "}
        //   <Select
        //     // menuIsOpen={true}
        //     options={options}
        //     value={options[data["is_active"] === "Active" ? 0 : 1]}
        //     className="pro-input"
        //     classNamePrefix={`pro-input`}
        //     onChange={(selected) => {
        //       if (selected.label !== data["is_active"]) {
        //         handleChange(selected, data.id);
        //       }
        //     }}
        //   />
        // </p>
        const isChecked = data["is_active"] === "Active" ? 1 : 0;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={() => toggleStatusById(!(isChecked), data?.id)}
              />
              <span></span>
            </div>
            {/* <label>Status</label> */}
          </div>
        );
    },
      change_password: (field, data) => (
        <p className="pro-mb-0">
          {" "}
          <Button
            className={"pro-btn-primary lg pro-mx-3"}
            type="submit"
            onClick={() => {
              setChangePasswordUserID(data);
              setChangePassword(true);
            }}
          >
            {" "}
            Change Password
          </Button>
        </p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };


//   const toggleStatusById = (status, id) => {
//     let data = { "id": id, "status": status}
//     updateStatus(data);
// };
  return {
    usersList,
    users,
    selectedItemsDetails,
    currentPage: users.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateUsersFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    filterForm,
    setFilterForm,
    changePassword,
    setChangePassword,
    changePasswordUserID,
    setChangePasswordUserID,
  };
};

export default useUsersList;

import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  fetchSurrenderById,
  updateConfig,
} from "../../../store/slices/surrender/surrenderDetailsPageSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateConfig as updateGlobalConfig } from "../../../store/slices/Global";
import { current } from "@reduxjs/toolkit";
import html2pdf from "html2pdf.js";
import moment from "moment";

const useSurrenderDetailTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const printRef = useRef(null);
  let { surrenderID } = useParams();
  const selectedItemsDetails = useSelector(
    (state) => state.surrenderDetailsPage
  );
  useEffect(() => {
    dispatch(fetchSurrenderById(surrenderID));
    return () =>
      dispatch(updateConfig((state) => (state.selectedItemsDetails = "")));
  }, []);
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      currency: (feild, data) => <p className="pro-mb-0">{data[feild].name}</p>,
      inr_volume: (feild, data) => <p className="pro-mb-0">₹{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleLinkClick = (item) => {
    if (item === "Surrenders") {
      navigate(`/surrender`);
    }
  };

  const beautifyDate = (uglyDate) => {
    if (uglyDate != "" && uglyDate !== undefined) {
      return new Date(uglyDate)?.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  const handlePrintClick = (data) => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [2, 10],
      filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: {
        precision: 32,
        unit: "mm",
        format: "ledger",
        orientation: "landscape",
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  return {
    getRow,
    handleLinkClick,
    printRef,
    selectedItemsDetails,
    handlePrintClick,
    beautifyDate
  };
};

export default useSurrenderDetailTable;

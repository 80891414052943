import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useCurrencyRate = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Currency Rate",
      link: "/currency-rate",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/currency-rate", true),
    }
  ];
  // const drawerMenuPermission = useMemo(() => {
  //   let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
  //     ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
  //   ]);

  //   menus = ["Users"];

  //   return drawerMenu
  //     .filter((menu) => menus?.includes?.(menu.title))
  //     .map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);


  return {
    drawerMenu
  };
};

export default useCurrencyRate;

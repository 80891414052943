import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import {
  getAllCountries,
  getAllStates,
  getAllRoles,
} from "../../../../store/slices/Users/addNewUserSlice";



const BasicForm = ({ formik, handleResetFilter }) => {
  const dispatch = useDispatch();

  const [userRoleOptions, setUserRoleOptions] = useState([]);

  useEffect(() => {
    dispatch(getAllRoles())
      .unwrap()
      .then((result) => {
        setUserRoleOptions(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            User Role
          </label>
          <Select
            name="userRole"
            options={userRoleOptions} // replace with your userRole options
            onChange={(selectedOption) => {
              formik.setFieldValue("userRole", selectedOption);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.userRole}
            className={`pro-input lg ${
              formik.errors.userRole && formik.touched.userRole && "error"
            }`}
            classNamePrefix={`pro-input`}
          />
          {formik.touched.userRole && formik.errors.userRole && (
            <div className="error">{formik.errors.userRole}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.startDate && formik.touched.startDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <div className="error">{formik.errors.startDate}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            End Date
          </label>
          <input
            type="date"
            name="endDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.endDate && formik.touched.endDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            min={formik?.values?.startDate ? formik.values.startDate : ""}
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <div className="error">{formik.errors.endDate}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={() => {
            handleResetFilter();
          }}
        >
          Reset
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={false}
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

import React, { useEffect, useState } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import BasicForm from "./BasicForm";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  addNewStore,
  updateUser,
} from "../../../../store/slices/Users/addNewUserSlice";

const useUserAddForm = (setShowform, refetch) => {
  const dispatch = useDispatch();

  const { selectedItemsDetails } = useSelector((state) => state.users);
  const { isLoading } = useSelector((state) => state.newUser);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    label: selectedItemsDetails?.country?.name,
    value: selectedItemsDetails?.country?.id,
  });

  const [selectedState, setSelectedState] = useState({
    label: selectedItemsDetails?.state?.name,
    value: selectedItemsDetails?.state?.id,
  });

  const [selectedRole, setSelectedRole] = useState({
    label: selectedItemsDetails?.role?.name,
    value: selectedItemsDetails?.role?.id,
  });

  const [selectedBranch, setSelectedBranch] = useState({
    label: selectedItemsDetails?.branch?.name,
    value: selectedItemsDetails?.branch?.id,
  });

  let user_type = localStorage.getItem("user_role");
  let user_data = localStorage.getItem("login_data");

  const validationObject = {
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid Email address"
      )
      .required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .max(20, "Username must be at most 20 characters")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Username can only contain letters, numbers, and underscores"
      ),

    userRole: Yup.object().required("User Role is required"),
    gst_no: Yup.string()
    .max(255, "Gst Number must be at most 255 characters")
    .matches(
      /^[a-zA-Z0-9_\\/,.\-|*=]+$/,
      "GST Number can only contain letters, numbers, underscores, \\, /, ,, -, ., |, *, and ="
    )
       
    .when(["userRole.value"], ([userRole], schema) => {
      if (userRole != undefined) {
        return userRole === 2 ? schema.required("Gst Number is required") : schema;
      } else {
        return schema.required("Gst Number is required");
      }
    }),
    license_no: Yup.string()
    .max(255, "License Number must be at most 255 characters")
    .matches(
      /^[a-zA-Z0-9_\\/,.\-|*=]+$/,
      "License Number can only contain letters, numbers, underscores, \\, /, ,, -, ., |, *, and ="
    )
    
    
    .when(["userRole.value"], ([userRole], schema) => {
      if (userRole != undefined) {
        return userRole === 2 ? schema.required("License Number is required") : schema;
      } else {
        return schema.required("License Number is required");
      }
    }),

    branch: Yup.object().when(["userRole.value"], ([userRole], schema) => {
      if (userRole != undefined) {
        return userRole === 2 ? schema.required("Branch is required") : schema;
      } else {
        return schema.required("Branch is required");
      }
    }),
    purchase_invoice_code: Yup.string().when(["userRole.value"], ([userRole], schema) => {
      if (userRole != undefined) {
        if (userRole === 2) {
          return schema
            .required("Purchase Invoice Code is required")
            .max(255, "Purchase Invoice Code must be at most 255 characters")
          .matches( /^[a-zA-Z0-9_\\/,.\-|*=]+$/,"Purchase Invoice Code can only contain letters, numbers, underscores, \\, /, ,, -, ., |, *, and =")
        } else {
          // Add validation rules for other cases
          return schema;
        }
    
      } else {
        return schema.required("Purchase Invoice Code is required");
      }
    }),
    surrender_invoice_code: Yup.string().when(["userRole.value"], ([userRole], schema) => {
      if (userRole != undefined) {
        if (userRole === 2) {
          return schema
            .required("Surrender Invoice Code is required")
            .max(255, "Surrender Invoice Code must be at most 255 characters")
          .matches( /^[a-zA-Z0-9_\\/,.\-|*=]+$/,"Surrender Invoice Code can only contain letters, numbers, underscores, \\, /, ,, -, ., |, *, and =")
        } else {
          // Add validation rules for other cases
          return schema;
        }
    
      } else {
        return schema.required("Surrender Invoice Code is required");
      }
    }),

    country: Yup.object().required("Country is required"),

    state: Yup.object().when(["country.value"], ([country], schema) => {
      if (country != undefined) {
        return country === 92 ? schema.required("State is required") : schema;
      } else {
        return schema.required("State is required");
      }
    }),
    franchisee_address: Yup.string().max(350, "max 350 characters allowed"),
    branch_address: Yup.string().max(350, "max 350 characters allowed"),
  };

  if (selectedItemsDetails == "") {
    validationObject.password = Yup.string().required("Password is required");
  }

  const validationSchema = Yup.object().shape(validationObject);

  const initialValues = {
    name: "",
    email: "",
    username: "",
    password: "",
    userRole: "",
    purchase_invoice_code: "",
    surrender_invoice_code: "",
    gst_no: "",
    license_no: "",
    branch: "",
    country: "",
    state: "",
    franchisee_address:"",
    branch_address:""
  };


  useEffect(() => {
    if (selectedItemsDetails) {
      // Update the initialValues if selectedItemsDetails is not empty
      initialValues.name = selectedItemsDetails.name || "";
      initialValues.email = selectedItemsDetails.email || "";
      initialValues.username = selectedItemsDetails.username || "";
      initialValues.country = selectedCountry || "";
      initialValues.purchase_invoice_code = selectedItemsDetails.purchase_invoice_code || "";
      initialValues.surrender_invoice_code = selectedItemsDetails.surrender_invoice_code || "";
      initialValues.gst_no = selectedItemsDetails.gst_no || "";
      initialValues.license_no = selectedItemsDetails.license_no || "";
      initialValues.userRole = selectedRole || "";
      initialValues.branch = selectedBranch || "";
      initialValues.state = selectedState || "";
      initialValues.franchisee_address = selectedItemsDetails.franchisee_address || "";
      initialValues.branch_address = selectedItemsDetails.branch_address || "";
      initialValues.isEditForm = true; // Set isEditForm to true for edit form
      // Set other initial values as needed
    }
  }, [selectedItemsDetails]);

  const onSubmitCreate = async (values, setFieldError) => {
    // API call for creating a new user

    const {
      name,
      email,
      username,
      password,
      userRole,
      purchase_invoice_code,
      surrender_invoice_code,
      gst_no,
      license_no,
      country,
      state,
      branch,
      franchisee_address,
      branch_address
    } = values;

    let formData = {
      name,
      email,
      username,
      password,
      user_role: userRole.value,
      country_id: country.value,
      state_id: state.value,
      franchisee_address,
      branch_address,
      is_active: 0,
    };

    if (userRole.value == 2) {
      formData.branch_id = branch.value;
      formData.purchase_invoice_code = purchase_invoice_code;
      formData.surrender_invoice_code = surrender_invoice_code;
    }
    if (userRole.value == 2 || userRole.value == 3) {
      formData.gst_no = gst_no;
      formData.license_no = license_no;
    }

    return await dispatch(addNewStore(formData));
  };

  const onSubmitUpdate = async (values, setFieldError) => {
    // API call for updating an existing user

    const {
      name,
      email,
      username,
      password,
      userRole,
      purchase_invoice_code,
      surrender_invoice_code,
      gst_no,
      license_no,
      country,
      state,
      branch,
      franchisee_address,
      branch_address,
    } = values;

    let formData = {
      name,
      email,
      username,
      password,
      user_role: userRole.value,
      country_id: country.value,
      franchisee_address,
      branch_address,
      is_active: 0,
    };

    if (userRole.value == 2) {
      formData.branch_id = branch.value;
      formData.purchase_invoice_code = purchase_invoice_code;
      formData.surrender_invoice_code = surrender_invoice_code;
    }

    if (userRole.value == 2 || userRole.value == 3) {
      formData.gst_no = gst_no;
      formData.license_no = license_no;
    }

    if (country.value == 92) {
      formData.state_id = state.value;
    }

    // Make the API call for updating the user using the appropriate endpoint or method
    // For example:
    return await dispatch(
      updateUser({ id: selectedItemsDetails.id, formData: formData })
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {
        setLoading(true);

        const result = values.isEditForm
          ? await onSubmitUpdate(values, setFieldError)
          : await onSubmitCreate(values, setFieldError);

        if (result.payload.success) {
          setLoading(false);
          const successMessage = values.isEditForm
            ? "User has been updated successfully."
            : "New user has been added successfully.";

          toast.success(successMessage);
          setShowform(false);
          resetForm();
          refetch();
        } else {
          setLoading(false);
          if (result.payload.errors) {
            Object.keys(result.payload.errors).forEach((fieldName) => {
              setFieldError(fieldName, result.payload.errors[fieldName][0]);
            });
          } else {
            toast.error(result.message || "Something went wrong.");
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.status === 401) {
          toast.error("Unauthorized access. Please login again.");
        } else if (Object.keys(err.errors || {}).length > 0) {
          const responseErrors = err.errors;
          Object.keys(responseErrors).forEach((fieldName) => {
            setFieldError(fieldName, responseErrors[fieldName][0]);
          });
        } else {
          toast.error("Something went wrong.");
        }
      }
    },
  });

  const renderTabContent = () => {
    return (
      <BasicForm
        formik={formik}
        setShowform={setShowform}
        isLoading={isLoading}
        loading={loading}
        isEditForm={selectedItemsDetails !== "" ? true : false}
      />
    );
  };
  const handleBranchSelection = () => {
    
  }

  return {
    formik,
    renderTabContent,
    loading,
    setLoading,
    user_data,
    user_type
  };
};

export default useUserAddForm;


import useFilterForm from "./useFilter";

function Filter({ setShowform }) {
  const {
    renderTabContent,
  } = useFilterForm(setShowform);

  return (
    <div>
      <form action="javascript:void(0)">{renderTabContent()}</form>
    </div>
  );
}

export default Filter;

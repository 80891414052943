import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  showNotesCk: false,
  selectedItemData: {},
};

export const addItemToTable = createAsyncThunk(
  "customersDetailsPage/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "customersDetailsPage/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/purchase/delete/${params.id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "customersDetailsPage/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/testimonial/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerDataByID = createAsyncThunk(
  "customersDetailsPage/getCustomerDataByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/customer/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPurchaseById = createAsyncThunk(
  "customersDetailsPage/fetchById",
  async (purchaseId, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/purchase/confirm/show/${purchaseId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "customersDetailsPage/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmPayment = createAsyncThunk(
  "purchase/confirmPayment",
  async ({ id, paymentData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/purchase/confirm-payment/${id}`,
        paymentData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customersDetailsPageSlice = createSlice({
  name: "customersDetailsPage",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
    clearCustomerData: (state, action) => {
      delete state.customerDetails;
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},

    [fetchPurchaseById.pending]: (state, action) => {},
    [fetchPurchaseById.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data;
    },
    [fetchPurchaseById.rejected]: (state, action) => {},

    [getCustomerDataByID.pending]: (state, action) => {
      state.customerDetailsLoading = "loading";
    },

    [getCustomerDataByID.fulfilled]: (state, action) => {
      state.customerDetails = action.payload.data.data;
      state.customerDetailsLoading = "success";
    },
    [getCustomerDataByID.rejected]: (state, action) => {
      state.customerDetailsLoading = "error";
    },
    
  },
});

export const { updateConfig, setEditTestimonail, clearCustomerData } =
  customersDetailsPageSlice.actions;

export default customersDetailsPageSlice.reducer;

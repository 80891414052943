import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${
          typeof params?.[key] === "object"
            ? params?.[key]["value"]
            : params?.[key]
        }`;
      } else {
        return "";
      }
    };

    const getQuery = (paramsData) => {
      
      return getParams(paramsData) !== "" ? `&${getParams(paramsData)}` : "";
    };

    try {
      let url =
        method !== "post"
          ? `${endpoint}?${getParams("sort_by")}&sort_order=${
              params?.sort_order || "desc"
            }&length=${params?.length || "10"}&${getParams("search")}&page=${
              params?.page || 1
            }${getQuery("branch_id")}${getQuery("franchise_id")}${getQuery("start_date")}${getQuery(
              "end_date"
            )}`
          : `${endpoint}`;

      const response = await api[method](url, body);

      if (response?.data?.data?.rows !== undefined) {
        return { data: response?.data?.data };
      } else {
        return { data: response?.data };
      }
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const rlm1Report = createApi({
  reducerPath: "rlm1ReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Rlm1Report"],
  endpoints: (builder) => ({
    getRlm1ReportData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/reports/rlm1-report`,
      }),
      providesTags: ["Rlm1Report"],
    }),

    updateRlm1ReportData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/rlm1-report/create`,
      }),

      invalidatesTags: ["Rlm1Report"],
    }),

    // Add more endpoints for the "rlm1-report" section as needed
  }),
});

export const { useGetRlm1ReportDataQuery, useUpdateRlm1ReportDataMutation } =
  rlm1Report;

import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useAllPurchases = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  let user_type = localStorage.getItem("user_role");

  const drawerMenu = [
    {
      title: "All Purchases",
      link: "/purchases",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/purchases", true),
    }
  ];

  if (user_type === "1") {
    drawerMenu.push({
      title: "Cancellation Requests",
      link: "/purchases/cancellation-request",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> cancel_presentation </span>,
      active: checkIfActiveRoute("/purchases/cancellation-request"),
      
    });
  }

  

  // const drawerMenuPermission = useMemo(() => {
  //   let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
  //     ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
  //   ]);

  //   menus = ["All Purchases"];

  //   return drawerMenu
  //     .filter((menu) => menus?.includes?.(menu.title))
  //     .map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);


  return {
    drawerMenu
  };
};

export default useAllPurchases;

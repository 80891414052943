import {
  Button,
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useCustomersList from "./useCustomersList";
import MultiTabForm from "./MultiForm";
import Filter from "./Filter";
import AddRemark from "../Purchases/AddRemark";

const CustomersList = () => {
  const {
    fields,
    customerList,
    customers,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    isFilterShow,
    setIsFilterShow,
    editCustomer,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    hasDeletePermission,
    handleSendMail,
    handleAddNote,
    handleCloseNotesCk
  } = useCustomersList();

  return (
    <>
      <HeadingGroup
        title={"Customers"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ?? false ? "Add new" : ""}
        handleClick={() => {
          setShowform(true);
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col-10">
            <SearchFilters
              data={customers?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
            />
          </div>

          {/* FIXME albert arrange teh componnet */}
          {/* <CustomerProfile/> */}
          <div className="col-auto pro-ms-auto">
            <Button
              onClick={() => {
                setIsFilterShow(true);
              }}
              className="pro-btn pro-btn-primary pro-px-5 lg"
            >
              Filter
            </Button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={customerList?.rows?.data || []}
          uniqueID={"id"}
          fields={customerList?.field}
          showCheckBox={(hasEditPermission || hasDeletePermission) ?? false}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={customers?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDelete}
          editable={hasEditPermission ?? false}
          deletable={hasDeletePermission ?? false}
        />

        {customerList?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={customerList?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={customerList?.field}
              moduleId={customerList?.table_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <OffCanvasLayout
          centered={false}
          show={showform}
          handleClose={() => {
            setShowform(false);
          }}
          keyboard={false}
          title={
            editCustomer === true ? "Edit Customer" : "Customer Registration"
          }
          backdrop="static"
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <MultiTabForm setShowform={setShowform} refetch={refetch} />
        </OffCanvasLayout>

        <OffCanvasLayout
          centered={true}
          show={isFilterShow}
          handleClose={() => {
            setIsFilterShow(false);
          }}
          backdrop="static"
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setIsFilterShow} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default CustomersList;

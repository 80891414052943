import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddBranchForm from "./useAddForm";
import AddFormFields from "../AddFormFields";
import useAddLeadForm from "./useAddForm";

const AddFormFieldsContainer = ({ setShowform, refetch, lead }) => {
  const {
    formik,
    loading,
    isLoading,
    optionsWithIcons,
    staffList,
    selectedOption,
    setSelectedOption,
    options,
    handleFileChange,
    franchiseOptions,
  } = useAddLeadForm(setShowform, refetch, lead);
  return (
    <div className={`pro-w-100`}>
      <AddFormFields franchiseOptions={franchiseOptions} formik={formik} />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddFormFieldsContainer;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

const TransactionForm = ({
  transaction,
  index,
  handleRemoveTransaction,
  currencyOptions,
  formik,
}) => {
  const calculateSum = () => {
    const currencySums = {};

    formik.values.transactions.forEach((transaction) => {
      const { currencyName, currencyVolume } = transaction;

      if (currencySums.hasOwnProperty(currencyName.value)) {
        currencySums[currencyName.value] += parseFloat(currencyVolume);
      } else {
        currencySums[currencyName.value] = parseFloat(currencyVolume);
      }
    });
  };
  const handleCurrencyChange = (selectedOption) => {
    formik.setFieldValue(`transactions.${index}.currencyName`, selectedOption);

    formik.setFieldValue(`transactions.${index}.rate`, "");

    formik.setFieldValue(`transactions.${index}.inrVolume`, "");
  };

  const handleCurrencyVolumeChange = (event) => {
    const { value } = event.target;

    if (!formik.values.transactions[index].currencyName) {
      toast.error("Currency is not selected!");
      return;
    }

    if (isNaN(value)) {
      return;
    }

    formik.setFieldValue(
      `transactions[${index}].currencyVolume`,
      value != "" ? value : ""
    );

    const rate = formik.values.transactions[index].rate;
    const inrVolume = value * rate;
    const formattedInrVolume = parseFloat(inrVolume.toFixed(2));
    formik.setFieldValue(`transactions.${index}.inrVolume`, formattedInrVolume);
  };

  const handleRateChange = (event) => {
    const { value } = event.target;

    if (!formik.values.transactions[index].currencyName) {
      toast.error("Currency is not selected!");
      return;
    }

    if (isNaN(value)) {
      return;
    }

    formik.setFieldValue(`transactions.${index}.rate`, value);

    const currencyVolume = formik.values.transactions[index].currencyVolume;
    const inrVolume = currencyVolume * value;
    formik.setFieldValue(`transactions.${index}.inrVolume`, inrVolume);
  };

  return (
    <div key={index} className="input-wrap pro-mb-4">
      <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Currency Name *
      </label>
      <Select
        className={`pro-input lg ${
          formik.errors.transactions?.[index]?.currencyName &&
          formik.touched.transactions?.[index]?.currencyName &&
          "error"
        }`}
        classNamePrefix={"pro-input"}
        value={transaction.currencyName}
        onChange={handleCurrencyChange}
        onBlur={() =>
          formik.setFieldTouched(`transactions.${index}.currencyName`, true)
        }
        name={`transactions.${index}.currencyName`}
        options={currencyOptions}
      />

      {formik.errors.transactions?.[index]?.currencyName &&
        formik.touched.transactions?.[index]?.currencyName && (
          <span className="error-text">
            {formik.errors.transactions[index].currencyName}
          </span>
        )}
      <br />

      <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Payment Type *
      </label>
      <br />

      <div className="input-wrap pro-mb-4">
        <div className="row gx-6">
          <div className="col-auto">
            <div className="pro-check-box">
              <input
                type="radio"
                className="pro-check"
                id={`radio1-${index}`}
                name={`radio-${index}`}
                checked={transaction.paymentType === 1}
                value="1"
                onChange={(e) =>
                  formik.setFieldValue(`transactions[${index}].paymentType`, 1)
                }
              />
              <label htmlFor={`radio1-${index}`} className="pro-check-label">
                Currency
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box">
              <input
                type="radio"
                className="pro-check"
                id={`radio2-${index}`}
                name={`radio-${index}`}
                checked={transaction.paymentType === 2}
                value="2"
                onChange={(e) => {
                  formik.setFieldValue(`transactions[${index}].paymentType`, 2);
                }}
              />
              <label htmlFor={`radio2-${index}`} className="pro-check-label">
                Travel cheque
              </label>
            </div>
          </div>
        </div>
        <br />
        {formik.errors.transactions?.[index]?.paymentType &&
          formik.touched.transactions?.[index]?.paymentType && (
            <span className="error-text">
              {formik.errors.transactions[index].paymentType}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Currency Volume *
        </label>
        <input
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.currencyVolume &&
            formik.touched.transactions?.[index]?.currencyVolume &&
            "error"
          }`}
          value={transaction.currencyVolume}
          onChange={handleCurrencyVolumeChange}
          onBlur={formik.handleBlur}
          name={`transactions[${index}].currencyVolume`}
        />
        {formik.errors.transactions?.[index]?.currencyVolume &&
          formik.touched.transactions?.[index]?.currencyVolume && (
            <span className="error-text">
              {formik.errors.transactions[index]?.currencyVolume}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Rate *
        </label>
        <input
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.rate &&
            formik.touched.transactions?.[index]?.rate &&
            "error"
          }`}
          value={transaction.rate}
          onChange={handleRateChange}
          onBlur={formik.handleBlur}
          name={`transactions[${index}].rate`}
        />
        {formik.errors.transactions?.[index]?.rate &&
          formik.touched.transactions?.[index]?.rate && (
            <span className="error-text">
              {formik.errors.transactions[index].rate}
            </span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          INR Volume *
        </label>
        <input
          readOnly
          type="text"
          className={`pro-input lg ${
            formik.errors.transactions?.[index]?.inrVolume &&
            formik.touched.transactions?.[index]?.inrVolume &&
            "error"
          }`}
          value={transaction.inrVolume}
          onChange={(e) =>
            formik.setFieldValue(
              `transactions[${index}].inrVolume`,
              e.target.value
            )
          }
          onBlur={formik.handleBlur}
          name={`transactions[${index}].inrVolume`}
        />
        {formik.errors.transactions?.[index]?.inrVolume &&
          formik.touched.transactions?.[index]?.inrVolume && (
            <span className="error-text">
              {formik.errors.transactions[index].inrVolume}
            </span>
          )}
      </div>

      {index > 0 && (
        <button
          type="button"
          className="pro-btn pro-btn-secondary pro-mt-4"
          onClick={() => handleRemoveTransaction(index)}
        >
          Remove Transaction
        </button>
      )}
    </div>
  );
};

export default TransactionForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  search: "",
  franchiseId: "", // New variable for franchise ID
  visaTypeId: "", // New variable for visa type ID
  countryId: "", // New variable for country ID
  stateId: "", // New variable for state ID
  nationalityId: "", // New variable for nationality ID
  isActive: "", // New variable for active status
  startDate: "", // New variable for start date
  endDate: "", // New variable for end date
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
};

export const addItemToTable = createAsyncThunk(
  "customers/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "customers/deleteItemFromTable",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/customer/delete/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "customers/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/testimonial/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "customers/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/testimonial/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },

    setStoreFormValues: (state, action) => {
      state.franchiseId = action.payload.franchise;
      state.visaTypeId = action.payload.visaType;
      state.nationalityId = action.payload.nationality;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.countryId = action.payload.country;
      state.stateId = action.payload.state;
    },
    resetExtraFilters: (state, action) => {
      state.franchiseId = "";
      state.visaTypeId = "";
      state.countryId = "";
      state.stateId = "";
      state.isActive = "";
      state.startDate = "";
      state.endDate = "";
      state.nationalityId = "";
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},
  },
  
});

export const {
  updateConfig,
  setEditTestimonail,
  resetExtraFilters,
  setStoreFormValues,
} = customersSlice.actions;

export default customersSlice.reducer;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { customer } from "../../../../store/queries/customers";
import { surrender } from "../../../../store/queries/surrenders";
import { purchase } from "../../../../store/queries/purchases";
import {
  adminUser,
  useGetLoggedInStaffProfileDataQuery,
} from "../../../../store/queries/admin";
import { userRoles } from "../../../../store/queries/roles";
import { leads } from "../../../../store/queries/leads";
import { rlm1Report } from "../../../../store/queries/reportsRLM1";
import { rlm2Report } from "../../../../store/queries/reportsRLM2";
import { rlm3Report } from "../../../../store/queries/reportsRLM3";
import { currencyRtk } from "../../../../store/queries/currency";
import { purchaseReport } from "../../../../store/queries/PurchaseRegisterReport";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";

const useHeaderActions = () => {
  const globalState = useSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isProfileViewVisible, setProfileViewVisible] = useState(false);

  const { data: loggedStaffData } = useGetLoggedInStaffProfileDataQuery();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    if(loggedStaffData) {
      localStorage.setItem("login_data", loggedStaffData ? loggedStaffData?.data?.user_id : "");
      localStorage.setItem("branch_data", loggedStaffData ? loggedStaffData?.data?.branch_id : "");
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  const dateFormatOptions = { day: "numeric", month: "short", year: "numeric" };
  const timeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = currentDateTime.toLocaleDateString(
    undefined,
    dateFormatOptions
  );
  const formattedTime = currentDateTime.toLocaleTimeString(
    undefined,
    timeFormatOptions
  );

  const handleViewProfile = () => {
    setProfileViewVisible(true);
  };

  const handleCloseProfileView = () => {
    setProfileViewVisible(false);
  };

  const handleLogout = () => {
    dispatch(customer.util.resetApiState());
    dispatch(surrender.util.resetApiState());
    dispatch(purchase.util.resetApiState());
    dispatch(adminUser.util.resetApiState());
    dispatch(userRoles.util.resetApiState());
    dispatch(leads.util.resetApiState());
    dispatch(rlm1Report.util.resetApiState());
    dispatch(rlm2Report.util.resetApiState());
    dispatch(rlm3Report.util.resetApiState());
    dispatch(currencyRtk.util.resetApiState());
    dispatch(purchaseReport.util.resetApiState());

    localStorage.removeItem("user_role");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");
    dispatch(
      updateConfig((state) => {
        state.dashboard_permissions = {}
      })
    )
    

    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings/appearance");
  };
  return {
    handleLogout,
    handleSettings,
    loggedStaffData,
    formattedDate,
    formattedTime,
    handleViewProfile,
    handleCloseProfileView,
    isProfileViewVisible,
    setProfileViewVisible,
  };
};

export default useHeaderActions;

import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_id")}&${getParams(
          "user_type_sort"
        )}&${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&per_page=${params?.page_size || "10"}&page=${
          params?.page || 1
        }&${getParams("member_id")}${getParams("page_size")}`,
        body
      );
      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const profile = createApi({
  reducerPath: "profileApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Profile",
    "Overview",
    "Matches",
    "Shortlist",
    "Favorites",
    "OptionsData",
    "ProfilePayment"
  ],
  endpoints: (builder) => ({
    getOverviewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/profile/view`,
      }),
      providesTags: ["Overview"],
    }),
    getMatchesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/profile/matches`,
      }),

      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },

      // merge: (currentCache, newItems) => {

      //   newItems = newItems.data.profiles.data;
      //   currentCache?.data?.profiles?.data?.push(...newItems);
      // },
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg;
      // },
      providesTags: ["Matches"],
    }),
    shortListProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/members/profile/shortlist`,
      }),

      invalidatesTags: ["Shortlist"],
    }),
    getShortlistedData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/profile/shortlist/list`,
      }),
      providesTags: ["Shortlist"],
    }),
    getFavoritesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/profile/favourites`,
      }),
      providesTags: ["Favorites"],
    }),
    updateProfileDetails: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/members/profile/update`,
      }),

      invalidatesTags: ["Overview"],
    }),
    highlightProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/members/profile/highlight`,
      }),

      invalidatesTags: ["Overview"],
    }),
    unHighlightProfile: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/highlighted-profiles/remove`,
      }),

      invalidatesTags: ["Overview"],
    }),
    getFilterOptionsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/create`,
      }),
      providesTags: ["OptionsData"],
    }),
    getProfilePaymentListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/payments`,
      }),
      providesTags: ["ProfilePayment"],
    }),
  }),
});

export const {
  useGetOverviewDataQuery,
  useGetMatchesDataQuery,
  useGetShortlistedDataQuery,
  useGetFavoritesDataQuery,
  useUpdateProfileDetailsMutation,
  useHighlightProfileMutation,
  useGetFilterOptionsDataQuery,
  useShortListProfileMutation,
  useGetProfilePaymentListDataQuery,
  useUnHighlightProfileMutation
} = profile;

import React from "react";
import ActionBtns from "./ActionBtns";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Style from "./orderColumn.module.scss";
import useOrderColoum from "./useOrderColoum";

const OrderColumn = ({
  title,
  tableFields,
  moduleId,
  updateData,
  refetchData,
}) => {
  const {
    characters,
    handleCancel,
    handleSubmit,
    handleOnDragEnd,
    handleCheckbox,
    //message
  } = useOrderColoum({ tableFields, moduleId, updateData, refetchData });

  return (
    <div className={Style.root}>
      <div className={Style.heading}>
        {title && <h5 className="pro-ttl h5 pro-mb-5">{title}</h5>}
      </div>
      <div className={Style.body}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className={`characters ${Style.items}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {characters &&
                  Object.values(characters)?.map((item, index) => {
                    return (
                      <Draggable
                        key={item?.label}
                        draggableId={item?.label}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <div
                              className={Style.item}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={Style.icon}></div>
                              <div className="pro-check-box">
                                <input
                                  type="checkbox"
                                  className="pro-check"
                                  id={`id-${index}`}
                                  checked={!item?.hidden}
                                  onChange={() => handleCheckbox(item.label)}
                                  name="pro-checkbox"
                                />
                                <label
                                  htmlFor={`id-${index}`}
                                  className="pro-check-label pro-fw-bold"
                                >
                                  {" "}
                                  {item?.label}{" "}
                                </label>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <ActionBtns
        submitText={"Update"}
        cancelText={`Cancel`}
        isRight
        SubmitAction={handleSubmit}
        cancelAction={handleCancel}
      />

      {/* {Object.keys(message)?.map((key, item) => {
        return <div className="error-text" key={key}>{key + " : " + message[key]} </div>;
      })} */}
    </div>
  );
};

export default OrderColumn;

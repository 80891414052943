import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { startOfMonth } from "date-fns";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  selectedBranches: null,
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  selectedFranchise: null,
  startDate: startOfMonth(new Date()),
  endDate: new Date(),
  branchesOptions:[],
  franchiseData: []
};

export const getFranchiseById = createAsyncThunk(
  "v1/listing/franchise",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        id ? `/v1/listing/franchise/${id}` : `/v1/listing/franchise`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getListData = createAsyncThunk(
  "v1/reports/rlm1-report",
  async ({data}, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get('v1/reports/rlm1-report', { params: data });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const reportsRLM1Slice = createSlice({
  name: "reportsRLM1Slice",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditTestimonail: (state, action) => {
      state.editTestimonial = action.payload;
    },
  },
  extraReducers: {
    [getFranchiseById.fulfilled]: (state, action) => {
      state.franchiseData = action.payload.data;
    
    },
  },
});

export const { updateConfig, setEditTestimonail } = reportsRLM1Slice.actions;

export default reportsRLM1Slice.reducer;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetAdminUsersListDataQuery,
  useUpdateAdminUsersListDataMutation,
} from "../../../store/queries/currency";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
  deleteItemFromTable,
  setEdit,
  setEditCurrencyItem,
  updateConfig,
} from "../../../store/slices/Currency/CurrencyListSlice";
import { toast } from "react-toastify";

const useCCRateList = () => {
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.currency);

  const { editItem } = currency;

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [filterForm, setFilterForm] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordUserID, setChangePasswordUserID] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const options = [
    { value: "0", label: "Active" },
    { value: "1", label: "Inactive" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (currency.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateUsersFields] = useUpdateAdminUsersListDataMutation();

  const {
    data: usersList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAdminUsersListDataQuery({
    length: currency.currentPageSize,
    page: currency.currentPage,
    sort_by: currency.sortBy,
    sort_order: currency.sortOrder,
    search: currency.search,
    branch_id: currency.filter.branch?.value,
    currency_id: currency.filter.currency?.value,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? false;
  }, [usersList]);

  const hasDeletePermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? false;
  }, [usersList]);

  const hasEditPermission = useMemo(() => {
    let permission = usersList?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? false;
  }, [usersList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleAddNewCurrencyRate = () => {
    dispatch(setEdit(false));
    setShowform(true);
    dispatch(setEditCurrencyItem(""));
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ user_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result?.data?.message === "Success") {
            toast.success(result?.data?.message);
          } else {
            toast.error(result?.data?.message);
          }
          if (result.data.data) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleSort = (label) => {
    if (currency.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = currency.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    const editItem = usersList.rows.data.filter(
      (element) => element.id === ids[0]
    );

    if (Array.isArray(editItem) && editItem.length > 0) {
      dispatch(setEditCurrencyItem(editItem[0]));
      dispatch(setEdit(true));
      setShowform(true);
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (field, data) => <p className="pro-mb-0">{data["user"]?.name}</p>,
      code: (field, data) => (
        <p className="pro-mb-0">{data["currency"]?.name}</p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    handleAddNewCurrencyRate,
    editItem,
    usersList,
    currency,
    currentPage: currency.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateUsersFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    filterForm,
    setFilterForm,
    changePassword,
    setChangePassword,
    changePasswordUserID,
    setChangePasswordUserID,
  };
};

export default useCCRateList;

import React, { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useAddRemark from "./useAddRemark";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const AddRemark = ({
  selectedItemData,
  isStickyFooter,
  refetch
}) => {

    const {
        formik,
        getFieldError,
        selectedItemsDetails,
        handleCloseModal,
        remarkLoading
      } = useAddRemark({refetch });

  return (
    <>
      <div className="input-wrap pro-mb-4">
        <textarea
          id="remark"
          rows={4}
          name="remark"
          className={`pro-input lg ${formik.touched.remark && formik.errors.remark && " error"}`}
          {...formik.getFieldProps("remark")}
        ></textarea>
        {formik.touched.remark && formik.errors.remark && (
          <span className="error-text">{formik.errors.remark}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            remarkLoading ? "loading" : ""
          }`}
          type="submit"
          disabled={remarkLoading}
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </>
  );
};

export default AddRemark;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  userRole: "",
  startDate: "",
  endDate: "",
};

export const addItemToTable = createAsyncThunk(
  "users/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/user/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "users/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/delete/${params.user_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "users/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin/show/${params.user_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateItemFromTableByID = createAsyncThunk(
  "users/updateItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/user/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "users/updateUserStatus",
  async ({ userId, postData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin/change-status/${userId}`,
        postData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePasswordByUserID = createAsyncThunk(
  "users/updatePasswordByUserID",
  async ({ userId, postData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin/change-password/admin/${userId}`,
        postData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setEditUser: (state, action) => {
      state.editUser = action.payload;
    },
    // setFilterValues: (state, action) => {
    //   if (action.payload === false) {
    //     state.filter.userRole = "";
    //     state.filter.startDate = "";
    //     state.filter.endDate = "";
    //   } else {
    //     state.filter.userRole = action.payload.values.userRole;
    //     state.filter.startDate = action.payload.values.startDate;
    //     state.filter.endDate = action.payload.values.endDate;
    //   }
    // },
    setFilterValues: (state, action) => {
      state.userRole = action.payload.userRole;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.userRole = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => { },
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => { },
  },
});

export const { updateConfig, setEditUser, setFilterValues, resetExtraFilters } = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect } from "react";
import useSurrenderDetailTable from "./useSurrenderDetailTable";
import { BreadCrumbs, HeadingGroup, Table } from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import CertificatePrint from '../print-page'

const SurrenderDetailTable = () => {
  const { selectedItemsDetails,handleLinkClick, printRef, handlePrintClick, getRow, beautifyDate } = useSurrenderDetailTable();

  const {
    franchise,
    payment_mode,
    currency_surrender_data,
    created_at,
    total_inr_volume,
    surrender_no,
    status,
    payment_mode_name
  } = selectedItemsDetails?.selectedItemsDetails ?? {};

  const singleDigits = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];

  const teenDigits = [
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tensDigits = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const bigNumbers = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
  ];

  function capitalizeAllWords(text) {
    return text.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  function convertNumberToWords(number) {
    if (number === "") {
      return "";
    }

    if (number === 0) {
      return "zero";
    }

    const words = [];

    let numStr = number?.toString();

    while (numStr?.length % 3 !== 0) {
      numStr = "0" + numStr;
    }

    for (let i = 0; i < numStr.length; i += 3) {
      const chunk = parseInt(numStr.substr(i, 3));

      if (chunk !== 0) {
        const hundreds = Math.floor(chunk / 100);
        const tens = Math.floor((chunk % 100) / 10);
        const ones = chunk % 10;
        let chunkWords = "";

        if (hundreds > 0) {
          chunkWords += singleDigits[hundreds] + " hundred ";
        }

        if (tens === 1 && ones > 0) {
          chunkWords += teenDigits[ones - 1] + " ";
        } else {
          if (tens > 0) {
            chunkWords += tensDigits[tens] + " ";
          }
          if (ones > 0) {
            chunkWords += singleDigits[ones] + " ";
          }
        }

        const bigNumberIndex = numStr.length / 3 - i / 3 - 1;
        if (bigNumberIndex >= 1 && chunkWords.trim() !== "") {
          chunkWords += bigNumbers[bigNumberIndex] + " ";
        }

        words.push(chunkWords.trim());
      }
    }

    return words.join(" ").trim();
  }

  const currencyData =
  currency_surrender_data?.map((currencyItem) => ({
      currencyName: currencyItem?.currency?.name || "",
      type: "CCY",
      amount: currencyItem?.currency_volume || "0.00",
      ratePer: currencyItem?.rate || "0.00 / 1",
      rsEquivalent: currencyItem?.inr_volume || "0.00",
    })) || [];

  // Assigning the extracted values to purchaseData
  const purchaseData = {
    surrender_to: franchise?.branch,
    cancelled: status,
    branchName: `LULU FINSERV -  ${franchise?.name || ""}`,
    titleName: `Franchisee of Lulu Forex Pvt Ltd.`,
    rbiLicenseNumber: franchise?.license_no ? `LIC.NO. : ${franchise?.license_no || ""}` : "",
    gstInNumber: franchise?.gst_no ? `GST IN NO. : ${franchise?.gst_no || ""}` : "",
    cin: `${franchise?.cin_sac || ""}`,
    branchAddress: franchise?.franchisee_address || franchise?.branch_address,
    // branchContact: branchContact || "+914844187757",
    validityText:
      "(Valid only for three months from the date of purchase of foreign currency)",
    serialNumber: surrender_no,
    date: beautifyDate(created_at),
    currencyData: currencyData,
    exchangeTransactionFee: "- 0.00",
    total: total_inr_volume,
    totalInWords: capitalizeAllWords(convertNumberToWords(total_inr_volume)),
    paymentDetails: `Paid ${total_inr_volume} By ${
      payment_mode?.payment_mode || ""
    }`,
    paymentMode: payment_mode_name,
    // undertakingText:
    //   "I CONFIRM THAT I HAVE NOT ENCASED FOREIGN CN/TC MORE THAN 1000 USD OR EQUIVALENT UPTO DATE IN THIS MONTH IN CASH",
    authorizedSignatory: `For LULU FINSERV - ${franchise?.name || ""}`,
    // note: "This certificate should be carefully preserved to facilitate reconversion of the rupee balance, if any, into foreign currency at the time of departure from India and/or of payment of the passage fare/freight cost in rupees, if necessary.",
    gstAndCinDetails: franchise?.gst_no ? `GST IN NO. : ${franchise?.gst_no || ""} | ${franchise?.cin_sac || ""}` : "",
  };


  return (
    <>
      {selectedItemsDetails?.selectedItemsDetails?.surrender_no ? (
        <div className="pro-mb-3">
          <BreadCrumbs
            data={["Surrenders", `${selectedItemsDetails?.selectedItemsDetails?.surrender_no}`]}
            clickable
            handleClick={handleLinkClick}
          />
        </div>
      ) : (
        <div className="pro-d-flex pro-gap-1">
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
        </div>
      )}
      <HeadingGroup title={"Surrenders"} className={`pro-mb-4`} handleClick={handlePrintClick} buttonTitle="Print Certificate"/>
      <CertificatePrint ref={printRef} {...purchaseData}></CertificatePrint>
      <Table
        multiSelect={false}
        data={
          selectedItemsDetails?.selectedItemsDetails?.currency_surrender_data ??
          []
        }
        uniqueID={"id"}
        fields={selectedItemsDetails?.selectedItemsDetails?.field}
        getRow={getRow}
        loading={false}
        perpage={10}
        assignable={false}
      />
    </>
  );
};

export default SurrenderDetailTable;

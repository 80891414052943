import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/auth/login", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/auth/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/auth/forgot-password-change/${params.token}`,
      params
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateEmail = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/v1/auth/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/auth/forgot-password-verify/${params.token}`,
      { otp: params.otp }
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  activeNationalities,
  getVisaTypes,

  getAllFranchises,
} from "../../../../store/slices/customers/registerCustomersSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import {
  getAllCountries,
  getAllStates,
} from "../../../../store/slices/customers/registerCustomersSlice";

const nationalitiesOptions = require("../../../../assets/data/nationality.json");

const BasicForm = ({ formik, handleResetExtraFilters }) => {
  const dispatch = useDispatch();


  const [nationalitiesList, setNationalitiesList] = useState([]);
  const [visaType, setVisaType] = useState([]);

  const [statesOptions, setStates] = useState([]);
  const [countriesOptions, setCountries] = useState([]);
  const [franchiseOptions, setFranchise] = useState([]);

  useEffect(() => {
    dispatch(getAllStates())
      .unwrap()
      .then((result) => {
        setStates(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getAllCountries())
      .unwrap()
      .then((result) => {
        setCountries(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(activeNationalities())
      .unwrap()
      .then((result) => {
        setNationalitiesList(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    dispatch(getVisaTypes())
      .unwrap()
      .then((result) => {
        setVisaType(
          result.data.data?.map((item) => ({
            label: item.value,
            value: item.id,
          }))
        );
      })

      .catch((err) => {});

    dispatch(getAllFranchises())
      .unwrap()
      .then((result) => {
        setFranchise(
          result.data.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Franchise
          </label>
          <Select
            name="franchise"
            options={franchiseOptions} // replace with your franchise options
            onChange={(selectedOption) =>
              formik.setFieldValue("franchise", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.franchise && formik.touched.franchise && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.franchise}
          />
          {formik.touched.franchise && formik.errors.franchise && (
            <div className="error">{formik.errors.franchise}</div>
          )}
        </div>
      </div>


      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Visa Type
          </label>
          <Select
            name="visaType"
            options={visaType} // replace with your visaType options
            onChange={(selectedOption) =>
              formik.setFieldValue("visaType", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.visaType && formik.touched.visaType && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.visaType}
          />
          {formik.touched.visaType && formik.errors.visaType && (
            <div className="error">{formik.errors.visaType}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label
            htmlFor="country"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Country
          </label>
          <Select
            name="country"
            options={countriesOptions} // replace with your country options
            onChange={(selectedOption) =>
              formik.setFieldValue("country", selectedOption)
            }
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.country}
            className={`pro-input lg ${
              formik.errors.country && formik.touched.country && "error"
            }`}
          />
          {formik.touched.country && formik.errors.country && (
            <div className="error">{formik.errors.country}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="state" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>

          <Select
            name="state"
            options={statesOptions} // replace with your country options
            onChange={(selectedOption) =>
              formik.setFieldValue("state", selectedOption)
            }
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            className={`pro-input lg ${
              formik.errors.state && formik.touched.state && "error"
            }`}
          />
          {formik.touched.state && formik.errors.state && (
            <div className="error">{formik.errors.state}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Nationality
          </label>
          <Select
            name="nationality"
            options={nationalitiesList} // replace with your nationality options
            onChange={(selectedOption) =>
              formik.setFieldValue("nationality", selectedOption)
            }
            className={`pro-input lg ${
              formik.errors.franchise && formik.touched.franchise && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.nationality}
          />
          {formik.touched.nationality && formik.errors.nationality && (
            <div className="error">{formik.errors.nationality}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.startDate && formik.touched.startDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <div className="error">{formik.errors.startDate}</div>
          )}
        </div>
      </div>

      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            End Date
          </label>
          <input
            type="date"
            name="endDate"
            placeholder="Date of Birth"
            onChange={formik.handleChange}
            className={`pro-input lg ${
              formik.errors.endDate && formik.touched.endDate && "error"
            }`}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            min={formik?.values?.startDate ? formik.values.startDate : ""}
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <div className="error">{formik.errors.endDate}</div>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-3"}
          onClick={handleResetExtraFilters}
        >
          Reset
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={false}
          onClick={formik.handleSubmit}
        >
          Filter
        </Button>
      </div>
    </div>
  );
};

export default BasicForm;

import { useDispatch, useSelector } from "react-redux";
import {
  useGetRlm2ReportDataQuery,
  useUpdateRlm2ReportDataMutation,
} from "../../../store/queries/reportsRLM2";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  deleteItemFromTable,
  editItemFromTableByID,
  updateConfig,
  getFranchiseById,
  getListData
} from "../../../store/slices/Reports/reportsRLM2Slice";
import { addDays } from "date-fns";
import { getRevFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import {
  getAllBranches,
  getAllFranchise,
} from "../../../store/slices/customers/registerCustomersSlice";
import html2pdf from "html2pdf.js";
import { useRef } from "react";
import moment from "moment";
import { toast } from "react-toastify";

const useRLM2 = () => {
  const dispatch = useDispatch();

  const rlm2Report = useSelector((state) => state.rlm2Report);

  const { showEditModal } = useSelector((state) => state.global);
  const [showform, setShowform] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [branchesOptions, setBranches] = useState([]);
  const [franchiseOptions, setFranchise] = useState([]);
  const [namePdf, setName] = useState("");
  const printRef = useRef(null);
  let user_type = localStorage.getItem("user_role");
  let user_data = localStorage.getItem("login_data");
  let branch_id = localStorage.getItem("branch_data");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: rlm2Report.startDate,
    endDate: rlm2Report.endDate,
    key: "selection",
  });

  // useEffect(() => {
  //   handleDateChange(new Date());
  // }, []);

  useEffect(() => {
    
    dispatch(getAllBranches())
      .unwrap()
      .then((result) => {
        
        if (user_type == 3) {
          setBranches(
            result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          );
        } 
        else if(user_type == 2) {
          setSeleoption(branch_id);
        }
        else {
          setBranches([
            ...[
              {
                label: "All",
                value: "",
              },
            ],
            ...result.data.data?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]);
          dispatch(
            updateConfig((state) => {
              state.selectedBranches = {
                label: "All",
                value: "",
              };
            })
          );
        }
      })
      .catch((err) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {    
        if (user_type == 3) {
          if(user_data) {
            let selectedBranchOPtion = branchesOptions.find((item) => (item.value == user_data));
            if(selectedBranchOPtion) {
              setName(selectedBranchOPtion.label);
              dispatch(
                updateConfig((state) => {
                  state.selectedBranches = selectedBranchOPtion
                })
              );
              setSeleoption(user_data);
            }
          }
        } 
  }, [branchesOptions]);

  useEffect(() => {    
    if(user_type == 2) {
      if(user_data) {
        let selectedFranchise = franchiseOptions.find((item) => (item.value == user_data));
        setName(selectedFranchise?.label);
        dispatch(
          updateConfig((state) => {
            state.selectedFranchise = selectedFranchise
          })
          )
      }
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [franchiseOptions]);

  useEffect(() => {
    if (rlm2Report.selectedItemsDetails !== "" && !showform) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [showform]);

  const [updateTestimonialsFields] = useUpdateRlm2ReportDataMutation();
  const [skip, setSkip] = useState(true);

  const {
    data: rlm2ReportList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetRlm2ReportDataQuery({
    page_size: rlm2Report.currentPageSize,
    page: rlm2Report.currentPage,
    sort_by: rlm2Report.sortBy,
    sort_order: rlm2Report.sortOrder,
    start_date: getRevFormatedDate(rlm2Report.startDate) + " 00:00:00",
    end_date:
    getRevFormatedDate(rlm2Report.endDate) + ` 23:59:59`,

    search: rlm2Report.search,
    branch_id: rlm2Report.selectedBranches,
    franchise_id: rlm2Report?.selectedFranchise
  }, {skip: skip});


	useEffect(() => {
		const selectedFranchise = rlm2Report?.selectedFranchise;
		const selectedBranch = rlm2Report?.selectedBranches;

		if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type == 2) {
      setSkip(false);
		}
		else if (selectedFranchise && typeof selectedFranchise === 'object' && selectedFranchise.value && user_type != 2) {
      setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm2Report?.selectedFranchise]);

	useEffect(() => {
		const selectedBranch = rlm2Report.selectedBranches;
		if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value && (user_type == 3 || user_type != 2)) {
			setSkip(false);
		}
		else if (selectedBranch && typeof selectedBranch === 'object' && selectedBranch.value == "" && (user_type != 3 || user_type != 2)) {
			setSkip(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rlm2Report?.selectedBranches]);

  const handleDownloadClick = () => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [0, 0, 10, 0],
      filename: `${
        "000" + "_" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 800,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handleActionSetBranches = (branch) => {
    dispatch(
      updateConfig((state) => {
        state.selectedBranches = branch;
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    let permission = rlm2ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.length ?? permission?.[0]?.can_create;
  }, [rlm2ReportList]);

  const hasViewPermission = useMemo(() => {
    let permission = rlm2ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.length ?? permission?.[0]?.can_view;
  }, [rlm2ReportList]);

  const hasEditPermission = useMemo(() => {
    let permission = rlm2ReportList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.length ?? permission?.[0]?.can_edit;
  }, [rlm2ReportList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const convertDateFormat = (inputDateString) => {
    // Create a new Date object with the input date string
    var inputDate = new Date(inputDateString);

    // Extract the year, month, and day from the Date object
    var year = inputDate.getFullYear();
    var month = String(inputDate.getMonth() + 1).padStart(2, "0");
    var day = String(inputDate.getDate()).padStart(2, "0");

    // Create the desired output date string
    var outputDateString = year + "-" + month + "-" + day;

    return outputDateString;
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = convertDateFormat(range) + " 00:00:00";
        state.endDate = convertDateFormat(range) + " 23:59:59";
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteItemFromTable({ testimonial_id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result.data.data) {
            // refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };
  const handleSort = (label) => {
    if (rlm2Report.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rlm2Report.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (ids) => {
    if (Array.isArray(ids)) {
      dispatch(editItemFromTableByID({ testimonial_id: ids[0] }))
        .unwrap()
        .then((data) => {
          setShowform(true);
        });
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePrintClick = (data) => {
    const element = printRef.current;

    let Tablelength = rlm2ReportList?.data?.table?.rows?.length;

    html2pdf(element, {
      margin: [15, 0, 10, 0],
      filename: `${moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width:
          Tablelength <= 5
            ? 650
            : Tablelength > 5 && Tablelength <= 10
            ? 820
            : Tablelength > 10 && Tablelength <= 15
            ? 1250
            : Tablelength > 15 && Tablelength <= 20
            ? 1550
            : 2200,

        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: Tablelength <= 10 ? "portrait" : "landscape",
      },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };


  const handleActionSetFranchiseies = (franchise) => {
    // setSeleoption(franchise);
    setName(franchise?.label)
    dispatch(
      updateConfig((state) => {
        state.selectedBranches = franchise;
      })
    );
    if(franchise?.value) {
      setSeleoption(franchise?.value);
    }
    else {
      setFranchise([]);
      dispatch(
        updateConfig((state) => {
          state.selectedFranchise = "";
        })
      );
    }
  };

  const setSeleoption = (id) => {
    dispatch(getFranchiseById(id))
    .unwrap()
    .then((result) => {
      if (result?.data?.message === "Success") {
        if(user_type == 2) {
          let temp = result?.data?.data?.filter((item) => item.id == user_data)
          setFranchise(
            temp?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
        else {
          setFranchise(
            result?.data?.data?.map((item) => (
              {
                label: item?.name,
                value: item?.id,
              }
            ))
          );
        }
        
      } else {
        toast.error(result?.data?.message);
      }
      
    });
  }

  const handleActionSetFranchiseById = (data) => {
    setName(data?.label)
    dispatch(
      updateConfig((state) => {
        state.selectedFranchise = data;
      })
    );
  };

  return {
    rlm2ReportList,
    rlm2Report,
    currentPage: rlm2Report.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handleDateRangeChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    // refetch,
    date,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    hasViewPermission,
    hasEditPermission,
    handleDownloadClick,
    handleActionSetBranches,
    branchesOptions,
    printRef,
    handlePrintClick,
    franchiseOptions,
    handleActionSetFranchiseies,
    handleActionSetFranchiseById,
    user_type,
    namePdf
  };
};

export default useRLM2;

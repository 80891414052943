import {
  Button,
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import usePurchaseList from "./usePurchaseList";
import Filter from "../Purchases/Filter/index";

const PurchaseList = () => {
  const {
    purchaseList,
    purchaseDetailsPage,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    setIsFilterShow,
    isFilterShow,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updatePurchasesFields,
    hasCreatePermission,
    // hasViewPermission,
    hasEditPermission,
  } = usePurchaseList();


  return (
    <>
      <HeadingGroup title={"Purchases"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col-10">
            <SearchFilters
              data={purchaseDetailsPage?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={(e) => handleSearch(e)}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
            />
          </div>
          <div className="col-auto pro-ms-auto">
            <Button
              onClick={() => {
                setIsFilterShow(true);
              }}
              className="pro-btn pro-btn-primary pro-px-5 lg"
            >
              Filter
            </Button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={purchaseList?.rows?.data}
          uniqueID={"id"}
          fields={purchaseList?.field}
          SortIcon={<FaSort />}
          getRow={getRow}
          handleSort={handleSort}
          loading={isFetching}
          perpage={1}
          assignable={false}
          handleDelete={handleDelete}
          deletable={true}
        />

        {purchaseList?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={purchaseList?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={purchaseList?.field}
              moduleId={purchaseList?.table_id}
              updateData={updatePurchasesFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <OffCanvasLayout
          centered={true}
          show={isFilterShow}
          handleClose={() => {
            setIsFilterShow(false);
          }}
          title={"Filter"}
          backdrop="static"
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setIsFilterShow} />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default PurchaseList;

// customHooks/useFetchPurchaseData.js
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPurchaseById } from "../../../store/slices/customers/customerDetailsPageSlice";

export const useFetchPurchaseData = (purchaseID) => {
  const [purchaseData, setPurchaseData] = useState(null);
  const dispatch = useDispatch();


  const tableDataArray = [];


  purchaseData?.currency_purchase_data_without_trash?.forEach((currency) => {
    const tableData = [
      { label: "Currency Name", value: currency.currency.name },
      { label: "Currency Type", value: currency.purchase_mode_name },
      { label: "Currency Volume", value: currency.currency_volume },
      { label: "Rate", value: currency.rate },
      { label: "INR Volume", value: `₹ ${currency.inr_volume}` },
    ];

    tableDataArray.push(tableData);
  });

  const tableData4 = [
    { label: "CDF Number", value: purchaseData?.cdf_number },
    {
      label: "Amount Declared in CDF",
      value: `₹ ${
        purchaseData?.amount_int_cdf == null ? 0 : purchaseData?.amount_int_cdf
      }`,
    },
    { label: "Date of CDF", value: purchaseData?.cdf_date },
    {
      label: "Custom Office Place",
      value: purchaseData?.customer_office_place,
    },
  ];

  const tableData5 = [
    { label: "Payment Mode", value: purchaseData?.payment_mode?.payment_mode },
  ];

  

  const tableData6 = [
    {
      label: "Total Transaction amount",
      value: `₹ ${purchaseData?.transaction_amount}`,
    },
    { label: "IGST amount", value: `₹ ${purchaseData?.igst}` },
    { label: "SGST amount", value: `₹ ${purchaseData?.sgst}` },
    { label: "CGST amount", value: `₹ ${purchaseData?.cgst}` },
    { label: "UGST amount", value: `₹ ${purchaseData?.ugst}` },
    {
      label: "Amount to customer",
      value: `₹ ${purchaseData?.amount_to_customer}`,
    },
  ];

  const tableData7 = [
    { label: "Name", value: purchaseData?.customer?.full_name },
    { label: "ID", value: purchaseData?.customer?.customer_code }
  ];

  useEffect(() => {
    dispatch(fetchPurchaseById(purchaseID))
      .then((data) => {
        setPurchaseData(data.payload.data);
      })
      .catch((error) => {});
  }, [dispatch, purchaseID]);

  return {purchaseData,tableDataArray,tableData4,tableData5,tableData6,tableData7};
};

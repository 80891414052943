import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  calculateAmount,
  resetValues,
  fetchActiveCurrency,
  fetchActivePaymentModes,
  createPurchaseByCustomerID,
  updatePurchaseByCustomerID,
} from "../../../store/slices/purchase/purchaseSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  balanceCheck,
  createSurrender,
} from "../../../store/slices/surrender/surrenderSlice";
import {
  clearData,
  fetchSurrenderById,
  updateSurrenderByID,
  updateConfig,
} from "../../../store/slices/surrender/surrenderDetailsPageSlice";
import { useGetSurrendersListDataQuery } from "../../../store/queries/surrenders";

const useSurrenderForm = () => {
  const dispatch = useDispatch();
  let { surrenderId } = useParams();

  const navigate = useNavigate();
  const { selectedItemsDetails, balanceVerified } = useSelector(
    (state) => state.surrenderDetailsPage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [paymentModeOptions, setPaymentModeOptions] = useState([]);
  const [isCDFrequired, setIsCDFrequired] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [cashVolumeCount, setCashVolumeCount] = useState(0);
  const [paymentTypeCount, setPaymentTypeCount] = useState(0);
  const [currencyTypeCount, setCurrencyTypeCount] = useState(0);

  useEffect(() => {
    if (surrenderId != undefined && selectedItemsDetails == "") {
      dispatch(fetchSurrenderById(surrenderId))
        .unwrap()
        .then((result) => {});
    }
  }, [surrenderId, selectedItemsDetails]);
  const { refetch } = useGetSurrendersListDataQuery({});
  // Fetch currency options from the API

  useEffect(() => {
    dispatch(fetchActiveCurrency()).then((result) => {
      const rawArray = result.payload.data;

      if (rawArray.length > 0) {
        const convertedData = rawArray.map((item) => {
          return {
            value: item.id,
            label: `${item.name} (${item.code})`,
          };
        });

        setCurrencyOptions(convertedData);
      }
    });

    dispatch(fetchActivePaymentModes()).then((result) => {
      const rawArray = result.payload.data;

      if (rawArray.length > 0) {
        const convertedData = rawArray
          .filter(item => item.payment_mode !== "Cash")
          .map(item => ({
            value: item.id,
            label: item.payment_mode,
          }));
      
        setPaymentModeOptions(convertedData);
      }
      
    });
    return () => {
      dispatch(clearData());
    };
  }, []);

  const cdfDetailsValidation = {
    cdfNumber: Yup.string().required("CDF Number is required"),
    amountDeclared: Yup.string().required("Amount Declared is required"),
    cdf_date: Yup.string().required("Date of Birth is required"),
    customsOfficePlace: Yup.string().required(
      "Customs Office/Place is required"
    ),
  };

  const validationSchema = Yup.object().shape({
    ...{
      paymentMode: Yup.object().required("Payment Mode is required"),

      transactions: Yup.array()
        .of(
          Yup.object().shape({
            currencyName: Yup.object().required("Currency Name is required"),
            paymentType: Yup.string().required("Payment Type is required"),
            currencyVolume: Yup.string().required(
              "Currency Volume is required"
            ),
            rate: Yup.string().required("Rate is required"),
            //   inrVolume: Yup.string().required("INR Volume is required"),
          })
        )
        .required("At least one transaction is required"),
    },
    ...(isCDFrequired ? cdfDetailsValidation : {}),
  });

  let initialValues;
  if (Object.keys(selectedItemsDetails).length === 0) {
    // Create mode
    initialValues = {
      paymentMode: "",
      transactions: [
        {
          currencyName: "",
          paymentType: "",
          currencyVolume: "",
          rate: "",
          inrVolume: "",
        },
      ],
    };
  } else {
    // Edit mode

    const transactions = selectedItemsDetails?.currency_surrender_data?.map(
      (transaction) => ({
        currencyName: {
          label: `${transaction?.currency?.name} (${transaction?.currency?.code})`,
          value: transaction?.currency_id,
        },
        paymentType: transaction?.purchase_mode,
        currencyVolume: transaction?.currency_volume?.toString(),
        rate: transaction?.rate?.toString(),
        inrVolume: transaction?.inr_volume?.toString(),
      })
    );

    initialValues = {
      paymentMode: {
        label: selectedItemsDetails?.payment_mode?.payment_mode,
        value: selectedItemsDetails?.payment_mode?.id,
      },
      payoutAmount: selectedItemsDetails?.payout_amount,
      transactions,
    };
  }

  const handleSubmit = (values) => {
    setIsLoading(true);
    let apiCallInstance, message;
    if (surrenderId != undefined) {
      const Data = {
        currency: values.transactions.map((item, index) => {
          return {
            currency_id: item.currencyName.value,
            currency_type: item.paymentType,
            amount: item.currencyVolume,
            old_amount: selectedItemsDetails?.currency_surrender_data?.map(
              (item) => {
                return item?.currency_volume;
              }
            )?.[index],
            rate: item.rate,
            inr_value: item.inrVolume,
          };
        }),

        payment_mode: values.paymentMode.value,
      };
      apiCallInstance = dispatch(
        updateSurrenderByID({ surrenderId: surrenderId, data: Data })
      );
      message = "Surrender has been updated.";
    } else {
      const formData = {
        currency: values.transactions.map((item) => {
          return {
            currency_id: item.currencyName.value,
            currency_type: item.paymentType,
            amount: item.currencyVolume,
            rate: item.rate,
            inr_value: item.inrVolume,
          };
        }),

        payment_mode: values.paymentMode.value,
      };
      apiCallInstance = dispatch(createSurrender(formData));
      message = "Surrender has been created.";
    }

    apiCallInstance
      .unwrap()
      .then((result) => {
        if (result.success) {
          setIsLoading(false);
          setIsCDFrequired(false);
          toast.success(message);
          navigate(`/surrender`);
          formik.resetForm();
        } else {
          toast.error("Sorry something went wrong.");
        }
      })
      .catch((err) => {
        const error = [
          "Currency Declaration Form need to be filled",
          "Payment mode should not be Cash",
        ];

        var position = error.indexOf(err.message);

        if (position === 0) {
          setIsLoading(false);
          setIsCDFrequired(true);
          toast.error(error[position]);
        }
        if (position === 1) {
          setIsLoading(false);
          toast.error(error[position]);
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const calculateTotalInrVolume = () => {
    const totalInrVolume = formik.values.transactions.reduce(
      (sum, transaction) => {
        return sum + Number(transaction.inrVolume);
      },
      0
    );

    return totalInrVolume;
  };
  useEffect(() => {
    if (
      balanceVerified === true &&
      formik.isValid === false &&
      formik.dirty === true
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [formik?.values?.paymentMode]);
  const BalanceCheck = () => {
    const totalCurrencyVolume = formik.values.transactions?.reduce(
      (sum, transaction) => {
        let s = formik.values.transactions.filter(
          (t) => t.currencyName.label === transaction.currencyName.label
        );

        return s;
      }
    );

    const currency_volume =
      totalCurrencyVolume?.length === undefined
        ? formik.values.transactions.reduce((sum, transaction) => {
            const currencyVolume = transaction.currencyVolume
              ? Number(transaction.currencyVolume)
              : 0;
            return sum + currencyVolume;
          }, 0)
        : totalCurrencyVolume.reduce((sum, transaction) => {
            const currencyVolume = transaction.currencyVolume
              ? Number(transaction.currencyVolume)
              : 0;
            return sum + currencyVolume;
          }, 0);

    const payment_type =
      totalCurrencyVolume?.length === undefined
        ? formik.values.transactions.reduce((sum, transaction) => {
            const paymentType = transaction.paymentType;

            return paymentType;
          }, 0)
        : totalCurrencyVolume.reduce((sum, transaction) => {
            const paymentType = transaction.paymentType;

            return paymentType;
          }, 0);

    const currency_type =
      totalCurrencyVolume?.length === undefined
        ? formik.values.transactions.reduce((sum, transaction) => {
            const currencyType = transaction.currencyName?.value;

            return currencyType;
          }, 0)
        : totalCurrencyVolume.reduce((sum, transaction) => {
            const currencyType = transaction.currencyName?.value;

            return currencyType;
          }, 0);

    return { currency_volume, payment_type, currency_type };
  };
  useEffect(() => {
   
    if (formik.isValid === false) {
      setDisable(false);
    }
    let newCurrencyVolumedata = formik.values.transactions?.map(
      (item) => item?.currencyVolume
    );
    let newCurrencyTypedata = formik.values.transactions?.map(
      (item) => item?.currency_type
    );
    let newPaymentTypedata = formik.values.transactions?.map(
      (item) => item?.payment_type
    );

    let filteredCurrencyVolumeArray = newCurrencyVolumedata?.filter(
      (element) => element !== ""
    );
    let filteredCurrencyTypeArray = newCurrencyTypedata?.filter(
      (element) => element !== ""
    );
    let filteredPaymentTypeArray = newPaymentTypedata?.filter(
      (element) => element !== ""
    );
   
    if (
      filteredCurrencyVolumeArray?.length !== cashVolumeCount &&
      (filteredCurrencyTypeArray?.length !== currencyTypeCount ||
        filteredPaymentTypeArray?.length !== paymentTypeCount)
    ) {
      const debounceTimer = setTimeout(() => {
        const cashVolume = BalanceCheck();

        const totalInrVolume = calculateTotalInrVolume();
        const params = {
          currency_id: cashVolume?.currency_type,
          value: cashVolume?.currency_volume,
          payment_type: cashVolume?.payment_type,
        };
        if (totalInrVolume === 0) {
          dispatch(resetValues());
        } else {
          dispatch(balanceCheck(params)).then((res) => {
            if (res?.payload?.data?.status_code === 200) {
              dispatch(updateConfig((state) => (state.balanceVerified = true)));
              if (
                balanceVerified === true &&
                formik.isValid === true &&
                formik.dirty === true
              ) {
                setDisable(true);
              }
              if (formik.values.transactions.length > 1) {
                if (
                  balanceVerified === true &&
                  formik.isValid === false &&
                  formik.dirty === true
                ) {
                  setDisable(true);
                }
              }
            }
            if (res?.payload?.status_code === 404) {
              toast.error(res?.payload?.message);

              if (balanceVerified === true) {
                setDisable(false);
              }
            }
          });
        }
      }, 500);

      return () => clearTimeout(debounceTimer);
    }
  }, [formik.values.transactions]);

  const handleAddTransaction = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    dispatch(updateConfig((state) => (state.balanceVerified = false)));
    setCashVolumeCount(
      formik.values.transactions?.map((item) => item?.currencyVolume)?.length
    );
    setCurrencyTypeCount(
      formik.values.transactions?.map((item) => item?.currency_type)?.length
    );
    setPaymentTypeCount(
      formik.values.transactions?.map((item) => item?.payment_type)?.length
    );
    formik.setFieldValue("transactions", [
      ...formik.values.transactions,
      {
        currencyName: "",
        radio: "",
        currencyVolume: "",
        rate: "",
        inrVolume: "",
      },
    ]);
  };

  const handleRemoveTransaction = (index) => {
    formik.setFieldValue(
      "transactions",
      formik.values.transactions.filter((_, i) => i !== index)
    );

    dispatch(updateConfig((state) => (state.balanceVerified = false)));
    setCashVolumeCount(
      formik.values.transactions?.map((item) => item?.currencyVolume)?.length
    );
    setCurrencyTypeCount(
      formik.values.transactions?.map((item) => item?.currency_type)?.length
    );
    setPaymentTypeCount(
      formik.values.transactions?.map((item) => item?.payment_type)?.length
    );
  };

  return {
    formik,
    balanceVerified,
    currencyOptions,
    disable,
    paymentModeOptions,
    isLoading,
    setIsLoading,
    handleAddTransaction,
    handleRemoveTransaction,
  };
};

export default useSurrenderForm;

import React from "react";
import Assets from "../../../../assets/Assets";

const PurchasePrint = React.forwardRef((props, ref) => {
  const {
    cancelled,
    branchName,
    rbiLicenseNumber,
    gstInNumber,
    titleName,
    cin,
    sacCode,
    branchAddress,
    branchContact,
    validityText,
    serialNumber,
    date,
    customerNumber,
    customerName,
    passportNumber,
    nationality,
    behalfOf,
    customerAddress,
    currencyData,
    exchangeTransactionFee,
    cgst,
    sgst,
    igst,
    ugst,
    roundOff,
    totalIncludingTax,
    totalInWords,
    paymentDetails,
    undertakingText,
    authorizedSignatory,
    note,
    gstAndCinDetails,
    total,
  } = props;
  // Your JSX code for rendering the component's content goes here
  return (
    <>
      <div style={{ visibility: "hidden", position: "fixed" }}>
        <table
          ref={ref}
          border={0}
          cellSpacing={0}
          role="presentation"
          cellPadding={0}
          style={{ width: "100%", borderCollapse: "collapse",
          backgroundImage: cancelled === "Cancelled" ? 'url('+ Assets.WATER_MARK +')' : "none",
          backgroundRepeat :"no-repeat",
          backgroundPosition: "center center" }}
        >
          <tbody>
            <tr>
              <td style={{ width: "100%", paddingBottom: "10px" }}>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                    paddingTop: "50px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: "575px", verticalAlign : "top", paddingTop : 15}}>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                            margin: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "16px",
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  lineHeight: "1.5",
                                  fontFamily: '"Arial", sans-serif',
                                  verticalAlign: "top"
                                }}
                              >
                                {branchName}
                              </td> 
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      

                      <td style={{ width: "575px", verticalAlign: "top", paddingTop : 15 }}>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                            margin: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Arial", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {titleName ? titleName : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  lineHeight: "1.2",
                                  fontFamily: '"Arial", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {rbiLicenseNumber ? rbiLicenseNumber : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Arial", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {gstInNumber ? gstInNumber : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Arial", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {cin}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Arial", sans-serif',
                  paddingBottom: "20px",
                }}
              >
                {branchAddress}
              </td>
            </tr>
            {/* <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Arial", sans-serif',
                  paddingBottom: "5px",
                }}
              >
                Tel:{branchContact}
              </td>
            </tr> */}
            <tr>
              <td
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Arial", sans-serif',
                  paddingBottom: "10px",
                }}
              >
                {validityText}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "1px solid #000000",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "1.5",
                  fontFamily: '"Arial", sans-serif',
                  textAlign: "center",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  textTransform: "uppercase",
                  letterSpacing: 5,
                  paddingTop: "5px",
                  paddingBottom: "15px",
                }}
              >
                encashment certificate
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Serial No : {serialNumber}
                      </td>
                      <td
                        style={{
                          width: "30%",
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "right",
                        }}
                      >
                        Date : {date}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "15px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Customer No : {customerNumber}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        We hereby certify that we have purchased today foreign
                        currency from : <strong>{customerName}</strong>
                      </td>
                    </tr>
                    {/* <tr>
                      <td
                        style={{
                          paddingBottom: "10px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Holder of PASSPORT No. {passportNumber} , Nationality :{" "}
                        {nationality}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        On Behalf of : INDIVIDUAL CLIENTS
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Address : {customerAddress}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Details of foreign currency Notes / Coins / Travellers
                        Cheques purchased
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "left",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                        width="20%"
                      >
                        Currency name
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Type
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Rate/Per
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "right",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          paddingRight: "50px",
                        }}
                      >
                        Rs. Equl.
                      </th>
                    </tr>
                    {currencyData.map((currency, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            
                          }}
                        >
                          {currency.currencyName}
                        </td>
                        <td
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.type}
                        </td>
                        <td
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.amount}
                        </td>
                        <td
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.ratePer}
                        </td>
                        <td
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {currency.rsEquivalent}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{paddingTop: 10}}>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    
                    {/* <tr>
                      <td width="30%" />
                      <td
                        width="45%"
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Exchange Transaction Fees-
                      </td>
                      <td>:</td>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "right",
                          paddingRight: "50px",
                        }}
                      >
                        {exchangeTransactionFee}
                      </td>
                    </tr> */}

              
                    <tr>
                      <td style={{ 
                        
                        paddingBottom: "25px",
                        borderTop: "1px solid #000000",
                     }} />
                      <td
                        style={{
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          borderTop: "1px solid #000000",
                        }}
                      >
                        Total {" "}
                      </td>
                      <td style={{ paddingBottom: "25px",
                      borderTop: "1px solid #000000",
                     }}>:</td>
                      <td
                        style={{
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "right",
                          paddingRight: "50px",
                          borderTop: "1px solid #000000",
                        }}
                      >
                        {totalIncludingTax}
                      </td>
                    </tr>

                    {cgst > 0 && (
                      <tr>
                        <td />

                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                          }}
                        >
                          CGST
                        </td>

                        <td>:</td>
                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {cgst}
                        </td>
                      </tr>
                    )}

                    {sgst > 0 && (
                      <tr>
                        <td />
                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                          }}
                        >
                          SGST
                        </td>
                        <td>:</td>
                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {sgst}
                        </td>
                      </tr>
                    )}

                    {igst > 0 && (
                      <tr>
                        <td />

                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                          }}
                        >
                          IGST
                        </td>

                        <td>:</td>
                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {igst}
                        </td>
                      </tr>
                    )}

                    {ugst > 0 && (
                      <tr>
                        <td />

                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                          }}
                        >
                          UGST
                        </td>

                        <td>:</td>
                        <td
                          style={{
                            paddingBottom: "5px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Arial", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {ugst}
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td />
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Round Off
                      </td>
                      <td>:</td>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "right",
                          paddingRight: "50px",
                        }}
                      >
                        {roundOff}
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          paddingBottom: "25px",
                        }}
                      />
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingBottom: "25px",
                          paddingTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          paddingBottom: "25px",
                        }}
                      >
                        :
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingBottom: "25px",
                          paddingTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "right",
                          paddingRight: "50px",
                        }}
                      >
                        {total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                }}
              >
                Total(In words) : {totalInWords}
              </td>
            </tr>
            {/* <tr>
              <td
                style={{
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                }}
              >
                Payment Details:
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "1px solid #000000",
                  paddingBottom: "25px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                }}
              >
                {paymentDetails}
              </td>
            </tr> */}
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                  textAlign: "center",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                }}
              >
                UNDERTAKING
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                }}
              >
                {undertakingText}
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td width="25%" />
                      <td />
                      <td
                        width={315}
                        style={{
                          paddingRight: "30px",
                          textAlign: "center",
                          paddingBottom: "40px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        {authorizedSignatory}
                      </td>
                      <td width="4%" />
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "20px",
                          paddingTop: "5px",
                          borderTop: "1px solid #000000",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                          textAlign: "center",
                        }}
                        width="11%"
                      >
                        Sign of customer
                      </td>
                      <td />
                      <td
                        style={{
                          paddingBottom: "20px",
                          paddingTop: "5px",
                          borderTop: "1px solid #000000",
                          textAlign: "center",
                          paddingRight: "30px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                        width={315}
                      >
                        Authorised signatory
                      </td>
                      <td width="4%" />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                }}
              >
                Note:{note}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Arial", sans-serif',
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  textAlign: "center",
                  paddingTop: "100px",
                  paddingBottom: "50px",
                }}
              >
                {gstAndCinDetails}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
});

export default PurchasePrint;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "id",
  sortOrder: "",
  data: [],
  showCreateModal: false,
  showEditModal: false,
  selectedItemsDetails: "",
  balanceVerified:false
};

export const addItemToTable = createAsyncThunk(
  "customersDetailsPage/addItemToTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/testimonial/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteItemFromTable = createAsyncThunk(
  "customersDetailsPage/deleteItemFromTable",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/purchase/delete/${params.id}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editItemFromTableByID = createAsyncThunk(
  "customersDetailsPage/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/testimonial/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSurrenderById = createAsyncThunk(
  "surrenderDetailsPage/fetchById",
  async (surrenderId, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/surrender/show/${surrenderId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSurrenderByID = createAsyncThunk(
  "surrenderDetailsPage/updateSurrenderID",
  async ({ surrenderId, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/surrender/update/${surrenderId}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const confirmPayment = createAsyncThunk(
  "purchase/confirmPayment",
  async ({ id, paymentData }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/purchase/confirm-payment/${id}`,
        paymentData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const surrenderDetailsPageSlice = createSlice({
  name: "surrenderDetailsPage",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    clearData: (state, action) => {
      state.selectedItemsDetails = '';
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
      
    },
    [editItemFromTableByID.rejected]: (state, action) => {},

    [fetchSurrenderById.pending]: (state, action) => {
      state.surrenderDetailsLoading = "loading";
    
    },
    [fetchSurrenderById.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data;
      state.surrenderDetailsLoading = "success";
    
    },
    [fetchSurrenderById.rejected]: (state, action) => {
      state.surrenderDetailsLoading = "error";
    },
  },
});

export const { updateConfig, setEditTestimonail,clearData } =
surrenderDetailsPageSlice.actions;

export default surrenderDetailsPageSlice.reducer;

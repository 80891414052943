import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
   
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${
          typeof params?.[key] === "object"
            ? params?.[key]["value"]
            : params?.[key]
        }`;
      } else {
        return "";
      }
    };

    const getQuery = (paramsData) => {
      return getParams(paramsData) !== "" ? `&${getParams(paramsData)}` : "";
    };

    try {
      let url =
        method !== "post"
          ? `${endpoint}?${getParams("sort_by")}&sort_order=${
              params?.sort_order || "desc"
            }&length=${params?.length || "10"}&${getParams("search")}&page=${
              params?.page || 1
            }${getQuery("franchise_id")}${getQuery("branch_id")}${getQuery("visa_type_id")}${getQuery(
              "country_id"
            )}${getQuery("state_id")}${getQuery("nationality_id")}${getQuery(
              "start_date"
            )}${getQuery("end_date")}`
          : `${endpoint}`;

      const response = await api[method](url, body);

      if (response?.data?.data?.rows !== undefined) {
        return { data: response?.data?.data };
      } else {
        return { data: response?.data };
      }
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const purchaseReport = createApi({
  reducerPath: "purchaseReportApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Rlm3Report"],
  endpoints: (builder) => ({
    getRlm3ReportData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/reports/purchase-register-report`,
      }),
      providesTags: ["Rlm3Report"],
    }),

    updateRlm3ReportData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/rlm3-report/create`,
      }),

      invalidatesTags: ["Rlm3Report"],
    }),

    // Add more endpoints for the "rlm3-report" section as needed
  }),
});

export const { useGetRlm3ReportDataQuery, useUpdateRlm3ReportDataMutation } =
purchaseReport;

import LOGO from "./images/logo-matrimony.svg";
import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/Logo.svg";
import PERSON from "./images/profile.jpg";
import PROFILEBG from "./images/user-img-design.png";
import PROFILE from "./images/user-img.png";
import TABLEBG from "./images/bg.png";
import ABOUTICON from "./images/about-icon.png";
import FAMILYICON from "./images/family_icon.png";
import EDUICON from "./images/edu.png";
import JOBICON from "./images/job.png";
import CONTACTICON from "./images/contact.png";
import ACCOUNT_CIRCLE from './images/acount_circle.png';
import WATER_MARK from './images/waterMark.png';



import NO_DATA from './images/no_data.svg';

const Assets = {
  LOGO,
  USER,
  HEADERLOGO,
  PERSON,
  PROFILEBG,
  PROFILE,
  TABLEBG,
  ABOUTICON,
  FAMILYICON,
  EDUICON,
  JOBICON,
  CONTACTICON,
  NO_DATA,
  ACCOUNT_CIRCLE,
  WATER_MARK
};

export default Assets;

import React, { useEffect, useState } from "react";
import Style from "../purchaseForm.module.scss"; // Import the CSS file for styling
import { useSelector } from "react-redux";

const TransactionDetails = ({ formik }) => {
  const [total, setTotal] = useState(0);

  const { calculatedAmount } = useSelector((state) => state.purchase);

  const calculateTotalInrVolume = () => {
    let totalInrVolume = 0;

    formik.values.transactions.forEach((transaction) => {
      totalInrVolume += parseFloat(transaction.inrVolume) || 0;
    });

    setTotal(totalInrVolume.toFixed(2));
  };

  const filterMoney = (value) => {
    return value?.toFixed(2);
  };

  useEffect(() => {
    calculateTotalInrVolume();
  }, [formik]);

  return (
    <div className="transaction-details">
      <h6 className={`pro-ttl pro-mb-4 ${Style.proTtl}`}>
        Transaction Details
      </h6>
      <div className={`${Style.table_root}`}>
        <table className={`${Style.table}`}>
          <tbody>
            <tr>
              <td className={`${Style.gray_text}`}>
                <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                  Total Transaction amount
                </p>
              </td>
              <td>
                <p className="pro-fw-bold pro-font-regular pro-mb-0">
                ₹{total}
                </p>
              </td>
            </tr>

            <tr>
              <td className={`${Style.gray_text}`}>
                <p className="pro-mb-0 pro-font-sm pro-fw-medium">
                  Amount to Surrender
                </p>
              </td>
              <td>
                <p className="pro-fw-bold pro-font-regular pro-mb-0">
                ₹{calculatedAmount?.amount_to_customer === undefined
                    ? total
                    : filterMoney(calculatedAmount?.amount_to_customer)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionDetails;

import Style from './customerProfileShimmer.module.scss';
const CustomerProfileShimmer = () => {
  return (
    <div className={`${Style.root} pro-w-100 pro-p-5`}>
        <div className={`${Style.profile_top} pro-pb-5 pro-mb-3`}>
            <div className="pro-py-3 shimmer pro-d-inline-flex pro-mb-3 pro-me-6">
                <div className="pro-px-6"></div>
                <div className="pro-px-6"></div>
            </div>
            <div className="pro-py-1 pro-px-6 shimmer"> </div>
        </div>

        <div className='pro-w-100 pro-pt-5'>
            <div className="pro-px-6 pro-py-2 shimmer pro-mb-3"></div>
            <table className='pro-w-100'>
                <tbody>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>

                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className='pro-w-100 pro-pt-5'>
            <div className="pro-px-6 pro-py-2 shimmer pro-mb-3"></div>
            <table className='pro-w-100'>
                <tbody>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>

                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className='pro-w-100 pro-pt-5'>
            <div className="pro-px-6 pro-py-2 shimmer pro-mb-3"></div>
            <table className='pro-w-100'>
                <tbody>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>

                    <tr>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                        <td> <div className={`shimmer ${Style.table_content}`}></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default CustomerProfileShimmer
import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { profile } from "./store/queries/Profile";
import { faqsRtk } from "./store/queries/cms";
import { usersRtk } from "./store/queries/users";
import { dashboard } from "./store/queries/Dashboard";

import { customer } from "./store/queries/customers";
import { purchase } from "./store/queries/purchases";
import { surrender } from "./store/queries/surrenders";
import { adminUser } from "./store/queries/admin";
import { userRoles } from "./store/queries/roles";
import { leads } from "./store/queries/leads";
 

import { rlm1Report } from "./store/queries/reportsRLM1";
import { rlm2Report } from "./store/queries/reportsRLM2";
import { rlm3Report } from "./store/queries/reportsRLM3";
import { purchaseReport } from "./store/queries/PurchaseRegisterReport";
import { currencyRtk } from "./store/queries/currency";
import { cancelRequest } from "./store/queries/cancellationRequest";
import { cancellationRequestSurrender } from "./store/queries/cancellationRequestSurrender";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      profile.middleware,
      faqsRtk.middleware,
      usersRtk.middleware,
      dashboard.middleware,
      customer.middleware,
      purchase.middleware,
      surrender.middleware,
      adminUser.middleware,
      userRoles.middleware,
      leads.middleware,
      rlm1Report.middleware,
      rlm2Report.middleware,
      rlm3Report.middleware,
      purchaseReport.middleware,
      currencyRtk.middleware,
      cancelRequest.middleware,
      cancellationRequestSurrender.middleware,
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

import React from "react";
import Assets from "../../../assets/Assets";

const CertificatePrint = React.forwardRef((props, ref) => {
  const {
    surrender_to,
    titleName,
    cancelled,
    branchName,
    rbiLicenseNumber,
    gstInNumber,
    cin,
    branchAddress,
    branchContact,
    validityText,
    serialNumber,
    date,
    currencyData,
    totalInWords,
    paymentDetails,
    undertakingText,
    authorizedSignatory,
    note,
    gstAndCinDetails,
    total,
    paymentMode
  } = props;

  // Your JSX code for rendering the component's content goes here
  return (
    <>
      <div style={{ visibility: "hidden", position: "fixed" }}>
        <table
          ref={ref}
          border={0}
          cellSpacing={0}
          role="presentation"
          cellPadding={0}
          style={{ width: "100%", borderCollapse: "collapse",
          backgroundImage: cancelled === "Cancelled" ? 'url('+ Assets.WATER_MARK +')' : "none",
          backgroundRepeat :"no-repeat",
          backgroundPosition: "center center" }}
        >
          <tbody>
            <tr>
              <td style={{ width: "100%", paddingBottom: "10px" }}>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                    paddingTop: "50px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: "575px", verticalAlign : "top", paddingTop :15 }}>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                            margin: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "16px",
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  lineHeight: "1.5",
                                  fontFamily: '"Inter", sans-serif',
                                }}
                              >
                                {branchName}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "575px", verticalAlign : "top", paddingTop :15 }}>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                            margin: "auto",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                          <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Inter", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {titleName ? titleName : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  lineHeight: "1.5",
                                  fontFamily: '"Inter", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {rbiLicenseNumber ? rbiLicenseNumber : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Inter", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {gstInNumber ? gstInNumber : ""}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingBottom: "5px",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "1.2",
                                  fontFamily: '"Inter", sans-serif',
                                  textAlign: "right",
                                }}
                              >
                                {cin}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Inter", sans-serif',
                  paddingBottom: "20px",
                }}
              >
                {branchAddress}
              </td>
            </tr>
            {/* <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Inter", sans-serif',
                  paddingBottom: "5px",
                }}
              >
                Tel:{branchContact}
              </td>
            </tr> */}
            <tr>
              <td
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "1.3",
                  fontFamily: '"Inter", sans-serif',
                  paddingBottom: "10px",
                }}
              >
                {validityText}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderTop: "1px solid #000000",
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "1.5",
                  fontFamily: '"Inter", sans-serif',
                  textAlign: "center",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  textTransform: "uppercase",
                  letterSpacing: 5,
                  paddingTop: "5px",
                  paddingBottom: "15px",
                }}
              >
                surrender certificate
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        Serial No : {serialNumber}
                      </td>
                      <td
                        style={{
                          width: "30%",
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                          textAlign: "right",
                        }}
                      >
                        Date : {date}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "15px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        We hereby certify that we have sold foreign
                        currency today to Lulu Forex Pvt Ltd : <strong>{surrender_to?.name}</strong>
                      </td>
                    </tr>
                    
                    <tr>
                      <td
                        style={{
                          paddingBottom: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Arial", sans-serif',
                        }}
                      >
                        Address : {surrender_to?.branch_address}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "5px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "left",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                        width="20%"
                      >
                        Currency name
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        Type
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "center",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        Rate/Per
                      </th>
                      <th
                        style={{
                          borderTop: "#000000 solid 1px",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          textAlign: "right",
                          paddingBottom: "25px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                          paddingRight: "50px",
                        }}
                      >
                        Rs. Equl.
                      </th>
                    </tr>
                    {currencyData.map((currency, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            paddingBottom: "20px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Inter", sans-serif',
                          }}
                        >
                          {currency.currencyName}
                        </td>
                        <td
                          style={{
                            paddingBottom: "20px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Inter", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.type}
                        </td>
                        <td
                          style={{
                            paddingBottom: "20px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Inter", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.amount}
                        </td>
                        <td
                          style={{
                            paddingBottom: "20px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Inter", sans-serif',
                            textAlign: "center",
                          }}
                        >
                          {currency.ratePer}
                        </td>
                        <td
                          style={{
                            paddingBottom: "20px",
                            paddingTop: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "1.2",
                            fontFamily: '"Inter", sans-serif',
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {currency.rsEquivalent}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    
                    <tr>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          paddingBottom: "25px",
                        }}
                      />
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingBottom: "25px",
                          paddingTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                          paddingBottom: "25px",
                        }}
                      >
                        :
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          paddingBottom: "25px",
                          paddingTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                          textAlign: "right",
                          paddingRight: "50px",
                        }}
                      >
                        {total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                <strong>Total(In words) : </strong>{totalInWords}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                <strong style={{marginRight: "2px"}}>Receipt</strong><strong> Details : </strong>Recd. {total} By {paymentMode}
              </td>
            </tr>

            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                  textAlign: "center",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                }}
              >
                UNDERTAKING
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {undertakingText}
              </td>
            </tr>
            <tr>
              <td>
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td width="25%" />
                      <td />
                      <td
                        width={315}
                        style={{
                          paddingRight: "30px",
                          textAlign: "center",
                          paddingBottom: "40px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        {authorizedSignatory}
                      </td>
                      <td width="4%" />
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "20px",
                          paddingTop: "5px",
                          borderTop: "1px solid #000000",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                          textAlign: "center",
                        }}
                        width="11%"
                      >
                        Sign of customer
                      </td>
                      <td />
                      <td
                        style={{
                          paddingBottom: "20px",
                          paddingTop: "5px",
                          borderTop: "1px solid #000000",
                          textAlign: "center",
                          paddingRight: "30px",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "1.2",
                          fontFamily: '"Inter", sans-serif',
                        }}
                        width={315}
                      >
                        Authorised signatory
                      </td>
                      <td width="4%" />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Note : {note}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                  fontFamily: '"Inter", sans-serif',
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  textAlign: "center",
                  paddingTop: "100px",
                  paddingBottom: "50px",
                }}
              >
                {gstAndCinDetails}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
});

export default CertificatePrint;

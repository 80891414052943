import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import usePurchases from "./usePurchases";
import { useEffect } from "react";
import AddRemark from "./AddRemark";

const transactionsJsonData = require("./../../../assets/data/transactions.json");

const Purchases = () => {
  const {
    purchaseList,
    customerDetailsPage,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updatePurchasesFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    handleAddPurchase,
    handleAddNote,
    handleCloseNotesCk,
    user_type,
    is_Cancelled,
    is_Editable,
    checkIsActive
  } = usePurchases();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("success") == "true") {
      refetch();
    }
  }, []);

  return (
    <>
      <HeadingGroup
        title={"Purchases"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ?? false ? "New Purchases" : ""}
        handleClick={handleAddPurchase}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={transactionsJsonData?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
        />

        <Table
          multiSelect={false}
          data={purchaseList?.rows?.data || []}
          uniqueID={"id"}
          fields={purchaseList?.field}
          showCheckBox={(hasEditPermission || hasDeletePermission) ?? false}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={customerDetailsPage.currentPageSize}
          editable={(hasEditPermission && !is_Editable) ? true : false}
          handleEdit={handleEditAction}
          handleDelete={handleDelete}
          deletable={user_type == 1 ? true : false}
          assignable={((user_type == 1) || (is_Cancelled)) ? false : true}
          assignIcon={<span class="material-symbols-outlined">mail</span>}
          assignText={"Cancellation Request"}
          handleAssign={handleAddNote}
          handleSelect={checkIsActive}          
        />

        {purchaseList?.rows?.data.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={purchaseList.rows.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={purchaseList?.field}
              moduleId={purchaseList.table_id}
              updateData={updatePurchasesFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>

        <ModalLayout
          show={customerDetailsPage.showNotesCk}
          handleClose={handleCloseNotesCk}
          title={"Add Remark"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <AddRemark
              selectedItemData={customerDetailsPage.selectedItemData}
              isStickyFooter
              refetch={refetch}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Purchases;

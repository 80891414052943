import React from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";

const useSurrenderLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Surrender List",
      link: "/",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/surrender", true),
    }
  ];
  // const drawerMenuPermission = useMemo(() => {
  //   let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
  //     ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
  //   ]);

  //   menus = ["Surrender List"];

  //   return drawerMenu
  //     .filter((menu) => menus?.includes?.(menu.title))
  //     .map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);


  return {
    drawerMenu
  };
};

export default useSurrenderLayout;

import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import Style from "./rlm3.module.scss";
import useRLM3 from "./useRLM3";

import EmptyData from "../../Global/EmptyData";
import TableStructureRLM3 from "../TableStructureRLM3";

import "react-calendar/dist/Calendar.css";
import { MyCalendar } from "../../Global/MonthYearCalander";
import Select from "react-select";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const RLM3 = () => {
  const {
    rlm3ReportList,
    rlm3Report,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    date,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    handleDateRangeChange,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateTestimonialsFields,
    hasCreatePermission,
    // hasViewPermission,
    hasEditPermission,
    franchiseiesOptions,
    handleActionSetFranchiseies,
    printRef,
    handlePrintClick,
    selectedMonth,
    franchiseOptions,
    handleActionSetFranchiseById,
    user_type,
    namePdf
  } = useRLM3();

  return (
    <>
      <HeadingGroup
        title={"RLM 3"}
        className={`pro-mb-4`}
        buttonTitle={/*hasCreatePermission ?*/ "Export" || ""}
        handleClick={handlePrintClick}
        buttonProps={{
          disabled: rlm3ReportList?.rows?.length > 0 ? "" : "disabled",
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          // extraFilters={
          //   <MyCalendar
          //     handleDateChange={handleDateChange}
          //     selectedMonth={selectedMonth}
          //   />
          // }
          data={rlm3ReportList?.data?.filters}
          // onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          // showDateRange={false}
          // initialDateRange={date}
          // datePickerProps={true}
          showActions={false}
          searchable={false}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
              {user_type != 2 ? (
                <div className="col-auto dropdown-filter">
                <Select
                  onChange={handleActionSetFranchiseies}
                  options={franchiseiesOptions}
                  value={franchiseiesOptions?.filter(
                    (item) => item?.label === rlm3Report?.selectedFranchise?.label
                  )}
                  placeholder="Select Branch"
                  classNamePrefix={`pro-input`}
                  className={`pro-input lg search-filter-select`}
                  isClearable={true}
                  isDisabled={(user_type == 3) ? true : false}
                />
              </div>
              ) : (<></>)}
              <div className="col-auto dropdown-filter">
                <Select
                  onChange={handleActionSetFranchiseById}
                  options={franchiseOptions}
                  value={franchiseOptions?.filter(
                    (item) =>
                      item?.label === rlm3Report?.selectedFranchiseOption?.label
                  )}
                  placeholder="Select Franchise"
                  classNamePrefix={`pro-input`}
                  className={`pro-input lg search-filter-select`}
                  isDisabled={((user_type == 2) || (rlm3Report?.selectedFranchise?.label == "All") || (rlm3Report?.selectedFranchise?.label == undefined)) ? true : false}
                  isClearable={true}
                />
              </div>
            </>
          }
        // actionOptions={franchiseiesOptions}
        // onActionOptionChange={handleActionSetFranchiseies}
        />

        {rlm3ReportList?.rows?.length > 0 && !isFetching ? (
          <div className={Style.table_wraper}>
            <TableStructureRLM3
              printRef={printRef}
              state={rlm3Report}
              title={"RLM 3"}
              rlm3ReportList={rlm3ReportList}
              getRow={getRow}
              showMonth={true}
              isFetching={isFetching}
              handleEditAction={handleEditAction}
              handleDelete={handleDelete}
              selectedBranch={namePdf}
            />
            <Table
              // ref={printRef}
              multiSelect={false}
              data={rlm3ReportList?.rows || []}
              fields={rlm3ReportList?.field}
              showCheckBox={false}
              getRow={getRow}
              loading={isFetching}
              perpage={rlm3Report.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDelete}
              deletable={true}
            />
          </div>
        ) : isFetching ? (
          <div className="app-loading fill"></div>
        ) : (
          <EmptyData subtitle={"Select the filter to generate the Report"} />
        )}

        {rlm3ReportList?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={rlm3ReportList?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={rlm3ReportList?.data?.fields}
              moduleId={rlm3ReportList?.data?.module_id}
              updateData={updateTestimonialsFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default RLM3;

import React from "react";
import Style from "./addStaffForm.module.scss";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const AddFormFields = ({
  formik,
  optionsWithIcons,
  staffList,
  selectedOption,
  setSelectedOption,
  options,
  handleFileChange,
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          {/* <div className="col pro-mb-4"> */}
          {/* <div className="input-wrap"> */}
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Bride Name
          </label>
          <input
            type="text"
            className={`pro-input lg ${
              formik.errors.brideName && formik.touched.brideName && " error"
            }`}
            id="brideName"
            name="brideName"
            {...formik.getFieldProps("brideName")}
          />
          {formik.touched.brideName && formik.errors.brideName && (
            <span className="error-text">{formik.errors.brideName}</span>
          )}
          {/* </div> */}
          {/* </div> */}
          <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Groom Name
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.groomName &&
                  formik.touched.groomName &&
                  " error"
                }`}
                id="groomName"
                name="groomName"
                {...formik.getFieldProps("groomName")}
              />
              {formik.touched.groomName && formik.errors.groomName && (
                <span className="error-text">{formik.errors.groomName}</span>
              )}
            </div>
          </div>
          <div className="col pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Date of marriage
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.dateOfMarrige &&
                  formik.touched.dateOfMarrige &&
                  " error"
                }`}
                id="dateOfMarrige"
                name="dateOfMarrige"
                {...formik.getFieldProps("dateOfMarrige")}
                placeholder="15-03-2023"
              />

              {formik.touched.dateOfMarrige && formik.errors.dateOfMarrige && (
                <span className="error-text">
                  {formik.errors.dateOfMarrige}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Testimonial
              </label>
              <textarea
                rows={5}
                className={`pro-input lg ${
                  formik.errors.testimonialText &&
                  formik.touched.testimonialText &&
                  " error"
                }`}
                id="testimonialText"
                name="testimonialText"
                {...formik.getFieldProps("testimonialText")}
              ></textarea>
              {formik.touched.testimonialText &&
                formik.errors.testimonialText && (
                  <span className="error-text">
                    {formik.errors.testimonialText}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Image
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  accept=".jpeg, .jpg"
                  className={`pro-input lg ${
                    formik.errors.image && formik.touched.image && " error"
                  }`}
                  id="image"
                  name="image"
                  onChange={handleFileChange}
                />

                <span className="input-drag-box">
                  <IconText title={`Drop files to attach or browse`} />
                </span>

                {formik.touched.image && formik.errors.image && (
                  <span className="error-text">{formik.errors.image}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFormFields;
